import { FC, SVGProps } from "react";

export const MoodboardStep: FC<SVGProps<unknown>> = ({ fill }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clipPath="url(#clip0_532_446)">
			<path
				d="M4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6Z"
				stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M4 9H12" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12 15H20" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12 4V20" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_532_446">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
);
