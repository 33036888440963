import { useAtom } from "@reatom/npm-react";
import { Avatar, Flex, Tag, Typography } from "antd";

import cn from "classnames";
import { FC } from "react";

import { getShotAction } from "@/entities/storyboard";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { toCharacter } from "@/shared/methods/toCharacter";
import bottomAngle from "@/assets/shot-selection/bottom-angle.svg";
import closeUp from "@/assets/shot-selection/close-up.svg";
import eyeLevel from "@/assets/shot-selection/eye-level.svg";
import cameraF8 from "@/assets/shot-selection/f-8.svg";
import highAngle from "@/assets/shot-selection/high-angle.svg";
import lowAngle from "@/assets/shot-selection/low-angle.svg";
import topAngle from "@/assets/shot-selection/top-angle.svg";
import timeImg from "@/assets/storyboard/time.svg";

import "./ShotDetailsInfo.scss";

export const ShotDetailsInfo: FC = () => {
	const [shot] = useAtom(getShotAction.dataAtom);

	const cameraData: Record<string, {
		img: string;
		title: string;
		key: string;
	}> = {
		"eye level": {
			img: eyeLevel,
			title: "Eye level",
			key: "eye level"
		},
		"close up": {
			img: closeUp,
			title: "Close up",
			key: "close up"
		},
		"f/8": {
			img: cameraF8,
			title: "F/8",
			key: "f/8"
		},
		"low angle": {
			img: lowAngle,
			title: "Low angle",
			key: "low angle"
		},
		"top angle": {
			img: topAngle,
			title: "Top angle",
			key: "top angle"
		},
		"bottom angle": {
			img: bottomAngle,
			title: "Bottom angle",
			key: "bottom angle"
		},
		"high angle": {
			img: highAngle,
			title: "High angle",
			key: "high angle"
		}
	};

	return (
		<Flex vertical className="shot-details-info">
			<Flex align="center" justify="space-between" className="shot-details-info__row gap-xxs">
				<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>"{shot?.data?.title}"</Typography.Text>
				<Flex>
					<Tag className="shot-details-info__tag flex-ctr-ctr gap-4">
						<img src={timeImg} className="image-contain" alt="time" />
						{shot?.data?.time} sec
					</Tag>
				</Flex>
			</Flex>
			<div className="shot-details-info__divider" />
			<Flex vertical className="shot-details-info__row gap-xxs">
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Location</Typography.Text>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					{shot?.data?.location}
				</Typography.Text>
			</Flex>

			<Flex wrap="wrap" className="shot-details-info__row gap-xxs">
				{shot?.data.characteristics.map((tag) => (
					<Tag className="shot-details-info__tag" key={tag}>{tag}</Tag>
				))}
			</Flex>
			<div className="shot-details-info__divider" />
			<Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
				<Flex vertical className="gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Description</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						{shot?.data?.description}
					</Typography.Text>
				</Flex>
			</Flex>
			<div className="shot-details-info__divider" />
			<Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
				<Flex vertical className="gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Dialogue</Typography.Text>
					<Typography className={TEXT_CLASSNAMES.XsRegular}>{shot?.data?.dialog ?? "-"}</Typography>
				</Flex>
			</Flex>
			{!!shot?.data.characters.length && (
				<>
					<div className="shot-details-info__divider" />
					<Flex wrap="wrap" className="shot-details-info__row gap-xxs">
						{shot?.data?.characters.map(() => (
							<Avatar size={43} />
						))}
					</Flex>
				</>
			)}
			<div className="shot-details-info__divider" />
			<Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
				<Flex vertical className="gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Camera Movement</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{shot?.data.camera_movement ?? "-"}</Typography.Text>
				</Flex>
			</Flex>
			{(!!shot?.data.camera_angle && cameraData[shot.data.camera_angle]?.title) && (
				<>
					<div className="shot-details-info__divider" />
					<Flex wrap="wrap" className="shot-details-info__row gap-xs">
						<Flex vertical className="gap-xxs">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Shot Settings</Typography.Text>
							<Flex align="center" vertical className="shot-details-info__camera gap-xxs">
								<img className="shot-details-info__camera__img image-contain" src={cameraData[shot.data.camera_angle].img} alt={cameraData[shot.data.camera_angle].key} />
								<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>{cameraData[shot.data.camera_angle].title}</Typography.Text>
							</Flex>
						</Flex>
					</Flex>
				</>
			)}
			{!!shot?.data.characters.length && (
				<>
					<div className="shot-details-info__divider" />
					<Flex wrap="wrap" className="shot-details-info__row gap-xs">
						{shot.data.characters.map((character, index) => (
							<Avatar key={index.toString(36)} size={46} icon={<img src={toCharacter(character.key, "2")} alt="character" />} />
						))}
					</Flex>
				</>
			)}
			{/* {shot?.data?.settings.map(() => ( */}
			{/*	<> */}
			{/*		<div className="shot-details-info__divider" /> */}
			{/*		<Flex wrap="wrap" className="shot-details-info__row gap-xs"> */}
			{/*			{shot?.data?.settings.map((title, idx) => ( */}
			{/*				<Flex key={idx.toString(36)} align="center" vertical className="shot-details-info__camera gap-xxs"> */}
			{/*					/!* <img className="shot-details-info__camera__img image-contain" src={img} alt={title} /> *!/ */}
			{/*					<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>{title}</Typography.Text> */}
			{/*				</Flex> */}
			{/*			))} */}
			{/*		</Flex> */}
			{/*	</> */}
			{/* ))} */}
		</Flex>
	);
};
