import ReactGA from "react-ga4";

export const trackGoogleAnalyticsEvent = <T extends NonNullable<unknown>, >(
	category: string,
	eventName: string,
	label: string,
	data: T
) => {
	console.log("GA event:", category, ":", eventName, ":", label);

	const eventParams = {
		category,
		label,
		...data
	};
	// Send GA4 Event
	ReactGA.event(eventName, eventParams);
};
