import { reatomAsync, withAbort } from "@reatom/async";
import { atom } from "@reatom/core";
import { UploadFile } from "antd";
import { getAccountAction } from "@/entities/account";
import { callErrorAction, callSuccessNotificationAction } from "@/entities/notification";
import {
	changePasswordResource,
	TChangePasswordDTO,
	TSettingsDTO,
	updateSettingsProfileResource
} from "@/shared/api/settings";

export const imageListAvatarAtom = atom<UploadFile[]>([], "imageList");

getAccountAction.dataAtom.onChange((ctx, response) => {
	if (response?.data.avatar) {
		imageListAvatarAtom(ctx, [{
			uid: "-1",
			name: "avatar.png",
			status: "done",
			url: response.data.avatar
		}]);
	}
});

export const updateProfileSeeingsAction = reatomAsync((ctx, data: TSettingsDTO) => updateSettingsProfileResource(data, ctx.controller), {
	onFulfill: (ctx) => {
		callSuccessNotificationAction(ctx, "Profile updated successfully");
		getAccountAction(ctx, true);
	},
	onReject: (ctx, err) => {
		callErrorAction(ctx, err);
	}
}).pipe(withAbort());

export const updateProfilePasswordAction = reatomAsync((ctx, data: TChangePasswordDTO) => changePasswordResource(data, ctx.controller), {
	onFulfill: (ctx) => {
		callSuccessNotificationAction(ctx, "Password updated successfully");
	},
	onReject: (ctx, err) => {
		callErrorAction(ctx, err);
	}
}).pipe(withAbort());
