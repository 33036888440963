import { Flex } from "antd";
import { FC, useEffect } from "react";

import { JoinWaitingList } from "@/features/join-waiting-list";
import { UseCaseContent, UseCaseDescription, UseCaseHeader } from "@/entities/use-cases";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import assistantIcon from "@/assets/use-cases/script/assistant-icon.svg";
import assistantPlaceholder from "@/assets/use-cases/script/assistant-placeholder.png";
import charactertIcon from "@/assets/use-cases/script/character-icon.svg";
import characterPlaceholder from "@/assets/use-cases/script/character-placeholder.png";
import collobarationPlaceholder from "@/assets/use-cases/script/collobaration-placeholder.png";
import collobarationIcon from "@/assets/use-cases/script/colobaration-icon.svg";
import generatorIcon from "@/assets/use-cases/script/generator-icon.svg";
import generatorPlaceholder from "@/assets/use-cases/script/generator-placeholder.png";
import scriptPlaceholder from "@/assets/use-cases/script-placeholder.png";

export const UseCaseScript: FC = () => {
	const { isTablet, isLaptopS, isLaptopM, isMobileL, isMobileS } = useMediaQueries();

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex className="landing-content-center" vertical align="center" justify="center">
			<UseCaseHeader
				title="Script breakdowns made effortless"
				description="Boost your productivity with our AI-driven tool, delivering swift script breakdowns and shot outlines, ensuring your projects move from concept to reality in record time."
				img={scriptPlaceholder}
			/>
			<UseCaseContent>
				<Flex vertical={isTablet} className="gap-l">
					<UseCaseDescription
						img={generatorIcon}
						title="Screenplay generator"
						description="Upload your screenplay to Blooper and get a script breakdown immediately. You don't have a script yet? We've got you covered. Enter a project briefing and Blooper provides you with a draft."
					/>
					<img
						style={{
							maxWidth: isLaptopS ? "415px" : isLaptopM ? "530px" : "630px",
							maxHeight: isMobileS ? "183px" : isMobileL ? "259px" : isLaptopS ? "298px" : isLaptopM ? "381px" : "450px"
						}}
						src={generatorPlaceholder}
						alt="Script generator"
						className="landing-content-center__img"
					/>
				</Flex>
				<Flex vertical={isTablet} className="gap-l">
					{!isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "415px" : isLaptopM ? "530px" : "630px",
								maxHeight: isMobileS ? "140px" : isMobileL ? "197px" : isLaptopS ? "234px" : isLaptopM ? "289px" : "343px"
							}}
							className="landing-content-center__img"
							src={assistantPlaceholder}
							alt="AI Assistant"
						/>
					)}
					<UseCaseDescription
						img={assistantIcon}
						title="Script breakdown"
						description="Define the shots of your movie by hand or let Blooper do it - you decide. Our AI-driven assistant analyzes your script and proposes a shot outline."
					/>
					{isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "415px" : isLaptopM ? "530px" : "630px",
								maxHeight: isMobileS ? "140px" : isMobileL ? "197px" : isLaptopS ? "234px" : isLaptopM ? "289px" : "343px"
							}}
							className="landing-content-center__img"
							src={assistantPlaceholder}
							alt="AI Assistant"
						/>
					)}
				</Flex>
				<Flex vertical={isTablet} className="gap-l">
					<UseCaseDescription
						img={charactertIcon}
						title="Character creation"
						description="Our AI assistant recognizes characters in your script and provides you with a selection of people to embody them in your storyboard."
					/>
					<img
						className="landing-content-center__img"
						src={characterPlaceholder}
						alt="Character creation"
						style={{
							maxWidth: isLaptopS ? "390px" : isLaptopM ? "500px" : "600px",
							maxHeight: isMobileS ? "183px" : isMobileL ? "258px" : isLaptopS ? "279px" : isLaptopM ? "357px" : "429px"
						}}
					/>
				</Flex>
				<Flex vertical={isTablet} className="gap-l">
					{!isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "400px" : isLaptopM ? "545px" : "575px",
								maxHeight: isMobileS ? "167px" : isMobileL ? "235px" : isLaptopS ? "261px" : isLaptopM ? "355px" : "375px"
							}}
							className="landing-content-center__img"
							src={collobarationPlaceholder}
							alt="Collaboration tool"
						/>
					)}
					<UseCaseDescription
						img={collobarationIcon}
						title="Collaboration tool"
						description="Share and discuss ideas with your co-workers directly within the script, making collaboration easier than ever before."
					/>
					{isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "400px" : isLaptopM ? "545px" : "575px",
								maxHeight: isMobileS ? "167px" : isMobileL ? "235px" : isLaptopS ? "261px" : isLaptopM ? "355px" : "375px"
							}}
							className="landing-content-center__img"
							src={collobarationPlaceholder}
							alt="Collaboration tool"
						/>
					)}
				</Flex>
			</UseCaseContent>
			<JoinWaitingList style={{ maxWidth: "1440px" }} />
		</Flex>
	);
};
