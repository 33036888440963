import { useAtom } from "@reatom/npm-react";
import { Input } from "antd";
import { ChangeEvent, FC, useEffect } from "react";
import { searchTeamMemberAtom } from "@/features/team-settings";

import search from "@/assets/shared/search.svg";

export const SearchTeamMember: FC = () => {
	const [searchTeamMember, setSearchTeamMember] = useAtom(searchTeamMemberAtom);

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchTeamMember(event.target.value);
	};

	useEffect(() => {
		return () => {
			setSearchTeamMember("");
		};
	}, []);

	return (
		<Input
			prefix={<img src={search} alt="search" />}
			value={searchTeamMember}
			onChange={onChange}
			placeholder="Start search team member"
			style={{
				width: "315px"
			}}
		/>
	);
};
