import { FC, PropsWithChildren } from "react";

import "./Wave.scss";

type Props = {
	isActive: boolean;
}

export const Wave: FC<PropsWithChildren<Props>> = ({ children, isActive }) => {
	return (
		<div className="wave-container">
			{children}
			{isActive && (
				<>
					<span className="wave"></span>
					<span className="wave"></span>
					<span className="wave"></span>
					<span className="wave"></span>
				</>
			)}
		</div>
	);
};
