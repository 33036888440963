import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { CloseWrapper } from "@/shared/ui";

import { promoCodeAtom } from "../../model";
import { PlanCard } from "./PlanCard";
import { PromotionalCode } from "./PromotionalCode";

interface IChooseYourPlan {
	closeAction: () => void;
}
export const ChooseYourPlan: FC<IChooseYourPlan> = ({ closeAction }) => {
	const navigate = useNavigate();
	const { userId } = useParams();
	const [promoCode] = useAtom(promoCodeAtom);

	const getPlan = (type: "year" | "month") => {
		navigate(`/payment/${userId}/${type}`);
	};

	return (
		<CloseWrapper closeAction={closeAction}>
			<Flex vertical justify="center" align="center" className="gap-s">
				<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
					Choose your plan
				</Typography.Text>
				{!!promoCode && (
					<div className="promo-code-notification full-width">
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.Green800)}>
							25% off all payments
						</Typography.Text>
					</div>
				)}
				<Flex className="gap-xs">
					<PlanCard
						title="Monthly"
						term="month"
						paidAmount={30}
						featureList={["access to all features", "€10 generations credits per month"]}
						getPlan={() => getPlan("month")}
					/>
					<PlanCard
						title="Annual"
						term="year"
						paidAmount={240}
						percent={20}
						featureList={["access to all features", "€100 generations credits per month"]}
						getPlan={() => getPlan("year")}
					/>
				</Flex>
				<PromotionalCode />
			</Flex>
		</CloseWrapper>
	);
};
