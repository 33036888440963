import { useAction } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { useParams } from "react-router-dom";
import { AddScriptModal } from "@/features/add-new-project";
import { generateAction } from "@/entities/script";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useOpenModal } from "@/shared/hooks";
import description from "@/assets/description/empty-description.svg";
import stick from "@/assets/description/stick.svg";
import upload from "@/assets/description/upload.svg";

import "./EmptyDescription.scss";

export const EmptyDescription: FC = () => {
	const { id } = useParams();
	const generateScene = useAction(generateAction);
	const [isOpen, toggleModal] = useOpenModal();

	const handleUploadScript = () => {
		toggleModal(true);
	};

	const handleGenerate = () => {
		if (id) {
			generateScene(id);
		}
	};

	return (
		<Flex
			vertical
			align="center"
			justify="center"
			className="empty-description gap-m full-height"
		>
			<img src={description} alt="description" className="empty-description__img" />
			<Flex vertical className="gap-s">
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>
					Add script
				</Typography.Text>
				<Flex vertical>
					<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
						Upload your script and split it into shots
					</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
						or
					</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
						Write a short description and we will generate it for you
					</Typography.Text>
				</Flex>
			</Flex>
			<Flex align="center" justify="center" className="gap-xs">
				<Button
					onClick={handleUploadScript}
					className="flex-ctr-ctr"
					icon={<img src={upload} alt="upload" />}
					type="primary"
				>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Upload script</Typography.Text>
				</Button>
				{NOT_RELEASE && (
					<Button
						onClick={handleGenerate}
						className="flex-ctr-ctr"
						icon={<img src={stick} alt="stick" />}
						type="primary"
					>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Generate</Typography.Text>
					</Button>
				)}
			</Flex>
			<AddScriptModal toggleModal={toggleModal} isOpen={isOpen} />
		</Flex>
	);
};
