import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Form, Modal, Select, Typography } from "antd";
import { FC } from "react";
import { TEAM_MEMBER_MAP, TEAM_MEMBER_ROLE_MAP } from "@/features/team-settings/lib";
import { TeamMemberPosition, TeamMemberRole } from "@/shared/api/teams";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import {
	changeTeamMemberPermissionsAction,
	currentTeamMember,
	isOpenChangeTeamPermissionsModalAtom
} from "../model";

type FieldTypeRole = {
	role: TeamMemberRole;
}

type FieldTypePosition = {
	position: TeamMemberPosition;
}

export const ChangeTeamPermissionsModal: FC = () => {
	const updateTeamMember = useAction(changeTeamMemberPermissionsAction);
	const [isOpen, setIsOpen] = useAtom(isOpenChangeTeamPermissionsModalAtom);
	const [current] = useAtom(currentTeamMember);

	const positionOptions = Object.entries(TEAM_MEMBER_MAP).map(([key, data]) => ({
		value: key,
		label: data.title
	}));

	const roleOptions = Object.entries(TEAM_MEMBER_ROLE_MAP).map(([key, data]) => ({
		value: key,
		label: data.title
	}));

	const onFinishPosition = (values: FieldTypePosition) => {
		updateTeamMember({
			email: current?.email ?? "",
			role: current?.role ?? "admin",
			position: values.position
		});
	};

	const onFinishRole = (values: FieldTypeRole) => {
		updateTeamMember({
			email: current?.email ?? "",
			position: current?.position ?? "line_producer",
			role: values.role
		});
	};

	return (
		<Modal open={isOpen} onCancel={() => setIsOpen(false)} footer={[]}>
			<Flex vertical className="gap-s text-center">
				<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
					Change role or position {current?.email}
				</Typography.Text>
			</Flex>
			<Flex vertical className="gap-s" style={{ paddingTop: 32 }}>
				<Form onFinish={onFinishPosition}>
					<Flex key="unique" className="gap-xxs" justify="space-between">
						<Form.Item<FieldTypePosition>
							className="full-width"
							name="position"
							rules={[{ required: true, message: "Missing position" }]}
						>
							<Select
								placeholder="Position"
								style={{ height: 44 }}
								options={positionOptions}
								defaultValue={current?.position}
							/>
						</Form.Item>
						<Button htmlType="submit" type="primary">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save</Typography.Text>
						</Button>
					</Flex>
				</Form>
				<Form onFinish={onFinishRole}>
					<Flex key="unique" className="gap-xxs" justify="space-between">
						<Form.Item<FieldTypeRole>
							className="full-width"
							name="role"
							rules={[{ required: true, message: "Missing role" }]}
						>
							<Select
								placeholder="Position"
								style={{ height: 44 }}
								options={roleOptions}
								defaultValue={current?.role}
							/>
						</Form.Item>
						<Button htmlType="submit" type="primary">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save</Typography.Text>
						</Button>
					</Flex>
				</Form>
			</Flex>
		</Modal>
	);
};
