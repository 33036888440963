import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useContext } from "react";
import { NavLink } from "react-router-dom";

import { DropdownContent } from "@/widgets/header/dropdown-content";
import { LandingLayoutContext } from "@/widgets/landing-layout";
import arrow from "@/assets/shared/arrow_green.svg";
import arrowGreenBottom from "@/assets/shared/arrow_green_down.svg";
import close from "@/assets/shared/close_square.svg";
import menu from "@/assets/shared/menu.svg";
import { LINKS } from "@/shared/const/links";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import "./BurgerMenu.scss";

export const BurgerMenu: FC = () => {
	const { isMobileXL } = useMediaQueries();
	const { isOpen, toggleOpen } = useContext(LandingLayoutContext);
	const [isOpenLinks, toggleOpenLinks] = useOpenModal();

	return (
		<Flex className="burger-menu">
			<div onClick={toggleOpen} role="button" className="burger-menu__button">
				<img className="burger-menu__img" src={isOpen ? close : menu} alt={isOpen ? "Close" : "Menu"} />
				{!isMobileXL && <Typography.Text>{isOpen ? "Close" : "Menu"}</Typography.Text>}
			</div>
			<Flex vertical className={cn("burger-menu__content", {
				"burger-menu__open": isOpen
			})}>
				{LINKS.map((link, index) => Array.isArray(link.url)
					? (
						<Flex key={index.toString(36)} vertical>
							<Flex onClick={() => toggleOpenLinks(!isOpenLinks)} className="burger-menu__link" justify="space-between" align="center">
								<Typography.Text className={cn("burger-menu__link__text", TEXT_CLASSNAMES.LPHeadlineH4)}>
									{link.title}
								</Typography.Text>
								<img className={cn("burger-menu__link__arrow cubic-animation", {
									"burger-menu__link__arrow-active": isOpenLinks
								})} src={arrowGreenBottom} alt="arrow" />
							</Flex>
							{isOpenLinks && (
								<Flex vertical className="gap-xs">
									{link.url.map((value, index) => (
										<DropdownContent {...value} className="burger-menu__link__case" onClose={toggleOpen} key={index} />
									))}
								</Flex>
							)}
						</Flex>
					)
					: (
						<NavLink onClick={toggleOpen} className="burger-menu__link" key={index.toString(36)} to={link.url}>
							<Flex justify="space-between" align="center">
								<Typography.Text className={cn("burger-menu__link__text", TEXT_CLASSNAMES.LPHeadlineH4)}>
									{link.title}
								</Typography.Text>
								<img src={arrow} alt="arrow" />
							</Flex>
						</NavLink>
					))}
			</Flex>
		</Flex>
	);
};
