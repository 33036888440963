import { useAction } from "@reatom/npm-react";
import { Flex, Form, Input, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { DisableSubmitButton } from "@/shared/ui";

import { getPromoCodeAction } from "../../model";

type FieldType = {
	code: string;
}

export const PromotionalCode: FC = () => {
	const [form] = Form.useForm<FieldType>();
	const getPromoCode = useAction(getPromoCodeAction);

	const onFinish = (data: FieldType) => {
		getPromoCode(data.code);
	};

	return (
		<Form onFinish={onFinish} className="full-width" form={form}>
			<Flex vertical className="full-width gap-xs">
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH4}>
					Do you have a promotional code?
				</Typography.Text>
				<Flex className="gap-xs">
					<Form.Item<FieldType> className="flex-1" name="code" rules={[{ required: true, message: " " }]}>
						<Input
							onChange={(event) => form.setFieldValue("code", event.target.value)}
							placeholder="Enter code here"
						/>
					</Form.Item>
					<DisableSubmitButton type="primary" form={form}>Apply</DisableSubmitButton>
				</Flex>
			</Flex>
		</Form>
	);
};
