import scriptPlaceholder from "@/assets/product/script-placeholder.png";
import script from "@/assets/product/script.svg";
import storyboardPlaceholder from "@/assets/product/storyboard-placeholder.png";
import storyboard from "@/assets/product/storyboard.svg";
import videoPlacehodler from "@/assets/product/video-placeholder.png";
import video from "@/assets/product/video.svg";

export const PRODUCT_FEATURE_LIST = [
	{
		img: script,
		placeholderImg: scriptPlaceholder,
		title: "Script breakdowns made effortless",
		description: "Quickly transform your screenplay into a detailed shot outline with our intuitive script editor.",
		tags: ["Writers"],
		link: "/use-case-script"
	},
	{
		img: storyboard,
		placeholderImg: storyboardPlaceholder,
		title: "Harness AI to instantly convert scripts into storyboards",
		description: "Instantly create storyboards and moodboards just from your script with our AI.",
		tags: ["Directors"],
		link: "/use-case-storyboard"
	},
	{
		img: video,
		placeholderImg: videoPlacehodler,
		title: "Accelerate postproduction with our video assistant",
		description: "Craft rough cut and compelling visuals effortlessly with our the help of our video assistant.",
		tags: ["Video Editors"],
		link: "/use-case-video"
	}
];
