import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useState } from "react";
import ReactLassoSelect from "react-lasso-select";
import { Point } from "react-lasso-select/lib/helpers";
import { TransformComponent } from "react-zoom-pan-pinch";

import { getShotAction } from "@/entities/storyboard";

export const Content: FC = () => {
	const [points, setPoints] = useState<Point[]>([]);
	const [shot] = useAtom(getShotAction.dataAtom);

	return (
		<Flex justify="center" align="center" className="flex-1">
			<TransformComponent>
				<ReactLassoSelect
					imageStyle={{ width: "100%", height: "100%" }}
					value={points}
					src={shot?.data?.image_url ? `${shot?.data?.image_url}?no-cache=${Date.now()}` : ""}
					// src={testImage}
					onChange={value => {
						setPoints(value);
					}}
				/>
			</TransformComponent>
		</Flex>
	);
};
