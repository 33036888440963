import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { descriptionStatusAtom } from "@/entities/script";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./DescriptionBottomActions.scss";

interface IDescriptionBottomActions {
	onNext: () => void;
}

export const DescriptionBottomActions: FC<IDescriptionBottomActions> = ({ onNext }) => {
	const { id } = useParams();
	const [descriptionStatus] = useAtom(descriptionStatusAtom);
	const navigate = useNavigate();

	const onSkip = () => {
		navigate(`/storyboard/${id}/moodboard`);
	};

	return (
		<Flex align="center" justify="end" className="description-bottom-actions">
			{descriptionStatus === "full" && (
				<Button className="description-bottom-actions__button" onClick={onNext} type="primary">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Next</Typography.Text>
				</Button>
			)}
			{descriptionStatus === "empty" && (
				<Button type="primary" onClick={onSkip}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Skip step</Typography.Text>
				</Button>
			)}
		</Flex>
	);
};
