import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";

import arrow from "@/assets/shared/arrow_green.svg";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

interface IDropdownContent extends HTMLAttributes<HTMLDivElement>{
	link: string;
	onClose: () => void;
	img: string;
	description: string;
}

export const DropdownContent: FC<IDropdownContent> = ({ link, onClose, img, description, ...props }) => {
	const navigate = useNavigate();
	const { isTablet, isMobileL } = useMediaQueries();
	const onRedirect = () => {
		navigate(link);
		onClose();
	};

	return (
		<Flex
			{...props}
			role="button"
			onClick={onRedirect}
			align="center"
			justify="space-between"
			className={cn("header-dropdown__block gap-s pointer", props.className)}
		>
			<Flex align={(!isTablet || isMobileL) ? "start" : "center"} vertical={!isTablet || isMobileL} className="gap-xxs">
				<img src={img} alt={link} className="header-dropdown__img" />
				<Typography.Text>{description}</Typography.Text>
			</Flex>
			<div role="button">
				<img src={arrow} alt="arrow"/>
			</div>
		</Flex>
	);
};
