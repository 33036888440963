import { action, atom } from "@reatom/core";

import { AxiosError } from "axios";
import { Layout } from "react-grid-layout";
import {
	TGridType,
	TMoodBoardStatus,
	TRightSidebarView
} from "@/entities/moodboard/lib";
import { callErrorAction } from "@/entities/notification";
import {
	addMoodboardImages,
	addMoodboardsImagesCoordinates,
	deleteMoodboardImages,
	getAllMoodboardImages,
	getMoodboardsResource,
	TMoodboardImage,
	TMoodBoardImageCoordinate
} from "@/shared/api/moodboard";
import {
	base64toBlob,
	getAspectRatio,
	getGcd,
	getImageFromBlob
} from "@/shared/methods";

export const moodboardLoadingAtom = atom<boolean>(false);
export const moodBoardNamesAtom = atom<string[]>([], "moodBoardNamesAtom");
export const moodBoardListAtom = atom<TMoodboardImage[]>([], "moodBoardListAtom");
export const moodBoardGridTypeAtom = atom<TGridType>("structure", "moodBoardGridTypeAtom");
export const moodBoardSidebarView = atom<TRightSidebarView>("inspiration");
export const moodBoardStatusAtom = atom<TMoodBoardStatus>((ctx) => {
	const list = ctx.spy(moodBoardListAtom);

	return list.length > 0 ? "full" : "empty";
}, "moodBoardStatusAtom");

export const getMoodboardAction = action(async (ctx, id: string, isHandle?: boolean) => {
	try {
		moodboardLoadingAtom(ctx, true);

		const imagesIds = await getMoodboardsResource(id);
		const moodboardImages = await getAllMoodboardImages(id, imagesIds.data.images["non-archived"].map((image) => image.id));

		if (isHandle) {
			const coordinatesPromises: Promise<TMoodBoardImageCoordinate>[] = moodboardImages.data.images.map(async (image, index) => {
				const { width, height } = await getImageFromBlob(base64toBlob(image.data));
				const aspectRatio = await getAspectRatio(width, height);
				const { numerator, denominator } = getGcd(aspectRatio);

				return {
					id: image.id,
					w: numerator,
					h: denominator,
					minW: 2,
					minH: 2,
					x: index + 1,
					y: 1
				};
			});

			const coordinates = await Promise.all(coordinatesPromises);

			await addMoodboardsImagesCoordinates(id, coordinates);
			const updatedImages = moodboardImages.data.images.map((image) => {
				return {
					...image,
					coordinates: coordinates.find((coord) => coord.id === image.id) ?? image.coordinates,
					data: URL.createObjectURL(base64toBlob(image.data))
				};
			});

			moodBoardListAtom(ctx, updatedImages);
		} else {
			const images = moodboardImages.data.images.map((image) => ({
				...image,
				data: URL.createObjectURL(base64toBlob(image.data))
			}));

			moodBoardListAtom(ctx, images);
		}
	} catch (err) {
		moodBoardListAtom(ctx, []);
		moodboardLoadingAtom(ctx, false);
		callErrorAction(ctx, err as AxiosError);
	} finally {
		moodboardLoadingAtom(ctx, false);
	}
});

export const addImageToMoodboardAction = action(async (ctx, id: string, formData: FormData) => {
	try {
		moodboardLoadingAtom(ctx, true);
		await addMoodboardImages(id, formData);
		await getMoodboardAction(ctx, id, true);
	} catch (err) {
		moodboardLoadingAtom(ctx, false);
		callErrorAction(ctx, err as AxiosError);
	}
});

export const dropImageToMoodboardAction = action(async (ctx, id: string, formData: FormData, coordinates: TMoodBoardImageCoordinate) => {
	try {
		const { data } = await addMoodboardImages(id, formData);
		const image = {
			...coordinates,
			id: data.ids[0]
		};

		await addMoodboardsImagesCoordinates(id, [image]);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const updateMoodboardImagesPositionAction = action(async (_ctx, id: string, layout: Layout[]) => {
	const images: TMoodBoardImageCoordinate[] = layout.map((image) => ({
		w: image.w,
		h: image.h,
		x: image.x,
		y: image.y,
		minW: image.minW ?? 2,
		minH: image.minH ?? 2,
		id: image.i
	})).filter((image) => image.id !== "special");

	await addMoodboardsImagesCoordinates(id, images);
}, "updateMoodboardImagesPosition");

export const clearMoodBoardAction = action(async (ctx, id) => {
	try {
		moodboardLoadingAtom(ctx, true);
		const imageIdList = ctx.get(moodBoardListAtom).map((image) => image.id);

		await deleteMoodboardImages(id, imageIdList);
		await getMoodboardAction(ctx, id);
	} catch (err) {
		moodboardLoadingAtom(ctx, false);
		callErrorAction(ctx, err as AxiosError);
	}
});

export const removeFromMoodBoardAction = action(async (ctx, moodboardId: string, imageId: string) => {
	try {
		moodBoardListAtom(ctx, ctx.get(moodBoardListAtom).filter((item) => item.id !== imageId));

		await deleteMoodboardImages(moodboardId, [imageId]);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const addToMoodBoardAction = action((ctx, image: TMoodboardImage) => {
	moodBoardListAtom(ctx, [...ctx.get(moodBoardListAtom), image]);
});

export const updateAllMoodBoardAction = action((ctx, images: TMoodboardImage[]) => {
	moodBoardListAtom(ctx, [...images]);
});

export const toggleStaticImageOnMoodBoardAction = action((ctx, id: string) => {
	moodBoardListAtom(ctx, ctx.get(moodBoardListAtom).map((item) => ({
		...item,
		isStatic: item.id === id ? !item.isStatic : item.isStatic
	})));
});
