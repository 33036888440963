import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";
import { axiosErrorHandler } from "@/shared/api";
import { IAxiosError, IStockError } from "@/vite-env";

export type TError = IAxiosError<{ message: string }> | IStockError<unknown>;

export const errorAtom = atom<TError | null>(null);

export const successAtom = atom<{ message: string } | null>(null);

export const callSuccessNotificationAction = action((ctx, message: string) => {
	successAtom(ctx, { message });
});

export const callErrorAction = action((ctx, err: unknown) => {
	console.error(err);
	errorAtom(ctx, axiosErrorHandler<{ message: string }>(err as AxiosError));
});

export const clearErrorAction = action((ctx) => {
	errorAtom(ctx, null);
});
