import { Flex } from "antd";
import { FC, PropsWithChildren } from "react";

import "./UseCaseContent.scss";

export const UseCaseContent: FC<PropsWithChildren> = ({ children }) => (
	<Flex gap={80} align="center" vertical className="use-case-content full-width text-center">
		{children}
	</Flex>
);
