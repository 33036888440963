import { useAction } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import { FC } from "react";

import { useParams } from "react-router-dom";
import { ColorShotSelection } from "@/features/color-shot-select";
import { addShotToSceneAction, deleteShotAction, TShot } from "@/entities/script";
import deleteImg from "@/assets/shared/delete.svg";
import plus from "@/assets/shared/plus.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { DeleteSceneModal } from "./DeleteSceneModal";

import "./SceneShotOutline.scss";
interface IScene {
	shots: TShot[];
	sceneTitle: string;
	sceneId: string;
	sceneIdx: number;
}

export const SceneShotOutline: FC<IScene> = ({ sceneTitle, shots, sceneId, sceneIdx }) => {
	const { id } = useParams();
	const addShot = useAction(addShotToSceneAction);
	const deleteShot = useAction(deleteShotAction);
	const lastShotId = shots[shots.length - 1]?.id;

	const handleAddShot = () => {
		if (id) {
			addShot(id, { sceneId, shotId: lastShotId });
		}
	};

	const handleDeleteShot = (shotId: string) => {
		if (id) {
			deleteShot(id, { sceneId, shotId });
		}
	};

	return (
		<Flex vertical className="gap-xs scene">
			<Flex align="center" justify="space-between" gap={2}>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular} ellipsis>Scene {sceneIdx + 1} "{sceneTitle}"</Typography.Text>
				<DeleteSceneModal sceneId={sceneId} />
			</Flex>
			<Flex vertical className="gap-xxs">
				{shots.map(({ title, color, id, idx }) => (
					<Flex key={id} align="center" justify="space-between" className="scene__shot pointer">
						<Flex align="center" className="gap-xxs">
							<ColorShotSelection
								placement="left"
								defaultColor={color}
								shotId={id}
								sceneId={sceneId}
							/>
							<Typography.Text style={{ whiteSpace: "nowrap", maxWidth: 250 }} ellipsis className={TEXT_CLASSNAMES.XsRegular}>Shot {idx + 1} {!!title.length && `"${title}"`}</Typography.Text>
						</Flex>
						<div role="button" onClick={() => handleDeleteShot(id)}>
							<img className="scene__shot--delete" src={deleteImg} alt="delete" />
						</div>
					</Flex>
				))}
				<Flex onClick={handleAddShot} align="center" className="gap-xxs pointer">
					<img src={plus} alt="plus" className="scene__img" />
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add shot</Typography.Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
