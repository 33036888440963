import { useAction } from "@reatom/npm-react";
import { useDebounceFn, useKeyPress } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { updateShotTitleInSceneAction } from "@/entities/script";
import { TShotColor } from "@/shared/api/highlight-the-shots";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./ShotTitle.scss";

type Props = {
	color: TShotColor;
	isHover: boolean;
	isActive: boolean;
	shotIdx: number;
	title: string;
	shotId: string;
	sceneId: string;
}

export const ShotTitle: FC<Props> = ({ color, isHover, isActive, shotIdx, title, shotId, sceneId }) => {
	const { id } = useParams();
	const titleRef = useRef<HTMLSpanElement>(null);
	const updateShot = useAction(updateShotTitleInSceneAction);
	const [isChangeActive, setIsChangeActive] = useState(false);

	const { run } = useDebounceFn((event: ChangeEvent<HTMLInputElement>) => {
		const { innerText } = event.target;

		if (id) {
			updateShot(id, { shotId, sceneId, title: innerText });
		}
	}, { wait: 500 });

	useKeyPress(
		"enter",
		() => {
			if (titleRef.current) {
				titleRef.current.innerText = titleRef.current.innerText.trim();
			}
		},
		{
			target: titleRef
		}
	);

	useEffect(() => {
		if (titleRef.current) {
			titleRef.current.innerText = title;
		}
	}, []);

	return (
		<Flex
			align="center"
			className={cn("shot__title cubic-animation gap-4", `shot__title__${color}`, {
				shot__title__active: isHover || isActive || isChangeActive
			})}
		>
			<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}>
				Shot {shotIdx + 1}
				&nbsp;
				"
				<span
					ref={titleRef}
					className="shot__title-edit"
					role="textbox"
					contentEditable
					onInput={run}
					onBlur={() => setIsChangeActive(false)}
					onFocus={() => setIsChangeActive(true)}
				/>
				"
			</Typography.Text>
		</Flex>
	);
};
