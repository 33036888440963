import { Button, Flex, Modal, Typography } from "antd";
import { FC, MouseEvent } from "react";
import { Generate } from "@/features/storyboard-view/utils";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";

type Props = {
	onGenerateImages: (shots: Generate[]) => (event: MouseEvent<HTMLElement>) => Promise<void>;
	shots: Generate[];
} & IOpenModal;

export const RegenerateImagesModal: FC<Props> = ({ toggleModal, isOpen, onGenerateImages, shots }) => {
	const generatedShots = shots.filter((shot) => shot.isRegenerate);

	const handleGenerate = (event: MouseEvent<HTMLElement>) => {
		onGenerateImages(shots)(event);
		toggleModal(false);
	};

	return (
		<Modal open={isOpen} onCancel={() => toggleModal(false)} footer={[]}>
			<Flex vertical className="gap-m">
				<Flex vertical className="gap-4 text-center">
					<Typography.Text className={TEXT_CLASSNAMES.HeadlineH3}>{generatedShots.length} of the {shots.length} images were generated</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Do you want to generate the {shots.length - generatedShots.length} remaining?</Typography.Text>
				</Flex>
				<Flex align="center" className="gap-xs">
					<Button className="full-width">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
					</Button>
					<Button onClick={handleGenerate} type="primary" className="full-width">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Yes, generate</Typography.Text>
					</Button>
				</Flex>
			</Flex>
		</Modal>
	);
};
