import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { getSettingsProfileAction } from "@/pages/settings";
import { AccountSettingsSkeleton } from "@/pages/settings/ui/settings-account/AccountSettingsSkeleton";
import { DeleteAccountForm } from "@/pages/settings/ui/settings-account/DeleteAccountForm";
import { NOT_RELEASE } from "@/shared/const/release";
import { ctx } from "@/shared/ctx";
import { AccountSettingsForm } from "./AccountSettingsForm";
import { UpdatePasswordForm } from "./UpdatePasswordForm";

export const SettingsAccount: FC = () => {
	const getProfile = useAction(getSettingsProfileAction);
	const [status] = useAtom(getSettingsProfileAction.statusesAtom);

	useEffect(() => {
		getProfile(false);

		return () => {
			getSettingsProfileAction.abort(ctx);
		};
	}, []);

	return (
		<Flex vertical className="gap-xs">
			<Flex className="gap-xs">
				{NOT_RELEASE && (
					<Flex vertical className="gap-xs">
						{status.isPending ? <AccountSettingsSkeleton /> : <AccountSettingsForm />}
						<Flex className="gap-xs full-width">
							<DeleteAccountForm />
						</Flex>
					</Flex>
				)}
				<UpdatePasswordForm />
			</Flex>
		</Flex>
	);
};
