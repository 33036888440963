import { FC, SVGProps } from "react";
import { COLOR_NAMES } from "@/shared/const/color-names";

export const StructureGrid: FC<SVGProps<unknown>> = ({ fill = COLOR_NAMES.TextSecondary, className }) => {
	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<g clipPath="url(#clip0_498_18772)">
				<path d="M7.5 2.5V17.5M12.5 2.5V17.5M2.5 3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5H3.33333C3.11232 17.5 2.90036 17.4122 2.74408 17.2559C2.5878 17.0996 2.5 16.8877 2.5 16.6667V3.33333Z" stroke="#616161" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<line x1="2.5" y1="10.083" x2="17.5" y2="10.083" stroke={fill} strokeWidth="1.5"/>
			</g>
			<defs>
				<clipPath id="clip0_498_18772">
					<rect width="20" height="20" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
};
