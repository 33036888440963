import { Button, Flex, Modal, Segmented, Typography } from "antd";
import cn from "classnames";
import { saveAs } from "file-saver";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { TStoryboardScene } from "@/entities/storyboard";
import { COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";

import { Spinner } from "@/shared/ui";
import excelImg from "@/assets/storyboard/excel.svg";
import pdfImg from "@/assets/storyboard/pdf.svg";

type Props = IOpenModal & {
	isPending: boolean;
	storyboardList: TStoryboardScene[]
	toPDF: () => void;
};
type FileType = "excel" | "pdf";

export const ExportStoryboardModal: FC<Props> = ({ toggleModal, isOpen, isPending, storyboardList, toPDF }) => {
	const [file, setFile] = useState<FileType>("pdf");
	const { id } = useParams();
	const save = () => {
		if (file === "excel") {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const shots = storyboardList.flatMap((storyboard) => storyboard.shots.map(({ id, ...shot }) => ({
				...shot,
				sceneTitle: storyboard.title
			})));

			const worksheet = XLSX.utils.json_to_sheet(shots);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
			const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
			saveAs(blob, `${id}.xlsx`);
		}

		if (file === "pdf") {
			toPDF();
		}

		toggleModal(false);
	};

	return (
		<Modal
			open={isOpen}
			onCancel={() => toggleModal(false)}
			footer={() => isPending
				? null
				: (
					<Button type="primary" className="full-width" onClick={save}>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>Export</Typography.Text>
					</Button>
				)}
		>
			{isPending
				? (
					<Spinner />
				)
				: (
					<Flex vertical className="gap-s text-center">
						<Flex vertical align="flex-start">
							<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>Export Storyboard</Typography.Text>
							<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>Select the file type and view you would like to export</Typography.Text>
						</Flex>
						<Segmented
							style={{
								border: `1px solid ${COLOR_NAMES.MetalGray300}`,
								padding: 4
							}}
							size="large"
							block
							onChange={(value) => setFile(value as FileType)}
							options={[
								{
									label: (
										<Flex style={{ height: 40 }} align="center" justify="center" className="full-height gap-xxs">
											<img src={pdfImg} alt="pdf" />
											<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>PDF file</Typography.Text>
										</Flex>
									),
									value: "pdf"
								},
								{
									label: (
										<Flex style={{ height: 40 }} align="center" justify="center" className="full-height gap-xxs">
											<img src={excelImg} alt="excel" />
											<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>Excel file</Typography.Text>
										</Flex>
									),
									value: "excel"
								}
							]}
						/>
					</Flex>
				)}
		</Modal>
	);
};
