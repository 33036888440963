import { UserOutlined } from "@ant-design/icons";
import { useAction } from "@reatom/npm-react";
import { Avatar, Flex, Popover, Tag, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";

import {
	openChangeTeamPermissionsModalAction,
	setCurrentTeamMemberAction
} from "@/features/change-team-permissions-modal";
import { TEAM_MEMBER_MAP, TTeamMember } from "@/features/team-settings/lib";
import arrow from "@/assets/shared/arrow_down.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./TeamGridCard.scss";

export const TeamGridCard: FC<TTeamMember> = ({ name, email, position, role, projectsCount, avatar }) => {
	const [open, setOpen] = useState(false);
	const openModal = useAction(openChangeTeamPermissionsModalAction);
	const setCurrentTeamMember = useAction(setCurrentTeamMemberAction);

	const hide = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	const onEdit = () => {
		hide();
		setCurrentTeamMember({
			role,
			position,
			email
		});
		openModal();
	};

	return (
		<Flex vertical className="team-grid-card gap-s">
			<Flex className="gap-xxs" align="center">
				<Avatar shape="square" size={50} icon={avatar ? <img src={avatar} alt={name} /> : <UserOutlined />} />
				<Flex vertical>
					<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>{name}</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>{email}</Typography.Text>
				</Flex>
			</Flex>
			<Flex vertical className="gap-xxs">
				<span>
					<Tag className={cn("team-grid-card__tag", `team-grid-card__tag--${position}`)}>{TEAM_MEMBER_MAP[position].title}</Tag>
				</span>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>{projectsCount} projects allowed</Typography.Text>
			</Flex>
			<Popover
				title="Actions"
				trigger="click"
				open={open}
				onOpenChange={handleOpenChange}
				content={() => (
					<Flex vertical className="gap-xxs">
						<Flex role="button" onClick={onEdit} align="center" className="gap-xxs pointer">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Edit</Typography.Text>
						</Flex>
						<Flex role="button" onClick={() => {}} align="center" className="gap-xxs pointer">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Delete</Typography.Text>
						</Flex>
					</Flex>
				)}
			>
				<Flex align="center" className="pointer gap-4">
					<Typography.Text className={TEXT_CLASSNAMES.XxsSemibold}>More actions</Typography.Text>
					<img src={arrow} alt="arrow" className={cn("team-grid-card__arrow cubic-animation", { "team-grid-card__arrow--open": open })} />
				</Flex>
			</Popover>
		</Flex>
	);
};
