import { reatomAsync } from "@reatom/async";
import { atom } from "@reatom/core";

export const promoCodeAtom = atom<string>("", "promoCodeAtom");

export const getPromoCodeAction = reatomAsync((_ctx, code: string) => new Promise((resolve) => resolve(code)), {
	name: "getPromoCodeAction",
	onFulfill (ctx, code) {
		const promoCode = code as string;
		promoCodeAtom(ctx, promoCode);
	}
});
