import { useAction } from "@reatom/npm-react";
import { Flex, Popover } from "antd";
import cn from "classnames";
import { FC, useState } from "react";

import { useParams } from "react-router-dom";
import { ShotColor, updateShotColorAction } from "@/entities/script";
import { TShotColor } from "@/shared/api/highlight-the-shots";

import "./ColorShotSelection.scss";

interface IColorShotSelection {
	defaultColor: TShotColor;
	placement: "top" | "left";
	shotId: string;
	sceneId: string;
}

export const ColorShotSelection: FC<IColorShotSelection> = ({ defaultColor, placement, shotId, sceneId }) => {
	const { id } = useParams();
	const updateShotColor = useAction(updateShotColorAction);
	const [activeColor, setActiveColor] = useState(defaultColor);
	const shotColors: TShotColor[] = [
		"green",
		"red",
		"blue",
		"violet",
		"orange"
	];

	const handleChangeColor = (color: TShotColor) => {
		if (id) {
			updateShotColor(id, { color, shotId, sceneId });
			setActiveColor(color);
		}
	};

	return (
		<Popover trigger="hover" placement={placement} content={
			<Flex
				align="center"
				justify="center"
				className={cn("color-shot-selection cubic-animation")}
			>
				{shotColors.map((color) => (
					<div key={color} className="color-shot-selection__shot">
						<ShotColor className="pointer" color={color} onClick={() => handleChangeColor(color)} />
					</div>
				))}
			</Flex>
		}>
			<ShotColor color={activeColor} />
		</Popover>
	);
};
