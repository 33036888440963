import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import check from "@/assets/shared/check.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { promoCodeAtom } from "../../model";
import annual from "@/assets/plan/annual.svg";
import monthly from "@/assets/plan/monthly.svg";

import "./PlanCard.scss";

interface IPlanCard {
	term: "month" | "year";
	title: string;
	percent?: number;
	featureList: string[];
	paidAmount: number;
	getPlan: () => void;
}

export const PlanCard: FC<IPlanCard> = ({
	term,
	featureList,
	percent,
	title,
	paidAmount,
	getPlan
}) => {
	const [promoCode] = useAtom(promoCodeAtom);

	const paidAmountResult = promoCode ? (paidAmount - (paidAmount * (25 / 100))) : paidAmount;

	return (
		<Flex vertical justify="flex-end" className="plan-card">
			{percent && (
				<div className="plan-card__save">
					<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>Save {percent}%</Typography.Text>
				</div>
			)}
			<Flex vertical justify="space-between" className="full-height gap-m">
				<Flex vertical className="gap-xxs">
					<img className="plan-card__img" src={term === "month" ? monthly : annual} alt="image"/>
					<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>{title}</Typography.Text>
				</Flex>
				<Flex vertical className="plan-card__cost">
					{!!promoCode && (
						<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH1, COLOR_CLASSNAMES.TextTertiary, "plan-card__decoration")}>
							€{paidAmount}
						</Typography.Text>
					)}
					<Flex align="center" className="gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH3}>
							€{paidAmountResult}
						</Typography.Text>
						{term === "year" &&
							<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>({paidAmountResult / 12}/month)</Typography.Text>}
					</Flex>
					<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>/per {term}</Typography.Text>
				</Flex>
			</Flex>
			<div className="plan-card__divider"/>
			<Flex vertical className="gap-xs plan-card__list">
				{featureList.map((text, idx) => (
					<Flex className="gap-xxs" align="center" key={idx.toString(36)}>
						<img src={check} alt="check"/>
						<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>{text}</Typography.Text>
					</Flex>
				))}
			</Flex>
			<Button onClick={getPlan} type="primary">
				<Typography.Text>
					Get started
				</Typography.Text>
			</Button>
		</Flex>
	);
};
