import { AxiosResponse } from "axios";
import { api } from "@/shared/api";
import { TCreateProjectParams, TProjectResult } from "@/shared/api/project/types";

export const createProjectResource = (params: TCreateProjectParams): Promise<AxiosResponse<unknown>> => api.post("/projects", { ...params });

export const getAllProjectsResource = (): Promise<AxiosResponse<TProjectResult>> => api.get("/projects");

export const getProjectByNameResource = (name: string): Promise<AxiosResponse<TProjectResult>> => api.get(`/projects/${name}`);

export const deleteProjectResource = (name: string): Promise<AxiosResponse<unknown>> => api.delete(`/projects/${name}/`);

export const updateProjectNameResource = (oldName: string, newName: string): Promise<AxiosResponse<unknown>> => api.patch(`/projects/${oldName}/${newName}`);
