import { reatomAsync, withAbort, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { callErrorAction } from "@/entities/notification";
import { getSettingsProfileResource } from "@/shared/api/settings";

export const getAccountAction = reatomAsync((ctx, isAvatar: boolean) => {
	return getSettingsProfileResource(isAvatar, ctx.controller);
}).pipe(
	withDataAtom(),
	withStatusesAtom(),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
	withAbort()
);
