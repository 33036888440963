import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";

import warningModalImage from "@/assets/moodboard/warning-modal.svg";

import "./MoodboardGenerateWarningModal.scss";

interface IMoodboardGenerateWarningModal extends IOpenModal {
	generateMoodBoard: () => void;
}

export const MoodboardGenerateWarningModal: FC<IMoodboardGenerateWarningModal> = ({ isOpen, toggleModal, generateMoodBoard }) => {
	const handleCancel = () => {
		toggleModal();
	};
	const handleOk = () => {
		generateMoodBoard();
		toggleModal();
	};

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			footer={[
				<Flex className="full-width">
					<Button className="flex-1" key="back" onClick={handleCancel}>
						No
					</Button>
					<Button type="primary" className="flex-1" key="back" onClick={handleOk}>
						Yes, generate
					</Button>
				</Flex>
			]}
		>
			<Flex className="generate-warning-modal" vertical align="center">
				<img className="generate-warning-modal__image" src={warningModalImage} alt="warning modal" />
				<Typography.Text
					className={cn("generate-warning-modal__title", TEXT_CLASSNAMES.HeadlineH3)}
				>
					Are you sure you want to generate the <br/> moodboard?
				</Typography.Text>
				<Typography.Text
					className={cn("generate-warning-modal__description", TEXT_CLASSNAMES.SmRegular)}
				>
					All unlocked images will be moved to the archive
				</Typography.Text>
			</Flex>
		</Modal>
	);
};
