import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { AddBriefMoodboard } from "@/features/add-brief-moodboard";
import { AddImageMoodboard } from "@/features/add-image-moodboard";
import { MoodboardDeleteModal, MoodboardGenerateWarningModal } from "@/entities/moodboard";
import clear from "@/assets/shared/clear.svg";
import editStick from "@/assets/shared/edit_stick.svg";
import exportIcon from "@/assets/shared/import.svg";
import magicStick from "@/assets/shared/magic_stick.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { clearMoodBoardAction, moodBoardGridTypeAtom } from "../../model";
import { ChaosGrid, StructureGrid } from "@/assets/components";

import "./MoodboardActions.scss";

export const MoodboardActions: FC = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [isOpenAddBriefnModal, toggleAddBriefModal] = useOpenModal();
	const [isOpenWarningModal, toggleAddWarningModal] = useOpenModal();
	const [isOpenDeleteModal, toggleAddDeleteModal] = useOpenModal();
	const [isOpenAddImage, toggleAddImage] = useOpenModal();

	const [moodBoardGridType, setMoodBoardGridType] = useAtom(moodBoardGridTypeAtom);

	const clearMoodBoard = useAction(clearMoodBoardAction);
	// const addToMoodBoard = useAction(addToMoodBoardAction);

	const generateMoodBoard = () => {};

	const deleteMoodBoard = () => {
		if (id) {
			clearMoodBoard(id);
		}
	};

	const redirectToTheCharacter = () => {
		navigate(`/storyboard/${id}/character`);
	};

	return (
		<Flex align="center" justify="space-between" className="full-width moodboard-actions">
			<Flex wrap="wrap" className="gap-xxs">
				<Flex className="moodboard-actions__board-view moodboard-actions__button-group gap-4">
					<Flex
						onClick={() => setMoodBoardGridType("chaos")}
						justify="center"
						align="center"
						className={cn("moodboard-actions__board-view__button", {
							"moodboard-actions__board-view__active": moodBoardGridType === "chaos"
						})}>
						<ChaosGrid />
					</Flex>
					<Flex
						onClick={() => setMoodBoardGridType("structure")}
						justify="center"
						align="center"
						className={cn("moodboard-actions__board-view__button", {
							"moodboard-actions__board-view__active": moodBoardGridType === "structure"
						})}>
						<StructureGrid />
					</Flex>
				</Flex>

				<Button
					onClick={() => toggleAddWarningModal()}
					className="moodboard-actions__button"
					icon={<img src={magicStick} alt="magic stick" />}
				>
					<Typography.Text className={cn("moodboard-actions__text", TEXT_CLASSNAMES.XsRegular)}>
						Generate
					</Typography.Text>
				</Button>
				<Button
					onClick={() => toggleAddBriefModal()}
					className="moodboard-actions__button"
					icon={<img src={editStick} alt="edit stick" />}
				>
					<Typography.Text className={cn("moodboard-actions__text", TEXT_CLASSNAMES.XsRegular)}>
						Change Brief
					</Typography.Text>
				</Button>

				<Flex className="moodboard-actions__button-group">
					<Flex
						onClick={() => toggleAddDeleteModal()}
						align="center"
						justify="center"
						className="moodboard-actions__button-group__radio pointer"
						style={{ borderRadius: "6px" }}
					>
						<img className="moodboard-actions__img" src={clear} alt="clear" />
					</Flex>
					<div className="moodboard-actions__button-group__divider" />
					<Flex
						onClick={() => toggleAddImage()}
						align="center"
						justify="center"
						className="moodboard-actions__button-group__radio pointer"
						style={{ borderRadius: "6px" }}
					>
						<img className="moodboard-actions__img" src={exportIcon} alt="import data" />
					</Flex>
				</Flex>
			</Flex>
			<Button type="primary" onClick={redirectToTheCharacter}>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, "moodboard-actions__next-button")}>Next</Typography.Text>
			</Button>

			<MoodboardDeleteModal
				isOpen={isOpenDeleteModal}
				toggleModal={toggleAddDeleteModal}
				deleteMoodboard={deleteMoodBoard}
			/>
			<MoodboardGenerateWarningModal
				isOpen={isOpenWarningModal}
				toggleModal={toggleAddWarningModal}
				generateMoodBoard={generateMoodBoard}
			/>
			<AddBriefMoodboard
				isOpen={isOpenAddBriefnModal}
				toggleModal={toggleAddBriefModal}
			/>
			<AddImageMoodboard
				isOpen={isOpenAddImage}
				toggleModal={toggleAddImage}
			/>
		</Flex>
	);
};
