import { Flex } from "antd";
import cn from "classnames";
import { FC } from "react";

import { DropdownContent } from "@/widgets/header/dropdown-content";
import { USE_CASES_LIST } from "@/shared/const/links";

import "./HeaderDropdown.scss";

interface IHeaderDropdown {
	isActive: boolean;
	onClose: () => void;
}

export const HeaderDropdown: FC<IHeaderDropdown> = ({ isActive, onClose }) => {
	return (
		<Flex justify="center" className={cn("header-dropdown cubic-animation gap-s", {
			"header-dropdown__active": isActive
		})}>
			{
				USE_CASES_LIST.map((useCase, index) => (
					<DropdownContent {...useCase} onClose={onClose} key={index.toString(36)} />
				))
			}
		</Flex>
	);
};
