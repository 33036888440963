import { Flex, Typography } from "antd";
import cn from "classnames";
import { PropsWithChildren } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./RightSidebar.scss";

interface IRightSidebar<T extends string> {
	changeView: (view: T) => void;
	sidebarViewList: { value: T, title: string }[];
	activeView: T;
}

export const RightSidebar = <T extends string, >({ children, sidebarViewList, changeView, activeView }: PropsWithChildren<IRightSidebar<T>>) => (
	<Flex vertical className="right-sidebar">
		<Flex className="right-sidebar__header gap-s">
			{sidebarViewList.map((sidebarView) => (
				<Typography.Text
					key={sidebarView.value}
					onClick={() => changeView(sidebarView.value)}
					className={cn(TEXT_CLASSNAMES.HeadlineH4,
						"pointer right-sidebar__header__nav", {
							"right-sidebar__header__nav-active": activeView === sidebarView.value
						})}>
					{sidebarView.title}
				</Typography.Text>
			))}
		</Flex>
		<Flex className="right-sidebar__content">
			{children}
		</Flex>
	</Flex>
);
