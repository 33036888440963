import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthWrapper, BackgroundPreLogin, sessionLoginAction } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { REG_EXP } from "@/shared/const/reg-exp";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { DisableSubmitButton } from "@/shared/ui";
import { invitationAction, invitationCodeAtom, invitationErrorAtom } from "../model";

import "./Invitation.scss";

type FieldType = {
	email: string;
	password: string;
	repeatPassword: string;
};

export const Invitation: FC = () => {
	const navigate = useNavigate();
	const { search } = useLocation();
	const { isMobileL } = useMediaQueries();
	const [form] = Form.useForm();
	const [error] = useAtom(invitationErrorAtom);
	const [key] = useAtom(invitationCodeAtom);
	const [currentEmail, setCurrentEmail] = useState("");
	const inviteUser = useAction(invitationAction);
	const login = useAction(sessionLoginAction);

	const handleSubmit = (data: FieldType) => {
		const params = new URLSearchParams(search);
		const verificationCode = params.get("verification_code");
		const teamId = params.get("team_id");
		const position = params.get("position");
		const email = params.get("email");

		inviteUser({
			verification_code: verificationCode ?? "",
			team_id: teamId ?? "",
			role: "manager" ?? "",
			position: position?.split(".")[1] ?? "",
			email: email ?? "",
			password: data.password,
			username: email ?? ""
		});
	};

	useEffect(() => {
		const params = new URLSearchParams(search);
		const email = params.get("email");

		if (email) {
			setCurrentEmail(email);
		}

		if (key) {
			login({ key });
			navigate("/main/projects");
		}
	}, [key]);

	// @ts-ignore
	return (
		<BackgroundPreLogin>
			<AuthWrapper
				action={() => {}}
				title="Hello!"
				questionText={["You were invited to Blooper. ", <br />, "Please create a password to join."]}
				actionText=""
			>
				<Form
					className="invitation-form"
					form={form}
					name="basic"
					initialValues={{ remember: true }}
					onFinish={handleSubmit}
				>
					<Flex vertical className="gap-xs">
						<Input className="invitation-form__email-input" disabled value={currentEmail} />
						<Form.Item<FieldType>
							name="password"
							style={{
								maxWidth: 380
							}}
							rules={[
								{ required: true, message: "Please input your new password" },
								{ pattern: REG_EXP.password, message: "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" }
							]}
						>
							<Input.Password placeholder="Password" />
						</Form.Item>
						<Form.Item<FieldType>
							style={{
								maxWidth: 380
							}}
							dependencies={["password"]}
							name="repeatPassword"
							rules={[
								{ required: true, message: "Please input your password" },
								{ pattern: REG_EXP.password, message: "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" },
								({ getFieldValue }) => ({
									validator (_, value) {
										if (!value || getFieldValue("password") === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("The new password that you entered do not match"));
									}
								})
							]}
						>
							<Input.Password placeholder="Repeat Password" />
						</Form.Item>
					</Flex>
					<Flex style={{ margin: "32px 0" }} className="full-width">
						<DisableSubmitButton isFullWidth errorMessage={error} type="primary" form={form}>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>
								Sign up
							</Typography.Text>
						</DisableSubmitButton>
					</Flex>
					<Typography.Text className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, {
						[TEXT_CLASSNAMES.XsRegular]: isMobileL
					})}>
					By signing up I agree with
						<Typography.Text
							className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "invitation-form__underline", {
								[TEXT_CLASSNAMES.XsRegular]: isMobileL
							})}>
						Terms of Service
						</Typography.Text>
						{!isMobileL && <br/>}
					and
						<Typography.Text
							className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "invitation-form__underline", {
								[TEXT_CLASSNAMES.XsRegular]: isMobileL
							})}>
						Privacy Policy
						</Typography.Text>
					</Typography.Text>
				</Form>
			</AuthWrapper>
		</BackgroundPreLogin>
	);
};
