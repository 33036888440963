import { useState } from "react";

export function useLocalStorage<T = never> (key: string, initialValue: T): [T, (value: T) => void] {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error("use local storage", error);
			return initialValue;
		}
	});
	const setValue = (value: T) => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			console.error("use local storage set value", error);
		}
	};

	return [storedValue, setValue];
}
