import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";

interface IDeleteProjectModal extends IOpenModal{
	onDelete: () => void;
}

export const DeleteProjectModal: FC<IDeleteProjectModal> = ({ isOpen, onDelete, toggleModal }) => {
	const handleCancel = () => {
		toggleModal(false);
	};

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			footer={[
				<Flex style={{
					marginTop: 24
				}} className="full-width gap-xs">
					<Button className="flex-1" key="back" onClick={handleCancel}>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							No, cancel
						</Typography.Text>
					</Button>
					<Button type="primary" className="flex-1" key="back" onClick={onDelete}>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							Yes, delete it
						</Typography.Text>
					</Button>
				</Flex>
			]}
		>
			<Flex vertical className="gap-s text-center">
				<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
					Deleting the project
				</Typography.Text>
				<Flex vertical className="gap-xxs">
					<Typography.Text className={TEXT_CLASSNAMES.MdRegular}>
						Are you sure you want to delete the project Movie “Lost”?
					</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
						The project will not be restored
					</Typography.Text>
				</Flex>
			</Flex>
		</Modal>
	);
};
