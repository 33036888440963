import { useAtom } from "@reatom/npm-react";
import { Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";
import { ButtonBack } from "@/shared/ui";
import { stepAtom } from "../model";
import { FirstStepForm } from "./FirstStepForm";
import { SecondStepForm } from "./SecondStepForm";

export const AddNewProject: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
	const [step, setStep] = useAtom(stepAtom);
	const handleBack = () => {
		setStep("1");
	};

	const onCloseModal = () => {
		toggleModal(false);
	};

	return (
		<Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
			<Flex vertical className="gap-s">
				<Flex className="gap-xxs" align="center">
					{step === "2" && <ButtonBack onAction={handleBack} />}
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdBold, COLOR_CLASSNAMES.TextSecondary)}>
						{step}/2
					</Typography.Text>
				</Flex>
				{step === "1" && <FirstStepForm />}
				{step === "2" && <SecondStepForm closeModal={onCloseModal} isBriefOpen />}
			</Flex>
		</Modal>
	);
};
