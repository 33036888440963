import { Flex } from "antd";
import { FC } from "react";

import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { BillingHistory } from "./billing-history";
import { CurrentPlan } from "./CurrentPlan";
import { PaymentMethod } from "./PaymentMethod";

export const SettingsBilling: FC = () => {
	const { isLaptopS } = useMediaQueries();

	return (
		<Flex vertical className="gap-xs">
			<Flex vertical={isLaptopS} className="gap-xs">
				<CurrentPlan />
				<PaymentMethod />
			</Flex>
			<BillingHistory />
		</Flex>
	);
};
