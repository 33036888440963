import { CharacterFilterList, CharacterTab, TCharacter } from "@/entities/character";
import { Character, CHARACTERS } from "@/shared/const/characters";

export const getNextCharacterId = (list: TCharacter[], currentId?: string) => {
	const characterIndex = list.findIndex((character) => character.id === currentId);
	const lastIndex = list.length - 1;
	const isLast = characterIndex === lastIndex;

	if (isLast) {
		return list[0].id;
	}

	if (characterIndex > -1) {
		return list[characterIndex + 1].id;
	}

	return currentId;
};

export const getPrevCharacterId = (list: TCharacter[], currentId?: string) => {
	const characterIndex = list.findIndex((character) => character.id === currentId);
	const isFirst = characterIndex === 0;

	if (isFirst) {
		return list[list.length - 1].id;
	}

	if (characterIndex > -1) {
		return list[characterIndex - 1].id;
	}

	return currentId;
};

export const toCharacterList = (tab: CharacterTab): CharacterFilterList[] => {
	const result: CharacterFilterList[] = [];
	if (tab === "all") {
		const keys = Object.keys(CHARACTERS);

		keys.forEach((key) => {
			const character = CHARACTERS[key as Character];

			Object.entries(character).forEach(([id, value]) => {
				result.push({
					id: `${key}_${id}`,
					img: value["4"]
				});
			});
		});
	} else {
		const character = CHARACTERS[tab as Character];

		Object.entries(character).forEach(([id, value]) => {
			result.push({
				id: `${tab}_${id}`,
				img: value["4"]
			});
		});
	}

	return result;
};
