import { useGoogleLogin } from "@react-oauth/google";
import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Checkbox, Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { errorAtom, loginAction, loginGoogleAction } from "@/pages/login";
import { AuthWrapper, BackgroundPreLogin, viewerAtom } from "@/entities/viewer";
import googleIcon from "@/assets/shared/google_icon.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
// import { REG_EXP } from "@/shared/const/reg-exp";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { DisableSubmitButton } from "@/shared/ui";
import { validateEmail } from "@/shared/validation";

import "./Login.scss";

type FieldType = {
	email: string;
	password: string;
	remember: boolean;
};

export const Login: FC = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const loginDefault = useAction(loginAction);
	const googleLogin = useAction(loginGoogleAction);

	const [error] = useAtom(errorAtom);
	const [viewer] = useAtom(viewerAtom);

	const email = Form.useWatch("email", { form, preserve: true });

	const handleRedirectToRegister = () => {
		navigate("/register");
	};

	const handleRedirectToRecover = () => {
		navigate("/recover-password");
	};

	const onFinish = (values: FieldType) => {
		loginDefault({
			email: values.email,
			password: values.password
		});
	};

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			googleLogin({ access_token: tokenResponse.access_token });
		},
		onError: (data) => console.log("==========>data", data)
	});

	useEffect(() => {
		if (viewer?.key) {
			navigate("/main/projects");
		}
	}, [viewer]);

	return (
		<BackgroundPreLogin>
			<AuthWrapper
				action={handleRedirectToRegister}
				title="Sign in"
				questionText={NOT_RELEASE ? "Don’t have an account?" : "Enter your credentials below to sign in"}
				actionText={NOT_RELEASE ? "Sign up" : ""}
			>
				<Form
					name="basic"
					initialValues={{ remember: true }}
					onFinish={onFinish}
					autoComplete="off"
					className="login-form"
					form={form}
				>
					<Flex vertical className="gap-xs">
						<Form.Item<FieldType>
							name="email"
							rules={[
								{ required: true, message: "Please input your email" },
								{ type: "email", message: "Please input your valid email" }
							]}
							hasFeedback
							validateStatus={validateEmail(email)}
						>
							<Input
								onChange={(event) => form.setFieldValue("email", event.target.value)}
								className="login-form__input"
								placeholder="Email"
							/>
						</Form.Item>
						<Form.Item<FieldType>
							name="password"
							style={{
								maxWidth: 380
							}}
							rules={[
								{ required: true, message: "Please input your password" }
								// { pattern: REG_EXP.password, message: "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" }
							]}
						>
							<Input.Password className="login-form__input" placeholder="Password" />
						</Form.Item>
					</Flex>
					<Flex justify="space-between" align="center" className="full-width login-form__actions">
						<Form.Item<FieldType>
							className="login-form__checkbox"
							name="remember"
							valuePropName="checked"
						>
							<Checkbox>
								<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
									Remember me
								</Typography.Text>
							</Checkbox>
						</Form.Item>
						<Typography.Text
							onClick={handleRedirectToRecover}
							className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, "pointer")}
						>
							Forgot password?
						</Typography.Text>
					</Flex>
					<Flex vertical className="login-form__buttons gap-xs">
						<DisableSubmitButton errorMessage={error} type="primary" form={form}>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>
								Sign in
							</Typography.Text>
						</DisableSubmitButton>
						{NOT_RELEASE && (
							<Button
								htmlType="button"
								className="login-form__buttons__google"
								icon={<img src={googleIcon} alt="google icon" />}
								onClick={() => login()}
							>
								<Typography.Text className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.XsRegular)}>
									Login with Google
								</Typography.Text>
							</Button>
						)}
					</Flex>
				</Form>
			</AuthWrapper>
		</BackgroundPreLogin>
	);
};
