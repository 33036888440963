import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { DRAGGABLE_IMAGE_ID } from "@/entities/inspiration-board";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { DraggableBoard, DraggableImage, Spinner } from "@/shared/ui";
import {
	searchBoardListAtom,
	searchBoardLoadingAtom,
	searchBoardStatusAtom,
	searchBoardViewAtom
} from "../model";
import { SearchBoardAutocomplete } from "./SearchBoardAutocomplete";
import { SearchBoardImageDownload } from "./SearchBoardImageDownload";

import searchBoardEmptyImage from "@/assets/moodboard/empty-search-moodboard.svg";

import "./SearchBoard.scss";

export const SearchBoard: FC = () => {
	const [searchBoardView] = useAtom(searchBoardViewAtom);
	const [searchBoardList] = useAtom(searchBoardListAtom);
	const [searchBoardLoading] = useAtom(searchBoardLoadingAtom);
	const [searchBoardStatus] = useAtom(searchBoardStatusAtom);

	return (
		<Flex vertical className="search-board full-width gap-s">
			{searchBoardView === "autocomplete" && <SearchBoardAutocomplete />}
			{searchBoardView === "image" && <SearchBoardImageDownload />}
			{searchBoardLoading
				? (
					<Flex className="full-width full-height" align="center" justify="center">
						<Spinner />
					</Flex>
				)
				: (
					<>
						{searchBoardStatus === "empty" && (
							<Flex vertical align="center" className="gap-xs">
								<img src={searchBoardEmptyImage} alt="search board empty" />
								<Flex vertical align="center">
									<Typography.Text className={cn(TEXT_CLASSNAMES.SmSemibold, "archive-board__text")}>
										Looking for something?
									</Typography.Text>
									<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, "archive-board__text")}>
										Type in and we’ll help you find it
									</Typography.Text>
								</Flex>
							</Flex>
						)}
						{searchBoardStatus === "full" && (
							<Flex vertical className="search-board__container gap-s">
								<DraggableBoard isLoading={false}>
									{searchBoardList.map(({ id, data }) => (
										<DraggableImage
											isFullWidth={true}
											key={id}
											id={id}
											img={data}
											draggableImageId={DRAGGABLE_IMAGE_ID}
										/>
									))}
								</DraggableBoard>
							</Flex>
						)}
					</>
				)}
		</Flex>
	);
};
