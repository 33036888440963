import { useAtom } from "@reatom/npm-react";
import { Button, Flex } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmptyMoodboard } from "@/features/empty-moodboard";
import { FullMoodboard } from "@/features/full-moodboard";
// import { KonvaMoodboad } from "@/features/full-moodboard/ui/KonvaMoodboad";
import { MoodboardActions, moodboardLoadingAtom, moodBoardStatusAtom } from "@/entities/moodboard";
import { Spinner } from "@/shared/ui";

export const MoodboardContent: FC = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [isLoading] = useAtom(moodboardLoadingAtom);
	const [moodBoardStatus] = useAtom(moodBoardStatusAtom);

	const isMoodBoardEmpty = moodBoardStatus === "empty";

	const redirectToCharacter = () => {
		navigate(`/storyboard/${id}/character`);
	};

	if (isLoading) {
		return (
			<Flex className="full-height full-height" align="center" justify="center">
				<Spinner />
			</Flex>
		);
	}

	return (
		<>
			{isMoodBoardEmpty && <EmptyMoodboard />}
			{!isMoodBoardEmpty && <FullMoodboard />}
			<Flex className="full-width" justify="end">
				{isMoodBoardEmpty
					? (
						<Button type="primary" onClick={redirectToCharacter} className="moodboard__next-button">
							Next
						</Button>
					)
					: <MoodboardActions />}
			</Flex>
		</>
	);
};
