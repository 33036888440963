import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { SettingsWrapper } from "@/entities/settings";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import email from "@/assets/settings/billing/email.svg";
import visa from "@/assets/settings/billing/visa.svg";

export const PaymentMethod: FC = () => {
	return (
		<SettingsWrapper
			title="Payment Method"
			description="Update your billing information"
			className="gap-s full-width"
			style={{
				height: "244px",
				justifyContent: "space-between"
			}}
		>
			<Flex vertical className="gap-xs">
				<Flex className="gap-xs">
					<img src={visa} alt="visa" />
					<Flex vertical className="gap-2">
						<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>1234 •••• •••• ••89 </Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Exp Date: 06/2028</Typography.Text>
					</Flex>
				</Flex>
				<Flex className="gap-xxs" align="center">
					<img src={email} alt="email" />
					<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>alice.johnson@gmail.com</Typography.Text>
				</Flex>
			</Flex>
			<Button className="full-width">
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Update Payment Info
				</Typography.Text>
			</Button>
		</SettingsWrapper>
	);
};
