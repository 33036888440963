import { useAction } from "@reatom/npm-react";
import cn from "classnames";
import {
	Children,
	cloneElement,
	forwardRef,
	HTMLProps,
	MouseEvent,
	ReactElement,
	useState
} from "react";

import { useParams } from "react-router-dom";
import { addToArchiveBoardAction } from "@/entities/archive-board";
import {
	removeFromMoodBoardAction,
	toggleStaticImageOnMoodBoardAction
} from "@/entities/moodboard";

import { TMoodboardImage } from "@/shared/api/moodboard";
import lock from "@/assets/moodboard/lock.svg";
import trash from "@/assets/moodboard/trash.svg";
import unlock from "@/assets/moodboard/unlock.svg";

interface ICustomMoodBoardItem extends HTMLProps<HTMLDivElement> {
	moodboard: TMoodboardImage;
}

interface ICardActions {
	isHover: boolean;
	isResize: boolean;
	isDrag: boolean;
}

export const CustomMoodBoardItem = forwardRef<HTMLDivElement, ICustomMoodBoardItem>(({
	style,
	moodboard,
	className,
	children,
	onMouseUp,
	onMouseDown,
	onTouchEnd,
	...props
}, ref) => {
	const { id } = useParams();
	const [cardActions, setCardActions] = useState<ICardActions>({
		isDrag: false,
		isHover: false,
		isResize: false
	});
	const removeFromMoodboard = useAction(removeFromMoodBoardAction);
	const toggleStaticImageOnMoodBoard = useAction(toggleStaticImageOnMoodBoardAction);
	const addToArchiveBoard = useAction(addToArchiveBoardAction);

	const handleArchive = (moodboard: TMoodboardImage) => {
		addToArchiveBoard(moodboard);
		if (id) {
			removeFromMoodboard(id, moodboard.id);
		}
	};

	const toggleHover = () => {
		setCardActions((prevState) => ({
			...prevState,
			isHover: !prevState.isHover
		}));
	};

	const resizebleCornerRender = () => Children.map(children, (el) => {
		if (el) {
			const customOnMouseDown = () => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				el.props.onMouseDown();
			};
			const customOnDrag = (e: unknown, _ref2: unknown) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				el.props.onDrag(e, _ref2);
			};
			const customOnStart = (e: unknown, _ref2: unknown) => {
				setCardActions((prevState) => ({
					...prevState,
					isResize: true
				}));

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				el.props.onStart(e, _ref2);
			};

			const customOnSop = (e: unknown, _ref2: unknown) => {
				setCardActions((prevState) => ({
					...prevState,
					isResize: false,
					isDrag: false
				}));

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				el.props.onStop(e, _ref2);
			};

			return cloneElement(el as ReactElement, {
				onDrag: customOnDrag,
				onMouseDown: customOnMouseDown,
				onStart: customOnStart,
				onStop: customOnSop
			});
		}

		return null;
	});

	const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
		setCardActions((prevState) => ({
			...prevState,
			isDrag: true
		}));

		if (onMouseDown) {
			onMouseDown(event);
		}
	};

	const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
		setCardActions((prevState) => ({
			...prevState,
			isDrag: false
		}));

		if (onMouseUp) {
			onMouseUp(event);
		}
	};

	return (
		<div
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onTouchEnd={onTouchEnd}
			onMouseEnter={toggleHover}
			onMouseLeave={toggleHover}
			style={{ ...style }}
			ref={ref}
			className={cn(className, "full-moodboard__card pointer", {
				"full-moodboard__card-active": cardActions.isHover,
				"full-moodboard__card-resize": cardActions.isResize
			})}
			{...props}
		>
			<img className="full-width full-height" src={moodboard.data} alt="image" />
			<div className="full-moodboard__card__actions">
				{(cardActions.isHover && !cardActions.isResize) && (
					<div onClick={() => handleArchive(moodboard)} className="full-moodboard__card__delete">
						<img src={trash} alt="trash" />
					</div>
				)}
				{((cardActions.isHover && !cardActions.isResize) || moodboard.isStatic) && (
					<div onClick={() => toggleStaticImageOnMoodBoard(moodboard.id)} className="full-moodboard__card__lock">
						{moodboard.isStatic ? <img src={lock} alt="lock" /> : <img src={unlock} alt="unlock" />}
					</div>
				)}
			</div>
			{resizebleCornerRender()}
		</div>
	);
});
