import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./CookieBanner.scss";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

export const CookieBanner: FC = () => {
	const { isMobileL } = useMediaQueries();
	const [cookieBanner, setCookieBanner] = useState(localStorage.getItem("cookie-banner"));

	const removeCookieBanner = () => {
		localStorage.setItem("cookie-banner", "false");
		setCookieBanner("false");
	};

	const isActive = cookieBanner !== "false";

	return (
		<Flex align="center" justify="center" className={cn("cookie-banner", {
			"cookie-banner--active": isActive
		})}>
			<Flex align="center" justify="center" className={cn("cookie-banner__content gap-m", {
				"gap-xs": isMobileL
			})}>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, {
					[TEXT_CLASSNAMES.XxsRegular]: isMobileL
				})}>
					We use cookies to improve your browsing experience. By clicking “Accept all”, you agree to use of cookies.
				</Typography.Text>
				<Flex vertical={isMobileL} align="center" justify="center" className={cn("gap-m", {
					"gap-xs": isMobileL
				})}>
					<Button type="primary" onClick={removeCookieBanner}>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, {
							[TEXT_CLASSNAMES.XxsRegular]: isMobileL
						})}>Accept all</Typography.Text>
					</Button>
					<Button onClick={removeCookieBanner}>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, {
							[TEXT_CLASSNAMES.XxsRegular]: isMobileL
						})}>Decline</Typography.Text>
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
