import { useAtom } from "@reatom/npm-react";
import { FC, PropsWithChildren } from "react";

import { RightSidebar } from "@/shared/ui";

import { TShotSidebarView } from "../../lib";
import { shotSidebarViewAtom } from "../../model";

export const RightSidebarShot: FC<PropsWithChildren> = ({ children }) => {
	const [sidebarView, changeView] = useAtom(shotSidebarViewAtom);

	const sidebarViewList: { title: string, value: TShotSidebarView }[] = [
		{
			value: "details",
			title: "Details"
		},
		{
			value: "edit",
			title: "Edit"
		},
		{
			value: "versions",
			title: "Versions"
		}
	];

	return (
		<RightSidebar
			sidebarViewList={sidebarViewList}
			activeView={sidebarView}
			changeView={changeView}
		>
			{children}
		</RightSidebar>
	);
};
