import { useAction, useAtom } from "@reatom/npm-react";
import { Col, Flex, Row } from "antd";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
	CharacterActions,
	CharacterCard,
	CharacterHeader,
	getCharactersAction
} from "@/entities/character";
import { Spinner } from "@/shared/ui";

import "./Character.scss";

export const Character: FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const initCharacters = useAction(getCharactersAction);

	const [characterList] = useAtom(getCharactersAction.dataAtom);
	const [status] = useAtom(getCharactersAction.statusesAtom);
	const handleNext = () => {
		navigate(`/storyboard/${id}/storyboard`);
	};

	useEffect(() => {
		if (id) {
			initCharacters(id);
		}
	}, []);

	return (
		<Flex vertical className="character full-height pointer gap-l">
			<CharacterHeader />
			{status.isPending
				? (
					<Flex align="center" justify="center" className="full-width full-height">
						<Spinner />
					</Flex>
				)
				: (
					<>
						<Row gutter={[16, 16]} style={{ padding: "0 8px" }}>
							{characterList.map((card) => (
								<Col>
									<CharacterCard key={card.id} {...card} />
								</Col>
							))}
						</Row>
						<CharacterActions type="next" onNext={handleNext} />
					</>
				)}
		</Flex>
	);
};
