import {
	Button,
	Checkbox,
	Flex,
	Form,
	Input,
	message,
	Select,
	Typography
} from "antd";
import axios from "axios";
import cn from "classnames";
import { FC, HTMLAttributes, useState } from "react";
import { useLocation } from "react-router-dom";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { ROLES } from "@/shared/const/roles";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { trackGoogleAnalyticsEvent } from "@/shared/methods";

import "./JoinWaitingList.scss";

interface IFeedbackForm {
	email: string;
	name: string;
	role: string;
}

export const JoinWaitingList: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
	const [messageApi, contextHolder] = message.useMessage();
	const [isEmailSendError, setIsEmailSendError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { isLaptopS, isTablet, isMobileXL } = useMediaQueries();
	const [form] = Form.useForm();
	const role = Form.useWatch("role", { form, preserve: true });
	const location = useLocation();

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid!"
		}
	};
	/* eslint-enable no-template-curly-in-string */

	const onFinish = async (values: IFeedbackForm) => {
		try {
			setIsLoading(true);

			trackGoogleAnalyticsEvent("white_list", "contacts", location.pathname, {
				...values,
				email: values.email.replace("@", "_")
			});

			const data = {
				service_id: "service_fftdtow",
				template_id: "template_lae8dge",
				user_id: "AQK3iUSsogwlK2dMZ",
				template_params: {
					email: values.email,
					message: `My name is ${values.name}. I want to join blooper`
				}
			};

			if (values.email !== "grosh@blooper.ai") {
				await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
					...data
				}, {
					headers: {
						"Content-Type": "application/json"
					}
				});
			}

			messageApi.open({
				type: "success",
				content: "Your application has been successfully accepted"
			});
			setIsEmailSendError(false);
		} catch (e) {
			setIsEmailSendError(true);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Flex {...props} vertical={isMobileXL} className={cn("join-waiting-list gap-s", props.className)}>
			{contextHolder}
			<Flex vertical justify="center" className="gap-xxs text-left flex-1 join-waiting-list__info">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH3, {
					[TEXT_CLASSNAMES.LPHeadlineH4]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH5]: isTablet,
					[TEXT_CLASSNAMES.HeadlineH1]: isMobileXL
				})}>Join waiting list</Typography.Text>
				<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
					[TEXT_CLASSNAMES.SmRegular]: isLaptopS,
					[TEXT_CLASSNAMES.XsRegular]: isTablet
				})}>
					Blooper is still in the works. Sign up now to be invited to our early access program and be first in line to experience the future of cinematic creativity.
				</Typography.Text>
			</Flex>
			<Flex className="flex-1" justify="flex-end">
				<Form
					onFinish={onFinish}
					validateMessages={validateMessages}
					layout="vertical"
					className="full-width join-waiting-list__form"
				>
					<Flex vertical className="gap-s text-center">
						<Flex vertical gap="middle">
							<Form.Item id="name" name="name" noStyle rules={[{ required: true }]}>
								<Input placeholder="Name" />
							</Form.Item>
							<Form.Item id="email" name="email" noStyle rules={[{ type: "email", required: true }]}>
								<Input placeholder="Email" />
							</Form.Item>
							<Form.Item
								name="role"
								rules={[
									{ required: true, message: "Please choose your role!" }
								]}
								hasFeedback
								validateStatus={role ? "success" : ""}
							>
								<Select
									className="text-left"
									placeholder="Choose the role"
									style={{ height: 44 }}
									onChange={(value) => form.setFieldValue("role", value)}
									options={ROLES}
								/>
							</Form.Item>
							<Form.Item
								name="remember"
								valuePropName="checked"
							>
								<Flex className="text-left">
									<Checkbox>
										<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
											I want to get a news about Blooper by email
										</Typography.Text>
									</Checkbox>
								</Flex>
							</Form.Item>
						</Flex>
						{isEmailSendError && <Typography.Text className="error">Oops something went wrong</Typography.Text>}
						<Form.Item>
							<Button loading={isLoading} className="full-width" htmlType="submit" type="primary">
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
									Send
								</Typography.Text>
							</Button>
						</Form.Item>
					</Flex>
				</Form>
			</Flex>
		</Flex>
	);
};
