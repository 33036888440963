import { Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import arrow from "@/assets/shared/arrow_left_24.svg";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";
import { MoodboardStep, StoryboardStep } from "@/assets/components";

import "./NextChoiceModal.scss";

interface INextChoiceModal extends IOpenModal {
	onMoodBoard: () => void;
	onStoryBoard: () => void;
}

export const NextChoiceModal: FC<INextChoiceModal> = ({ onStoryBoard, onMoodBoard, toggleModal, isOpen }) => {
	return (
		<Modal
			open={isOpen}
			onCancel={() => toggleModal(false)}
			className="next-choice-modal"
			footer={[]}
		>
			<Flex vertical className="gap-xs">
				<Flex onClick={onMoodBoard} vertical className="next-choice-modal__block gap-xs pointer cubic-animation">
					<Flex align="center" justify="space-between">
						<Flex align="center" justify="center" className="next-choice-modal__block__bg">
							<MoodboardStep fill={COLOR_NAMES.MetalGray800} />
						</Flex>
						<img src={arrow} alt="arrow" className="next-choice-modal__block__img" />
					</Flex>
					<Flex vertical className="gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
							Create Moodboard
						</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
							Define your style before your start
						</Typography.Text>
					</Flex>
				</Flex>
				<Flex vertical className="next-choice-modal__block gap-xs pointer cubic-animation" onClick={onStoryBoard}>
					<Flex align="center" justify="space-between">
						<Flex align="center" justify="center" className="next-choice-modal__block__bg">
							<StoryboardStep fill={COLOR_NAMES.MetalGray800} />
						</Flex>
						<img src={arrow} alt="arrow" className="next-choice-modal__block__img" />
					</Flex>
					<Flex vertical className="gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
							Create Storyboard
						</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
							Find your style on the go. <br/>
							AI will help you to generate the images
						</Typography.Text>
					</Flex>
				</Flex>
			</Flex>
		</Modal>
	);
};
