import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./CharacterActions.scss";

interface ISelect {
	type: "select";
	onSelect: () => void;
}

interface INext {
	type: "next";
	onNext: () => void;
}

type TCharacterActions = INext | ISelect;

export const CharacterActions: FC<TCharacterActions> = (props) => {
	return (
		<Flex className="character-actions full-width" justify="flex-end" align="center">
			<Button onClick={props.type === "next" ? props.onNext : props.onSelect} type="primary" className="character-actions__button">
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					{props.type === "next" ? "Next" : "Select"}
				</Typography.Text>
			</Button>
		</Flex>
	);
};
