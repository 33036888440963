import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";
import { TMoodBoardStatus } from "@/entities/moodboard";
import { callErrorAction } from "@/entities/notification";
import { getAllMoodboardImages, getMoodboardsResource, TMoodboardImage } from "@/shared/api/moodboard";
import { base64toBlob } from "@/shared/methods";

export const archiveBoardListAtom = atom<TMoodboardImage[]>([], "archiveBoardListAtom");

export const archiveBoardLoadingAtom = atom<boolean>(false, "archiveBoardLoadingAtom");

export const archiveBoardStatusAtom = atom<TMoodBoardStatus>((ctx) => {
	const list = ctx.spy(archiveBoardListAtom);

	return list.length > 0 ? "full" : "empty";
}, "archiveBoardStatusAtom");

export const getArchiveBoardAction = action(async (ctx, id) => {
	try {
		archiveBoardLoadingAtom(ctx, true);
		const imagesIds = await getMoodboardsResource(id);
		const moodboardImages = await getAllMoodboardImages(id, imagesIds.data.images.archived.map((image) => image.id));

		const images = moodboardImages.data.images.map((image) => ({
			...image,
			data: URL.createObjectURL(base64toBlob(image.data))
		}));

		archiveBoardListAtom(ctx, images);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	} finally {
		archiveBoardLoadingAtom(ctx, false);
	}
});

export const removeFromArchiveBoardAction = action((ctx, id: string) => {
	archiveBoardListAtom(ctx, ctx.get(archiveBoardListAtom).filter((item) => item.id !== id));
});

export const addToArchiveBoardAction = action((ctx, image: TMoodboardImage) => {
	archiveBoardListAtom(ctx, [...ctx.get(archiveBoardListAtom), image]);
});
