import { useAction, useAtom } from "@reatom/npm-react";
import { notification } from "antd";
import { FC, PropsWithChildren, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { clearErrorAction, errorAtom } from "@/entities/notification";
import { CREDENTIAL_KEY } from "@/entities/viewer";
import { TRegisterKey } from "@/shared/api/auth";
import { useLocalStorage } from "@/shared/hooks";

export const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => {
	const [api, contextHolder] = notification.useNotification();
	const [viewer] = useLocalStorage<TRegisterKey | null>(CREDENTIAL_KEY, null);
	const isAuth = !!viewer;
	const navigate = useNavigate();
	const clearError = useAction(clearErrorAction);
	const [error] = useAtom(errorAtom);

	useEffect(() => {
		if (error?.type === "axios-error") {
			if (error.error.response?.status === 502 || error.error.response?.status === 500) {
				if (isAuth) {
					navigate("/main/500");
				} else {
					navigate("/500");
				}
			} else {
				api.error({
					message: `Error status ${error.error.response?.status}`,
					description: error.error.response?.data?.message
				});
			}
		}

		if (error?.type === "stock-error") {
			api.error({
				message: "Error status 500",
				description: "Oops, something went wrong. Please try again later"
			});
		}

		return () => {
			clearError();
		};
	}, [error]);

	return (
		<>
			{contextHolder}
			{children}
		</>
	);
};
