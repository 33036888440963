import { Flex, Typography } from "antd";
import { FC } from "react";

import arrow from "@/assets/shared/arrow_left_24.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./SidebarNavigation.scss";

interface ISidebarNavigation {
	title: string;
	onNext: () => void;
	onPrev: () => void;
}

export const SidebarNavigation: FC<ISidebarNavigation> = ({ title, onPrev, onNext }) => {
	return (
		<Flex align="center" justify="space-between">
			<Typography.Text className={TEXT_CLASSNAMES.HeadlineH3}>{title}</Typography.Text>
			<Flex align="center" className="gap-xxs">
				<div role="button" onClick={onPrev} className="sidebar-navigation-button pointer">
					<img src={arrow} alt="arrow left" />
				</div>
				<div role="button" onClick={onNext} className="sidebar-navigation-button pointer">
					<img className="sidebar-navigation-button__right" src={arrow} alt="arrow right" />
				</div>
			</Flex>
		</Flex>
	);
};
