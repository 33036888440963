import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Modal, Progress, Select, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { updateInspirationPromptAction } from "@/entities/inspiration-board";
import { EMBENDINGS } from "@/shared/const/moodboard";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";
import { createUrlParam } from "@/shared/methods";
import { briefAtom, briefOptionsAtom, briefProgressAtom, getTagsAction, updateTagsAction } from "../model";
import description from "@/assets/moodboard/description-modal.svg";

import "./AddBriefMoodboard.scss";

export const AddBriefMoodboard: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
	const { id } = useParams();
	const [brief, setBrief] = useAtom(briefAtom);
	const [briefOptions] = useAtom(briefOptionsAtom);
	const [progressPercent] = useAtom(briefProgressAtom);
	const updateTags = useAction(updateTagsAction);
	const updateInspirationPrompt = useAction(updateInspirationPromptAction);
	const getTags = useAction(getTagsAction);

	const onChange = (options: string[]) => {
		setBrief(options);
	};
	const handleOk = () => {
		if (id) {
			const inspirationPrompt = createUrlParam(brief, EMBENDINGS);

			updateTags(id, brief);
			updateInspirationPrompt(id, inspirationPrompt);
			toggleModal();
		}
	};

	const handleCancel = () => {
		toggleModal();
	};

	useEffect(() => {
		if (id) {
			getTags(id);
		}
	}, []);

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			footer={[
				<Flex className="full-width">
					<Button className="flex-1" onClick={handleCancel}>
						Cancel
					</Button>
					<Button
						// disabled={progressPercent < 100}
						type="primary"
						className="flex-1"
						onClick={handleOk}
					>
						Add
					</Button>
				</Flex>
			]}
		>
			<Flex className="add-brief-moodboard" vertical align="center">
				<img src={description} alt="description"/>
				<Typography.Text
					className={cn("add-brief-moodboard__title", TEXT_CLASSNAMES.HeadlineH3)}
				>
					Add the brief to clarify your moodboard
				</Typography.Text>
				<Typography.Text
					className={cn("add-brief-moodboard__description", TEXT_CLASSNAMES.SmRegular)}
				>
					Fill out the description and we will generate images for your moodboard
				</Typography.Text>
				<Select<string[]>
					size="large"
					mode="tags"
					placeholder="Example: Soft Pastel Hues, Ethereal Textures, Reflective Surfaces, Natural Elements"
					onChange={onChange}
					options={briefOptions}
					defaultValue={brief}
					className="add-brief-moodboard__select"
				/>
				<Progress className="add-brief-moodboard__progress" percent={progressPercent} showInfo={false} />
			</Flex>
		</Modal>
	);
};
