import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useWindowSize } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

interface IProductProcessBlock {
	buttons: string[];
	idx: number;
	title: string | JSX.Element;
	description1: string;
	description2: string;
	direct: "left" | "right";
	path: string;
	mobileSVGImg: string;
	alt: string;
}

export const ProductProcessBlock: FC<IProductProcessBlock> = ({
	buttons,
	title,
	idx,
	description2,
	description1,
	direct,
	path,
	mobileSVGImg,
	alt
}) => {
	const navigate = useNavigate();
	const { width } = useWindowSize();
	const { isTablet, isMobileL, isMobileS } = useMediaQueries();

	return (
		<Flex vertical className="product-process__block flex-1 gap-xs">
			<Flex vertical className="gap-xs">
				<Flex className="gap-xs">
					{buttons.map((button, index) => (
						<div className="product-process__block__button" key={index.toString(36)}>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.Green500)}>
								{button}
							</Typography.Text>
						</div>
					))}
				</Flex>
				<Flex className="gap-xs">
					<Typography.Text className="product-process__block__idx">{idx}</Typography.Text>
					<Typography.Text className={cn("product-process__block__title", TEXT_CLASSNAMES.LPHeadlineH4, {
						[TEXT_CLASSNAMES.LPHeadlineH5]: isTablet,
						[TEXT_CLASSNAMES.HeadlineH2]: isMobileL
					})}>{title}</Typography.Text>
				</Flex>
				{width <= 670 && (
					<Flex className="full-width product-process__block__img" vertical justify="center" align="center">
						<img src={mobileSVGImg} alt={alt} />
					</Flex>
				)}
			</Flex>
			<div className="full-width product-process__line">
				<div className={cn({
					"full-width product-process__line__el": direct === "right",
					"full-width product-process__line__el-left": direct === "left"
				})} />
			</div>
			<Flex vertical className="gap-m">
				{description1 && (
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextSecondary, {
						[TEXT_CLASSNAMES.SmRegular]: isTablet,
						[TEXT_CLASSNAMES.XsRegular]: isMobileS
					})}>
						{description1}
					</Typography.Text>
				)}
				{description2 && (
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextSecondary, {
						[TEXT_CLASSNAMES.SmRegular]: isTablet,
						[TEXT_CLASSNAMES.XsRegular]: isMobileS
					})}>
						{description2}
					</Typography.Text>
				)}
			</Flex>
			<Flex style={{ marginTop: "16px" }} vertical={isMobileS} className="gap-xs">
				<Button onClick={() => navigate(path)}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Learn more</Typography.Text>
				</Button>
			</Flex>
		</Flex>
	);
};
