import { Descendant } from "slate";
import { HTShot, HTSScene, TShotColor } from "@/shared/api/highlight-the-shots";
import { insert } from "@/shared/methods";
import { SceneHistoryRequest, TScene, TShot } from "../lib";

export const sortShots = (shotsInfo: HTShot, shotsOrder: string[]): TShot[] => {
	const colors: TShotColor[] = ["violet", "green", "blue", "red", "orange"];
	const shots = Object.entries(shotsInfo).map(([shotId, shot]) => ({
		id: shotId,
		idx: shotsOrder.findIndex((order) => order === shotId),
		title: shot.title,
		prompt: typeof shot.prompt === "string" ? [{ type: "paragraph", children: [{ text: shot.prompt }] }] as Descendant[] : Array.isArray(shot.prompt) ? shot.prompt : [],
		color: colors.includes(shot.color) ? shot.color : randomColor(),
		description: shot.description
	}));

	return shots.sort((shotA, shotB) => shotA.idx - shotB.idx);
};

export const sortScenes = (scenes: HTSScene, orders: string[]): TScene[] => {
	const sceneList = Object.entries(scenes);

	const sceneListUpdated: TScene[] = sceneList.map(([id, scene]) => ({
		id,
		title: scene.title,
		idx: orders.findIndex((order) => order === id),
		shots: sortShots(scene.shots_info, scene.shots_order)
	}));

	return sceneListUpdated.sort((sceneA, sceneB) => sceneA.idx - sceneB.idx);
};

type TInsertShotsParams = {
	shot: TShot
	shots: TShot[]
	lastShotIndex: number
};

export const insertShots = (params: TInsertShotsParams, orders: string[]) => {
	const insertedShots = insert<TShot>(params.lastShotIndex + 1, [params.shot], params.shots);
	const updatedShots = insertedShots.map((shot) => ({
		...shot,
		idx: orders.findIndex((order) => order === shot.id)
	}));

	return updatedShots.sort((shotA, shotB) => shotA.idx - shotB.idx);
};

export const randomColor = (): TShotColor => {
	const colors: TShotColor[] = ["violet", "green", "blue", "red", "orange"];
	const randomColor = colors[Math.floor(Math.random() * colors.length)];

	return randomColor;
};

export const dumpIndex = (step: number, arr: Array<SceneHistoryRequest>) => {
	let index =
		step > 0
			? step - 1 // move forward
			: arr.length + step; // move backward
	if (index >= arr.length - 1) {
		index = arr.length - 1;
	}
	if (index < 0) {
		index = 0;
	}
	return index;
};

export const split = (step: number, targetArr: Array<SceneHistoryRequest>) => {
	const index = dumpIndex(step, targetArr);
	return {
		_current: targetArr[index],
		_before: targetArr.slice(0, index),
		_after: targetArr.slice(index + 1)
	};
};

type Children = {
	children: Array<{ text: string }>
}

export const getInlineTextFromDescendant = (descendant: Descendant[]) => {
	return descendant.reduce((acc, cur) => `${acc} ${(cur as unknown as Children).children.reduce((text, curText) => `${text} ${curText.text}`, "")}`, "");
};
