import { useAction, useAtom } from "@reatom/npm-react";
import { useDebounceFn } from "ahooks";
import { Flex, Input } from "antd";
import { ChangeEvent, FC } from "react";

import {
	getEmbeddingsAction,
	searchBoardViewAtom
} from "@/entities/search-board";
import search from "@/assets/shared/search.svg";
import searchPreview from "@/assets/shared/search_preview.svg";

export const SearchBoardAutocomplete: FC = () => {
	const getEmbeddings = useAction(getEmbeddingsAction);
	const [_searchBoardView, setSearchBoardView] = useAtom(searchBoardViewAtom);
	const { run } = useDebounceFn((event: ChangeEvent<HTMLInputElement>) => {
		getEmbeddings(event.target.value);
	}, { wait: 300 });

	return (
		<Input
			placeholder="Search"
			prefix={<img src={search} alt="search" />}
			allowClear
			addonAfter={
				<Flex onClick={() => setSearchBoardView("image")} className="search-board__addon-after">
					<img src={searchPreview} alt="search preview" />
				</Flex>
			}
			onChange={run}
		/>
	);
};
