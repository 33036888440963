import { useEffect } from "react";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

export function useParallax (speed: number, selector: string) {
	const { isTablet } = useMediaQueries();

	useEffect(() => {
		const layout = document.querySelector("#layout");

		function getSpeed () {
			return window.pageYOffset * speed;
		}

		if (layout && !isTablet) {
			// @ts-ignore
			layout.style.height = "auto";

			const header = layout.querySelector(selector);

			const parallax = () => {
				const speed = getSpeed();
				if (header && speed <= 1200) {
					// @ts-ignore
					header.style.transform = `translateY(${speed}px)`;
					// @ts-ignore
					header.style.webkitTransform = `translateY(${getSpeed()}px)`;
					// @ts-ignore
					header.style.mozTransform = `translateY(${getSpeed()}px)`;
					// @ts-ignore
					header.style.oTransform = `translateY(${getSpeed()}px)`;
					// @ts-ignore
					header.style.msTransform = `translateY(${getSpeed()}px)`;
				}
			};

			window.addEventListener("scroll", parallax);

			return () => {
				window.removeEventListener("scroll", parallax);
			};
		}
	}, [isTablet]);
}
