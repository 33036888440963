import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import descriptionPlaceholder from "@/assets/product/description-placeholder.png";

import "./ProductDescription.scss";

export const ProductDescription: FC = () => {
	const { isLaptopS, isTablet, isMobileL } = useMediaQueries();

	return (
		<Flex vertical className="gap-s text-center product-description">
			<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH2, {
				[TEXT_CLASSNAMES.LPHeadlineH3]: isLaptopS,
				[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet
			})}>Digitalizing the film industry</Typography.Text>
			<Flex className="product-description__text" vertical gap={40}>
				<Typography.Text className={cn(TEXT_CLASSNAMES.LgSemibold, {
					[TEXT_CLASSNAMES.MdSemibold]: isLaptopS,
					[TEXT_CLASSNAMES.SmSemibold]: isTablet
				})}>
					We're on a mission to democratize the art of filmmaking. {!isTablet && <br/>}
					With Blooper, creativity knows no bounds.
				</Typography.Text>
				<Typography.Text className={cn(TEXT_CLASSNAMES.LgRegular, {
					[TEXT_CLASSNAMES.MdRegular]: isLaptopS,
					[TEXT_CLASSNAMES.SmRegular]: isTablet
				})}>
					Collaborate with team members, accelerate your creative workflow with interactive AI assistants and manage your documents all in one place.
				</Typography.Text>
			</Flex>
			<LazyLoadImage
				className="product-description__img"
				src={descriptionPlaceholder}
				placeholderSrc={descriptionPlaceholder}
				height={isMobileL ? 223 : isTablet ? 264 : isLaptopS ? 379 : 492}
				alt="team"
			/>
		</Flex>
	);
};
