import { reatomAsync, withCache, withDataAtom, withStatusesAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";

import { AxiosError } from "axios";
import { UpdateStoryboardShots } from "@/features/storyboard-view/utils";
import { callErrorAction } from "@/entities/notification";
import { isGenerateStoryboardAtom, TStoryboardScene, TStoryboardShot } from "@/entities/storyboard";
import { generateStoryboardResource, getStoryboardResource, SBShot } from "@/shared/api/storyboard";
import { TTabView } from "@/shared/types/common";

export const storyboardViewAtom = atom<TTabView>("grid");
export const storyboardProjectId = atom<string>("");

export const generateStoryboardAbortController = atom<AbortController>(new AbortController(), "generateStoryboardAbortController");
export const getStoryboardAbortController = atom<AbortController>(new AbortController(), "getStoryboardAbortController");

export const generateStoryboardAction = reatomAsync((ctx, projectId: string) => {
	storyboardProjectId(ctx, projectId);
	const controller = ctx.get(generateStoryboardAbortController);
	return generateStoryboardResource(projectId, controller);
}, {
	onFulfill: (ctx) => {
		const projectId = ctx.get(storyboardProjectId);
		const generateStoryboard = ctx.get(isGenerateStoryboardAtom);

		isGenerateStoryboardAtom(ctx, { ...generateStoryboard, [projectId]: true });
		getStoryboardAction(ctx, projectId);
	},
	onReject: (ctx, err) => {
		if ((err as AxiosError).message === "Request failed with status code 504") {
			const projectId = ctx.get(storyboardProjectId);

			getStoryboardAction(ctx, projectId);
		} else {
			const projectId = ctx.get(storyboardProjectId);
			const generateStoryboard = ctx.get(isGenerateStoryboardAtom);

			isGenerateStoryboardAtom(ctx, { ...generateStoryboard, [projectId]: false });
			callErrorAction(ctx, err as AxiosError);
		}
	}
}).pipe(withStatusesAtom());

export const getStoryboardAction = reatomAsync((ctx, projectId: string) => {
	const controller = ctx.get(getStoryboardAbortController);
	return getStoryboardResource(projectId, controller);
}, "getStoryboardAction").pipe(
	withStatusesAtom(),
	withDataAtom([], (_ctx, res) => {
		const toShot = (shotsInfo: SBShot, shotsOrder: string[]): TStoryboardShot[] => {
			const shots: TStoryboardShot[] = Object.entries(shotsInfo).map(([shotId, shot]) => ({
				id: shotId, // guid
				idx: shotsOrder.findIndex((order) => order === shotId),
				time: shot.time ?? 0,
				location: shot.location ?? "",
				shotCharacteristics: shot.characteristics,
				description: shot.description ?? "",
				props: [], // max length 8
				cameraMovement: shot.camera_movement ?? "",
				shotSettings: shot.settings, // close-up | f/2.8
				dialogue: shot.dialog ?? "",
				characters: shot.characters,
				image: shot.image_url ?? "",
				title: shot.title ?? "",
				cameraAngle: shot.camera_angle ?? ""
			}));

			return shots.sort((shotA, shotB) => shotA.idx - shotB.idx);
		};
		const toScenes = () => {
			const updatedList: TStoryboardScene[] = Object.entries(res.data.scenes_info).map(([id, scene]) => ({
				id,
				tableView: "grid",
				idx: res.data.scenes_order.findIndex((order) => order === id),
				title: scene.title,
				shots: toShot(scene.shots_info, scene.shots_order)
			}));

			return updatedList.sort((sceneA, sceneB) => sceneA.idx - sceneB.idx);
		};

		const scenes = toScenes();

		return scenes;
	}),
	withCache()
);
export const updateStoryboardShotsAction = action((ctx, params: UpdateStoryboardShots) => {
	getStoryboardAction.dataAtom(ctx, (list) => list.map((scene) => ({
		...scene,
		shots: scene.id !== params.sceneId
			? scene.shots
			: scene.shots.map((shot) => ({
				...shot, image: (shot.id !== params.shotId) ? shot.image : params.image
			}))
	})));
}, "updateStoryboardShots");

updateStoryboardShotsAction.onCall(getStoryboardAction.cacheAtom.reset);
