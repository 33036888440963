import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { FC, useEffect } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { howuku } from "react-howuku";
import { RouterProvider } from "react-router-dom";

import { router } from "@/pages";

import { PermissionWrapper } from "@/entities/viewer";
import { AntDProvider, ReatomProvider } from "./providers";

import "./styles/index.css";

export const App: FC = () => {
	useEffect(() => {
		const IS_PROD = import.meta.env.VITE_PRODUCTION_BRANCH === "refs/heads/main";
		const PROD_DOMAIN = IS_PROD ? [/^https:\/\/blooper.ai\.io\/api/] : [/^https:\/\/dev.blooper.ai\.io\/api/];

		if (import.meta.env.PROD) {
			Sentry.init({
				dsn: import.meta.env.VITE_SENTRY_DSN,
				integrations: [
					Sentry.browserTracingIntegration(),
					Sentry.replayIntegration()
				],
				// Performance Monitoring
				tracePropagationTargets: PROD_DOMAIN,
				tracesSampleRate: 1.0, //  Capture 100% of the transactions
				// Session Replay
				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			});

			ReactGA.initialize([{
				trackingId: import.meta.env.VITE_GOOGLE_ANALYTICS_ID
			}]);
			howuku.initialize(import.meta.env.VITE_HOWUKU_ANALYTICS_ID);

			TagManager.initialize({
				gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER
			});
		}
	}, []);

	return (
		<ReatomProvider>
			<AntDProvider>
				<GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
					<PermissionWrapper>
						<RouterProvider router={router} />
					</PermissionWrapper>
				</GoogleOAuthProvider>
			</AntDProvider>
		</ReatomProvider>
	);
};
