import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { currentPlanAtom } from "@/pages/settings";
import { SettingsWrapper } from "@/entities/settings";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

export const CurrentPlan: FC = () => {
	const [currentPlan] = useAtom(currentPlanAtom);

	return (
		<SettingsWrapper
			title="Current Plan"
			description="See information regarding your current plan"
			className="full-width gap-s"
			style={{
				height: "244px",
				justifyContent: "space-between"
			}}
		>
			<Flex
				align="center"
				justify="space-between"
				style={{
					borderRadius: "8px",
					padding: "16px",
					border: "1px solid var(--metal-gray-300)"
				}}
			>
				<Flex vertical className="gap-4">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Plan</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>{currentPlan.name}</Typography.Text>
				</Flex>
				<div style={{ width: "1px", height: "100%", backgroundColor: "var(--metal-gray-300)" }} />
				<Flex vertical className="gap-4">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Projects</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>{currentPlan.projectsNumber}</Typography.Text>
				</Flex>
				<div style={{ width: "1px", height: "100%", backgroundColor: "var(--metal-gray-300)" }} />
				<Flex vertical className="gap-4">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Monthly Seats</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>{currentPlan.monthlySeats}</Typography.Text>
				</Flex>
				<div style={{ width: "1px", height: "100%", backgroundColor: "var(--metal-gray-300)" }} />
				<Flex vertical className="gap-4">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Invoice total</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>${currentPlan.invoiceTotal}</Typography.Text>
				</Flex>
			</Flex>
			<Flex className="gap-xs">
				<Button className="full-width">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						Cancel Subscription
					</Typography.Text>
				</Button>
				<Button type="primary" className="full-width">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						Upgrade plan
					</Typography.Text>
				</Button>
			</Flex>
		</SettingsWrapper>
	);
};
