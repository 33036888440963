import { useAtom } from "@reatom/npm-react";
import { FC } from "react";
import { teamMemberViewAtom } from "@/features/team-settings";
import { ViewTypeTab } from "@/shared/ui";

export const ChangeTeamView: FC = () => {
	const [tabView, setTabView] = useAtom(teamMemberViewAtom);

	return (
		<ViewTypeTab disabled={false} tabView={tabView} changeView={setTabView} />
	);
};
