import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { getSettingsPreferencesAction } from "@/pages/settings";
import { GeneralSettings, GeneralSettingsSkeleton } from "./general-settings";

export const SettingsPreferences: FC = () => {
	const getSettingsPreferences = useAction(getSettingsPreferencesAction);
	const [status] = useAtom(getSettingsPreferencesAction.statusesAtom);

	useEffect(() => {
		getSettingsPreferences();
	}, []);

	return (
		<Flex className="gap-xs">
			{status.isPending ? <GeneralSettingsSkeleton /> : <GeneralSettings />}
		</Flex>
	);
};
