import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ArchiveBoard } from "@/entities/archive-board";
import { InspirationBoard } from "@/entities/inspiration-board";
import {
	moodBoardStatusAtom,
	RightSidebarMoodboard,
	moodBoardSidebarView,
	getMoodboardAction
} from "@/entities/moodboard";
import { SearchBoard } from "@/entities/search-board";

import { MoodboardContent } from "./MoodboardContent";
import { MoodboardHeader } from "./MoodboardHeader";

import "./Moodboard.scss";

export const Moodboard: FC = () => {
	const { id } = useParams();
	const [moodBoardStatus] = useAtom(moodBoardStatusAtom);
	const [sidebarView] = useAtom(moodBoardSidebarView);
	const initMoodboard = useAction(getMoodboardAction);

	const isMoodBoardEmpty = moodBoardStatus === "empty";

	useEffect(() => {
		if (id) {
			initMoodboard(id);
		}
	}, []);

	return (
		<Flex className="full-width full-height">
			<Flex vertical className={cn("moodboard", "gap-s", { moodboard__full: !isMoodBoardEmpty })}>
				<MoodboardHeader />
				<MoodboardContent />
			</Flex>
			<RightSidebarMoodboard>
				{sidebarView === "inspiration" && <InspirationBoard />}
				{sidebarView === "archive" && <ArchiveBoard />}
				{sidebarView === "search" && <SearchBoard />}
			</RightSidebarMoodboard>
		</Flex>
	);
};
