import { Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { AuthWrapper, BackgroundPreLogin } from "@/entities/viewer";

export const SuccessEmail: FC = () => {
	const { email } = useParams();

	return (
		<BackgroundPreLogin>
			<AuthWrapper
				action={() => {}}
				title="Verification"
				questionText=""
				actionText=""
			>
				<Typography.Text>We sent an verification link to {email}</Typography.Text>
			</AuthWrapper>
		</BackgroundPreLogin>
	);
};
