import { Layout } from "react-grid-layout";

import { TMoodboardImage } from "@/shared/api/moodboard";
import { DROPPING_ELEMENT } from "./const";

export const getUpdatedMoodBoardList = (
	layout: Layout[],
	imageFromOutside: TMoodboardImage,
	moodBoardList: TMoodboardImage[]
): TMoodboardImage[] => {
	const nullableMoodBoardList = layout.map((moodBoardItem) => {
		const currentMoodBoardItem = moodBoardList.find((current) => current.id === moodBoardItem.i);

		if (currentMoodBoardItem) {
			return {
				...currentMoodBoardItem,
				coordinates: {
					...currentMoodBoardItem.coordinates,
					w: moodBoardItem.w,
					h: moodBoardItem.h,
					x: moodBoardItem.x,
					y: moodBoardItem.y
				}
			};
		}

		if (moodBoardItem.i === DROPPING_ELEMENT) {
			return {
				...imageFromOutside,
				coordinates: {
					...imageFromOutside.coordinates,
					w: moodBoardItem.w,
					h: moodBoardItem.h,
					x: moodBoardItem.x,
					y: moodBoardItem.y
				}
			};
		}

		return null;
	});

	const filteredMoodBoardList: unknown[] = nullableMoodBoardList.filter((item) => item !== null);

	return filteredMoodBoardList as TMoodboardImage[];
};
