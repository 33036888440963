import { reatomAsync, withAbort, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { callErrorAction, callSuccessNotificationAction } from "@/entities/notification";
import {
	getSettingsPreferencesResource,
	TSettingsPreferencesDTO,
	updateSettingsPreferencesResource
} from "@/shared/api/settings";

export const getSettingsPreferencesAction = reatomAsync((ctx) => getSettingsPreferencesResource(ctx.controller)).pipe(
	withDataAtom(),
	withStatusesAtom(),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
	withAbort()
);

export const updateSettingsPreferencesAction = reatomAsync((ctx, data: TSettingsPreferencesDTO) => updateSettingsPreferencesResource(data, ctx.controller), {
	onFulfill: (ctx) => callSuccessNotificationAction(ctx, "Preferences updated successfully"),
	onReject: (ctx, err) => callErrorAction(ctx, err)
}).pipe(withAbort());
