import { api } from "@/shared/api";
import { CharactersResult, CharacterUpdateDTO } from "./types";
import { TResponse } from "@/vite-env";

export const getCharactersResource = (projectId: string, controller: AbortController): TResponse<CharactersResult> => api.get(`/storyboards/${projectId}/characters`, { signal: controller.signal });
export const getCharactersDetectResource = (projectId: string, controller: AbortController): TResponse<{ [name: string]: string }> => api.get(`/storyboards/${projectId}/characters/detect`, { signal: controller.signal });

export const updateCharacterResource = (projectId: string, params: CharacterUpdateDTO, controller: AbortController) =>
	api({
		method: "PATCH",
		url: `/storyboards/${projectId}/characters`,
		data: params,
		signal: controller.signal
	});
