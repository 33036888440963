import script from "@/assets/shared/script.svg";
import storyboard from "@/assets/shared/storyboard.svg";
import video from "@/assets/shared/video.svg";

export const USE_CASES_LIST = [
	{
		img: script,
		link: "/use-case-script",
		description: "Script breakdowns made effortless"
	},
	{
		img: storyboard,
		link: "/use-case-storyboard",
		description: "Harness AI to instantly convert scripts into storyboards"
	},
	{
		img: video,
		link: "/use-case-video",
		description: "Accelerate postproduction with our video assistant"
	}
];

export const LINKS = [
	{
		title: "Product",
		url: "/"
	},
	{
		title: "Use cases",
		url: USE_CASES_LIST
	},
	// {
	// 	title: "Pricing",
	// 	url: "/pricing"
	// },
	// {
	// 	title: "Blog",
	// 	url: "/"
	// },
	{
		title: "Team",
		url: "/team"
	},
	{
		title: "Contact",
		url: "/contacts"
	}
];
