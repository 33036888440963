import { useNavigate } from "react-router-dom";

export const useJoinWaitingList = () => {
	const navigate = useNavigate();
	const join = () => {
		const element = document.querySelector(".join-waiting-list");

		if (element) {
			setTimeout(() => {
				element.scrollIntoView({ behavior: "smooth" });
			}, 100);
		} else {
			navigate("/?scroll=join-waiting-list");
		}
	};

	return join;
};
