import { Flex, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

interface ISceneHeader {
	title: string;
	shotLength: number;
	idx: number;
}

export const SceneHeader: FC<ISceneHeader> = ({ title, shotLength, idx }) => {
	return (
		<Flex justify="space-between" align="center">
			<Flex align="center" className="gap-s">
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>Scene {idx + 1} "{title}"</Typography.Text>
				<Typography.Text className={TEXT_CLASSNAMES.MdRegular}>{shotLength === 0 ? "not Shots" : shotLength === 1 ? `${shotLength} Shot` : `${shotLength} Shots`}</Typography.Text>
			</Flex>
		</Flex>
	);
};
