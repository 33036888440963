import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import { FC } from "react";

import { moodBoardStatusAtom } from "@/entities/moodboard";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
// import moodboardDesign from "@/assets/moodboard/design-moodboard.svg";

export const MoodboardHeader: FC = () => {
	const [moodBoardStatus] = useAtom(moodBoardStatusAtom);
	const isMoodBoardEmpty = moodBoardStatus === "empty";

	return (
		<Flex vertical className="gap-xs">
			<Flex align="center" className="gap-xxs">
				{/* <img src={moodboardDesign} alt="mooadboard design icon"/> */}
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>Design the Moodboard</Typography.Text>
			</Flex>
			{!isMoodBoardEmpty && (
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Craft the visual foundation of your film by creating a moodboard.<br/>
					You can easily add new images, arrange, and reorder them by dragging to prioritize your visual elements.
				</Typography.Text>
			)}
		</Flex>
	);
};
