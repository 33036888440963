import { FC, useEffect } from "react";
import ReactGA from "react-ga4";
import { ExamplePdf } from "@/entities/use-cases";
import blooperExamplePDF from "@/assets/blooper-example.pdf";

export const BlooperExample: FC = () => {
	useEffect(() => {
		const download = sessionStorage.getItem("download");

		if (download !== "download") {
			onButtonClick();
			sessionStorage.setItem("download", "download");
		}
	}, []);

	const onButtonClick = () => {
		const link = document.createElement("a");
		link.href = blooperExamplePDF;
		link.download = "Blooper example.pdf"; // specify the filename
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		ReactGA.event({
			category: "pdf download",
			action: "download pdf from blooper-example"
		});
	};

	return (
		<ExamplePdf
			actionName="download pdf from blooper-example"
			title="Your download will begin shortly."
			description=""
			buttonText="Re-download example pdf"
		/>
	);
};
