import { useAction, useAtom } from "@reatom/npm-react";
import {
	DragEvent,
	FC,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
// @ts-ignore
import GridLayout, { Layout, WidthProvider } from "react-grid-layout-aspect-ratio";

import { useParams } from "react-router-dom";
import { archiveBoardListAtom, removeFromArchiveBoardAction } from "@/entities/archive-board";
import {
	DRAGGABLE_IMAGE_ID,
	inspirationBoardListAtom,
	removeFromInspirationBoardAction
} from "@/entities/inspiration-board";
import {
	dropImageToMoodboardAction,
	moodBoardGridTypeAtom,
	moodBoardListAtom,
	updateAllMoodBoardAction,
	updateMoodboardImagesPositionAction
} from "@/entities/moodboard";

import { removeFromSearchBoardAction, searchBoardListAtom } from "@/entities/search-board";
import { TMoodboardImage } from "@/shared/api/moodboard";
import { urlToBlob } from "@/shared/methods";
import { DROPPING_ELEMENT, getUpdatedMoodBoardList } from "../lib";
import { CustomMoodBoardItem } from "./CustomMoodBoardItem";

import "react-grid-layout-aspect-ratio/css/styles.css";
import "./FullMoodboard.scss";

const ReactGridLayout = WidthProvider(GridLayout);

export const FullMoodboard: FC = () => {
	const elementRef = useRef<HTMLDivElement>(null);
	const params = useParams();

	const [mounted, setMounted] = useState(false);

	const [moodBoardList] = useAtom(moodBoardListAtom);
	const [inspirationBoardList] = useAtom(inspirationBoardListAtom);
	const [archiveBoardList] = useAtom(archiveBoardListAtom);
	const [searchBoardList] = useAtom(searchBoardListAtom);
	const [moodBoardGridType] = useAtom(moodBoardGridTypeAtom);

	const removeFromInspirationBoard = useAction(removeFromInspirationBoardAction);
	const removeFromArchiveBoard = useAction(removeFromArchiveBoardAction);
	const removeFromSearchBoard = useAction(removeFromSearchBoardAction);

	const updateAllMoodBoard = useAction(updateAllMoodBoardAction);
	const dropImage = useAction(dropImageToMoodboardAction);
	const updateLayoutPosition = useAction(updateMoodboardImagesPositionAction);

	const dropHelper = async (layout: Layout[], imageFromBoard: TMoodboardImage, id: string, removeHandler: (id: string) => void) => {
		const newMoodBoardList = getUpdatedMoodBoardList(layout, imageFromBoard, moodBoardList);
		const currentImage = newMoodBoardList.find((item) => item.id === id);

		updateAllMoodBoard(newMoodBoardList);
		removeHandler(id);

		if (params.id && currentImage) {
			const imageBlob = await urlToBlob(currentImage.data);
			const file = new File([imageBlob!], "drop");
			const formData = new FormData();
			formData.append("images", file);
			await dropImage(params.id, formData, currentImage.coordinates);
		}
	};
	const handleDrop = async (layout: Layout[], _layoutItem: Layout, event: unknown) => {
		const customEvent = event as DragEvent;

		const id = customEvent.dataTransfer.getData(DRAGGABLE_IMAGE_ID);
		const imageFromInspirationBoard = inspirationBoardList.find((item) => item.id === id);
		const imageFromArchiveBoard = archiveBoardList.find((item) => item.id === id);
		const imageFromSearchBoard = searchBoardList.find((item) => item.id === id);

		if (imageFromSearchBoard) {
			await dropHelper(layout, imageFromSearchBoard, id, removeFromSearchBoard);
		}

		if (imageFromInspirationBoard) {
			await dropHelper(layout, imageFromInspirationBoard, id, removeFromInspirationBoard);
		}

		if (imageFromArchiveBoard) {
			await dropHelper(layout, imageFromArchiveBoard, id, removeFromArchiveBoard);
		}
	};

	const layout: Layout[] = useMemo(() => moodBoardList.map(({ id, coordinates }) => ({
		i: id,
		resizeHandles: ["nw"],
		...coordinates
	})), [moodBoardList, moodBoardGridType]);

	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<div ref={elementRef} className="full-height full-width full-moodboard">
			<ReactGridLayout
				className="full-moodboard"
				layout={[...layout]}
				cols={12}
				rowHeight={60}
				droppingItem={{ i: DROPPING_ELEMENT, w: 2, h: 2 }}
				width={elementRef.current?.offsetWidth ?? 641}
				isDroppable
				isResizable
				onDrop={handleDrop}
				useCSSTransforms={mounted}
				verticalCompact={moodBoardGridType === "structure"}
				lockAspectRatio={true}
				onLayoutChange={(layout: Layout[]) => {
					if (params.id) {
						updateLayoutPosition(params.id, layout);
					}
				}}
			>
				{moodBoardList.map((moodboard) => (
					<CustomMoodBoardItem key={moodboard.id} moodboard={moodboard} />
				))}
				{/* <div key="special" /> */}
			</ReactGridLayout>
		</div>
	);
};
