import { reatomAsync } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";

import { callErrorAction } from "@/entities/notification";
import { addScriptToProjectAction } from "@/entities/script";
import { isGenerateStoryboardAtom } from "@/entities/storyboard";
import { viewerPermission } from "@/entities/viewer";
import {
	createProjectResource,
	deleteProjectResource,
	getAllProjectsResource,
	updateProjectNameResource
} from "@/shared/api/project";
import { TNewProject, TProject } from "../lib";

export const projectsAtom = atom<TProject[]>([], "projectsAtom");

export const isProjectsLoadingAtom = atom(
	(ctx) => ctx.spy(getProjectsAction.pendingAtom) > 0,
	"isLoadingAtom"
);

export const isProjectsEmptyAtom = atom<boolean>((ctx) => {
	const list = ctx.spy(projectsAtom);

	return !!list.length;
}, "isProjectsEmptyAtom");

export const createProjectAction = action(async (ctx, project: TNewProject) => {
	try {
		await createProjectResource({
			name: project.name ?? "",
			brief: project.briefing ?? ""
		});
		await addScriptToProjectAction(ctx, project.script, project.name);
		getProjectsAction(ctx);
	} catch (e) {
		callErrorAction(ctx, e as AxiosError);
	} finally {
		const generateStoryboard = ctx.get(isGenerateStoryboardAtom);

		if (project.name) {
			isGenerateStoryboardAtom(ctx, { ...generateStoryboard, [project.name]: false });
		}
	}
});

export const getProjectsAction = reatomAsync(() => getAllProjectsResource(), {
	name: "getProjectsAction",
	onFulfill: (ctx, response) => {
		// const projects = Promise.allSettled(response.data.names.map(getProjectByNameResource));
		// console.log("==========>projects", projects);
		const permission = ctx.get(viewerPermission);
		const names = permission === "admin" ? response.data.names : [...response.data.names, ...response.data.collab_names];
		projectsAtom(ctx, names.map((name) => ({
			date: "",
			img: "",
			name
		})));
	},
	onReject: (ctx, err) => {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const renameProjectByIdAction = action(async (ctx, oldName: string, newName: string) => {
	try {
		const projectList = ctx.get(projectsAtom);

		await updateProjectNameResource(oldName, newName);

		projectsAtom(ctx, projectList.map((project) => ({
			...project,
			name: oldName === project.name ? newName : project.name
		})));
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const deleteProjectByIdAction = action(async (ctx, name: string) => {
	try {
		await deleteProjectResource(name);
		getProjectsAction(ctx);
		isGenerateStoryboardAtom(ctx, { ...ctx.get(isGenerateStoryboardAtom), [name]: false });
	} catch (e) {
		callErrorAction(ctx, e as AxiosError);
	}
});
