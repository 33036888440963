import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import img from "@/assets/shared/coming_soon.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./ComingSoon.scss";

export const ComingSoon: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
	return (
		<Flex {...props} className={cn(props.className, "full-width coming-soon")} align="center" justify="center">
			<img src={img} alt="coming soon" className="coming-soon__img" />
			<Typography style={{ zIndex: 10 }} className={TEXT_CLASSNAMES.XsRegular}>
				Coming soon...
			</Typography>
		</Flex>
	);
};
