export const getUrlParams = (url: string, param: string) => {
	const urlParams = new URLSearchParams(url);

	return urlParams.getAll(param);
};

export const createUrlParam = (list: string[], param: string) => {
	const urlList = list.map((el) => ([param, el]));
	const url = new URLSearchParams(urlList).toString();

	return url;
};
