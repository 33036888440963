import { useAction } from "@reatom/npm-react";
import { Button, Flex, Form, Input, Select, Typography } from "antd";
import { FC, useRef, useState } from "react";
import { inviteTeamMemberAction } from "@/features/team-settings";
import { TEAM_MEMBER_MAP } from "@/features/team-settings/lib";
import close from "@/assets/shared/close_square.svg";
import { TeamMemberPosition } from "@/shared/api/teams";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

interface IInviteNewMemberField {
	onDelete: (id: number) => void;
	id: number;
}

type FieldType = {
	email: string;
	position: TeamMemberPosition;
}

export const InviteNewMemberField: FC<IInviteNewMemberField> = ({ onDelete, id }) => {
	const [hoverDelete, setHoverDelete] = useState(false);
	const timeout = useRef<null | ReturnType<typeof setTimeout>>(null);
	const inviteTeamMember = useAction(inviteTeamMemberAction);

	const onMouseEnter = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}

		setHoverDelete(true);
	};

	const onMouseLeave = () => {
		timeout.current = setTimeout(() => {
			setHoverDelete(false);
		}, 100);
	};

	const onFinish = (values: FieldType) => {
		inviteTeamMember({
			...values,
			role: "collaborator"
		});
	};

	const options = Object.entries(TEAM_MEMBER_MAP).map(([key, data]) => ({
		value: key,
		label: data.title
	}));

	return (
		<Form
			onMouseOver={onMouseEnter}
			onMouseOut={onMouseLeave}
			onFinish={onFinish}
			className="inviter-member__form"
		>
			{hoverDelete && <img onClick={() => onDelete(id)} className="inviter-member__delete" src={close} alt="delete" />}
			<Flex key="unique" className="gap-xxs" justify="space-between">
				<Form.Item<FieldType>
					name="email"
					rules={[{ required: true, message: "Missing email" }]}
					style={{ width: "100%" }}
				>
					<Input placeholder="email@address.com" />
				</Form.Item>
				<Form.Item<FieldType>
					name="position"
					rules={[{ required: true, message: "Missing position" }]}
				>
					<Select
						placeholder="Position"
						style={{ height: 44, width: 174 }}
						options={options}
					/>
				</Form.Item>
				<Button htmlType="submit" type="primary">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Invite</Typography.Text>
				</Button>
			</Flex>
		</Form>
	);
};
