import { useAction, useAtom } from "@reatom/npm-react";
import { useClickAway, useKeyPress } from "ahooks";
import { Flex } from "antd";
import cn from "classnames";
import { FC, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createEditor, Descendant } from "slate";
import { HistoryEditor, withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";

import { editingShotAtom } from "@/features/highlight-script";
import { addShotToSceneAction, updateShotValueInSceneAction } from "@/entities/script";
import plus from "@/assets/shared/plus.svg";

import { TShotColor } from "@/shared/api/highlight-the-shots";
import { ShotTitle } from "../shot-title";
import { LastShot } from "./LastShot";

import "./Shot.scss";

interface IShot {
	color: TShotColor;
	title: string;
	content: Descendant[];
	shotId: string;
	sceneId: string;
	shotIdx: number;
	isLast: boolean;
}

export const Shot: FC<IShot> = ({ color = "red", content, title, shotId, sceneId, shotIdx, isLast }) => {
	const [editingShot, setEditingShot] = useAtom(editingShotAtom);
	const [isHover, setIsHover] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [localShotValue, setLocalShotValue] = useState<Descendant[]>(content);
	const [editor] = useState(() => withReact(withHistory(createEditor())));
	const ref = useRef(null);
	const { id } = useParams();

	const updateShot = useAction(updateShotValueInSceneAction);
	const addShot = useAction(addShotToSceneAction);

	const onChange = (value: Descendant[]) => {
		setLocalShotValue(value);
		setEditingShot(shotId);
	};

	const onFocus = () => {
		setIsActive(true);
	};

	const onBlur = () => {
		setIsActive(false);
	};

	const toggleHover = () => {
		setIsHover((prev) => !prev);
	};

	const handleAddShot = () => {
		if (id) {
			addShot(id, { sceneId, shotId });
		}
	};

	useKeyPress(["ctrl.z", "meta.z"], () => {
		if (editingShot === shotId) {
			HistoryEditor.undo(editor);
		}
	}, { exactMatch: true });

	useKeyPress(["shift.ctrl.z", "meta.ctrl.z"], () => {
		if (editingShot === shotId) {
			HistoryEditor.redo(editor);
		}
	});

	useKeyPress(["esc"], () => {
		if (isActive && id) {
			setEditingShot("");
			updateShot(id, { shotId, sceneId, value: localShotValue });
		}
	});

	useClickAway(() => {
		const prevContent = JSON.stringify(content);
		const currentContent = JSON.stringify(localShotValue);

		if (id && prevContent !== currentContent) {
			setEditingShot("");
			updateShot(id, { shotId, sceneId, value: localShotValue });
		}
	}, ref);

	return (
		<>
			<Flex
				onMouseEnter={toggleHover}
				onMouseLeave={toggleHover}
				className={cn("shot", `shot__${color}`)}
			>
				<ShotTitle
					color={color}
					isHover={isHover}
					isActive={isActive}
					shotIdx={shotIdx}
					title={title}
					shotId={shotId}
					sceneId={sceneId}
				/>
				<div
					onClick={() => setEditingShot(shotId)}
					ref={ref}
					style={{ position: "relative" }}
					className="full-width"
				>
					<Slate
						onChange={onChange}
						editor={editor}
						initialValue={localShotValue}
					>
						<Editable
							onFocus={onFocus}
							onBlur={onBlur}
							className={cn("shot__text full-width", `shot__text__${color}`)}
						/>
					</Slate>
				</div>
			</Flex>
			{isLast
				? (
					<LastShot shotId={shotId} sceneId={sceneId} />
				)
				: (
					<Flex onClick={handleAddShot} role="button" align="center" justify="center" className="shot__add-shot cubic-animation pointer">
						<Flex className="pointer" align="center" justify="center">
							<img src={plus} alt="plus" className="shot__img" />
						</Flex>
					</Flex>
				)}
		</>
	);
};
