import { REG_EXP } from "@/shared/const/reg-exp";
import { TFeedbackMessage } from "@/shared/types/validation";

export const validateEmail = (email: string): TFeedbackMessage => {
	const regExp = REG_EXP.email;

	const containEmail = regExp.test(email) ? "success" : "validating";

	return email?.length ? containEmail : "";
};
