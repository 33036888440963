export const TEXT_CLASSNAMES = {
	LPHeadlineH1: "lp-headline-h1",
	LPHeadlineH2: "lp-headline-h2",
	LPHeadlineH3: "lp-headline-h3",
	LPHeadlineH4: "lp-headline-h4",
	LPHeadlineH5: "lp-headline-h5",

	LgRegular: "lg-regular",
	MdRegular: "md-regular",
	SmRegular: "sm-regular",
	XsRegular: "xs-regular",

	XxsRegular: "xxs-regular",

	LgLight: "lg-light",
	MdLight: "md-light",
	SmLight: "sm-light",
	XsLight: "xs-light",
	XxsLight: "xxs-light",

	LgSemibold: "lg-semibold",
	MdSemibold: "md-semibold",
	SmSemibold: "sm-semibold",
	XsSemibold: "xs-semibold",
	XxsSemibold: "xxs-semibold",

	LgBold: "lg-bold",
	MdBold: "md-bold",
	SmBold: "sm-bold",
	XsBold: "xs-bold",
	XxsBold: "xxs-bold",

	HeadlineH1: "headline-h1",
	HeadlineH2: "headline-h2",
	HeadlineH3: "headline-h3",
	HeadlineH4: "headline-h4"
};
