import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

// import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
// import { EMBENDINGS } from "@/shared/const/moodboard";
import { AddBriefMoodboard } from "@/features/add-brief-moodboard";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
// import { getUrlParams } from "@/shared/methods";
import { useOpenModal } from "@/shared/hooks";
import { DraggableBoard, DraggableImage } from "@/shared/ui";

import { DRAGGABLE_IMAGE_ID } from "../lib";
import {
	// getInspirationAction,
	getInspirationPromptAction,
	inspirationBoardListAtom,
	inspirationBoardLoadingAtom
	// inspirationPromptAtom,
	// limitAtom
} from "../model";
import emptyInspirationBoard from "@/assets/inspiration-board/empty_inspiration_board.svg";

import "./InspirationBoard.scss";

export const InspirationBoard: FC = () => {
	const { id } = useParams();
	// const [limit, setLimit] = useAtom(limitAtom);
	const [isOpenAddBriefnModal, toggleAddBriefModal] = useOpenModal();
	const [isLoading] = useAtom(inspirationBoardLoadingAtom);
	const [inspirationBoardList] = useAtom(inspirationBoardListAtom);
	const getInspirationPromt = useAction(getInspirationPromptAction);

	// const next = () => {
	// 	setLimit(limit + 10);
	// };

	useEffect(() => {
		if (id) {
			getInspirationPromt(id);
		}
	}, []);

	return (
		<Flex vertical className="inspiration-board gap-s">
			{/* <div */}
			{/*	id="scrollableDiv" */}
			{/*	style={{ */}
			{/*		height: 300, */}
			{/*		overflow: "auto", */}
			{/*		display: "flex", */}
			{/*		flexDirection: "column" */}
			{/*	}} */}
			{/* > */}
			{/*	<InfiniteScroll */}
			{/*		next={next} */}
			{/*		hasMore={inspirationBoardList.length <= limit} */}
			{/*		dataLength={inspirationBoardList.length} */}
			{/*		loader={<Skeleton avatar paragraph={{ rows: 1 }} active />} */}
			{/*		scrollableTarget="scrollableDiv" */}
			{/*		style={{ display: "flex", flexDirection: "column", 	flexWrap: "wrap" }} */}
			{/*		endMessage={<Divider plain>It is all, nothing more 🤐</Divider>} */}
			{/*	> */}
			{/*		{inspirationBoardList.map(({ id, data }) => ( */}
			{/*			<DraggableImage */}
			{/*				isFullWidth={false} */}
			{/*				key={id} */}
			{/*				id={id} */}
			{/*				img={data} */}
			{/*				draggableImageId={DRAGGABLE_IMAGE_ID} */}
			{/*			/> */}
			{/*		))} */}
			{/*	</InfiniteScroll> */}
			{/* </div> */}
			<DraggableBoard isLoading={isLoading}>
				{inspirationBoardList.length === 0
					? (
						<Flex style={{ paddingBottom: 24 }} vertical className="gap-xs full-height full-width" align="center">
							<img
								className="inspiration-board__image"
								src={emptyInspirationBoard}
								alt="inspiration board empty"
							/>
							<Flex vertical className="text-center gap-xxs">
								<Typography.Text className={cn(TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextSecondary)}>
								Inspiration board is empty
								</Typography.Text>
								<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
								Start to fill the brief and we will display images
								</Typography.Text>
							</Flex>
							<Button onClick={() => toggleAddBriefModal(true)}>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add Brief</Typography.Text>
							</Button>
						</Flex>
					)
					: (
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>The images were generated based on your script. Drag the pictures you like onto the moodboard.</Typography.Text>
					)}
				{inspirationBoardList.map(({ id, data }) => (
					<DraggableImage
						isFullWidth={false}
						key={id}
						id={id}
						img={data}
						draggableImageId={DRAGGABLE_IMAGE_ID}
					/>
				))}
			</DraggableBoard>
			<AddBriefMoodboard isOpen={isOpenAddBriefnModal} toggleModal={toggleAddBriefModal} />
		</Flex>
	);
};
