import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { useParams } from "react-router-dom";
import { addSceneAction, sceneListAtom } from "@/entities/script";
import plus from "@/assets/shared/plus.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { SceneShotOutline } from "./SceneShotOutline";

import "./ShotOutline.scss";

export const ShotOutline: FC = () => {
	const { id } = useParams();
	const [sceneList] = useAtom(sceneListAtom);
	const addScene = useAction(addSceneAction);

	const handleAddScene = () => {
		if (id) {
			addScene(id);
		}
	};

	return (
		<Flex vertical className="shot-outline">
			<Flex className="shot-outline__header">
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH3}>Shot Outline</Typography.Text>
			</Flex>
			<div className="shot-outline__divider" />
			<Flex vertical className="gap-s shot-outline__body">
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
					Select a shot to manage its settings or to add new shots to the scene. This panel helps you organize and refine the flow of your scenes efficiently.
				</Typography.Text>
				<Button onClick={handleAddScene} className="full-width flex-ctr-ctr gap-xxs shot-outline__button">
					<img src={plus} alt="plus" className="shot-outline__img" />
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add scene</Typography.Text>
				</Button>
				{sceneList.map((scene) => (
					<SceneShotOutline
						sceneId={scene.id}
						sceneIdx={scene.idx}
						sceneTitle={scene.title}
						key={scene.id}
						shots={scene.shots}
					/>
				))}
			</Flex>
		</Flex>
	);
};
