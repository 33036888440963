import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import arrowLeft from "@/assets/shared/arrow_left_24.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./Header.scss";

export const Header: FC = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const handleBack = () => {
		navigate(`/storyboard/${id}/storyboard`);
	};

	return (
		<Flex align="center" className="gap-xs">
			<Button className="shot-selection-editor-header-back flex-ctr-ctr" onClick={handleBack}>
				<img src={arrowLeft} alt="arrow left" />
			</Button>
			<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>Shot Editing</Typography.Text>
		</Flex>
	);
};
