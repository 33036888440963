import { AxiosResponse } from "axios";
import { api } from "@/shared/api";

import {
	GenerateImageResult,
	GenerateShotParams,
	GenerateShotResult,
	GetShotParams,
	GetShotResult,
	GetShotsParams, RegenerateImageParams,
	SceneShots,
	TStoryboardResponse
} from "./types";
import { TResponse } from "@/vite-env";

export const getStoryboardResource = (projectId: string, controller: AbortController): Promise<AxiosResponse<TStoryboardResponse>> =>
	api.get(`/storyboards/${projectId}/full/`, { signal: controller.signal });

export const generateStoryboardResource = (projectId: string, controller: AbortController): Promise<AxiosResponse<TStoryboardResponse>> =>
	api(
		{
			method: "POST",
			url: `/storyboards/${projectId}/generate_storyboard/`,
			signal: controller.signal
		});

export const getStoryboardShotResource = (params: GetShotParams, controller: AbortController): TResponse<GetShotResult> =>
	api.get(`/storyboards/${params.projectId}/shots/${params.shotId}`, { signal: controller.signal });

export const generateStoryboardShotResource = (params: GenerateShotParams): TResponse<GenerateShotResult> =>
	api.get(`/storyboards/${params.projectId}/shots/${params.shotId}`);

export const getAllSceneShotsResource = (params: GetShotsParams, controller: AbortController): TResponse<SceneShots> =>
	api.get(`/storyboards/${params.projectId}/scenes/${params.sceneId}/shots/`, { signal: controller.signal });

export const regenerateImageResource = (params: RegenerateImageParams, controller: AbortController): TResponse<GenerateImageResult> =>
	api.patch(`/storyboards/${params.projectId}/shots/${params.shotId}`, {}, { signal: controller.signal });
