import { useAtom } from "@reatom/npm-react";
import { notification } from "antd";
import { FC, PropsWithChildren, useEffect } from "react";
import { successAtom } from "@/entities/notification";

export const SuccessWrapper: FC<PropsWithChildren> = ({ children }) => {
	const [success] = useAtom(successAtom);
	const [api, contextHolder] = notification.useNotification();

	useEffect(() => {
		if (success !== null) {
			api.success(success);
		}
	}, [success]);

	return (
		<>
			{contextHolder}
			{children}
		</>
	);
};
