import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { ProductProcessBlock } from "./ProductProcessBlock";

import footage718 from "@/assets/product/footage-718.png";
import footagePlaceholder from "@/assets/product/footage-placeholder.png";
import footage from "@/assets/product/footage.svg";

import generator718 from "@/assets/product/generator-718.png";
import generatorPlaceholder from "@/assets/product/generator-placeholder.png";
import generator from "@/assets/product/generator.svg";

import interactiveSVG718 from "@/assets/product/interactive-718.png";
import interactivePlaceholder from "@/assets/product/interactive-placeholder.png";
import interactive from "@/assets/product/interactive.svg";

import "./ProductProcess.scss";

export const ProductProcess: FC = () => {
	const { isTablet, isLaptopS, isMobileL, isLaptopM } = useMediaQueries();

	return (
		<Flex className="product-process" vertical gap={isTablet ? 32 : 80}>
			<Typography.Text className={cn("text-center", TEXT_CLASSNAMES.LPHeadlineH2, {
				[TEXT_CLASSNAMES.LPHeadlineH3]: isLaptopS,
				[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet,
				[TEXT_CLASSNAMES.LPHeadlineH5]: isMobileL
			})}>The production process with Blooper</Typography.Text>
			<Flex vertical>
				<Flex>
					<ProductProcessBlock
						buttons={["AI Assistant"]}
						idx={1}
						title={<>Script <br/> assistant</>}
						description1="Transform your screenplay seamlessly with our script assistant. Blooper analyzes your script and proposes a shot outline."
						description2=""
						direct="right"
						path="/use-case-script"
						mobileSVGImg={interactiveSVG718}
						alt="Script assistant"
					/>
					<div className="product-process__vertical">
						<div className="product-process__vertical__dot" />
					</div>
					<Flex className="flex-1 product-process__img">
						<LazyLoadImage
							src={interactive}
							alt="Interactive script assistant"
							placeholderSrc={interactivePlaceholder}
							height={isTablet ? 215 : isLaptopS ? 350 : isLaptopM ? 384 : 445}
						/>
					</Flex>
				</Flex>
				<Flex style={{ height: isTablet ? "80px" : "48px" }} align="center" justify="center">
					<div className="flex-1 full-width full-height" />
					<div className="product-process__vertical full-height" />
					<div className="flex-1 full-width full-height" />
				</Flex>
				<Flex>
					{!isTablet && (
						<Flex className="flex-1 product-process__img">
							<LazyLoadImage
								src={generator}
								alt="Storyboard generator"
								placeholderSrc={generatorPlaceholder}
								height={isTablet ? 225 : isLaptopS ? 260 : isLaptopM ? 383 : 425}
							/>
						</Flex>
					)}
					<div className="product-process__vertical">
						<div className="product-process__vertical__dot" />
					</div>
					<ProductProcessBlock
						buttons={["Image Generator"]}
						idx={2}
						title="Storyboard generator"
						description1="Create together with our AI assistant the moodboard of your movie to set the tone for the image generation of your storyboard."
						description2="Blooper derives important properties of your scene from your script and directly includes it in the storyboard."
						direct="left"
						path="/use-case-storyboard"
						mobileSVGImg={generator718}
						alt="Storyboard generator"
					/>
					{isTablet && (
						<Flex className="flex-1 product-process__img">
							<LazyLoadImage
								src={generator}
								alt="Storyboard generator"
								placeholderSrc={generatorPlaceholder}
								height={isTablet ? 225 : isLaptopS ? 260 : isLaptopM ? 383 : 425}
							/>
						</Flex>
					)}
				</Flex>
				<Flex style={{ height: isTablet ? "80px" : "48px" }} align="center" justify="center">
					<div className="flex-1 full-width full-height" />
					<div className="product-process__vertical full-height" />
					<div className="flex-1 full-width full-height" />
				</Flex>
				<Flex>
					<ProductProcessBlock
						buttons={["Synchronize with storyboard"]}
						idx={3}
						title="Raw footage to rough cut"
						description1="Create synergy: Blooper uses your storyboard and script to create a first rough cut from your raw footage."
						description2="Blooper gives you recommendations for scene compositions, connects with movie archives and suggests you fitting B-roll footage."
						direct="right"
						path="/use-case-video"
						mobileSVGImg={footage718}
						alt="Raw footage to rough cut"
					/>
					<div className="product-process__vertical">
						<div className="product-process__vertical__dot" />
					</div>
					<Flex className="flex-1 product-process__img">
						<LazyLoadImage
							src={footage}
							alt="Raw footage-to-rough cut"
							placeholderSrc={footagePlaceholder}
							height={isTablet ? 250 : isLaptopS ? 356 : isLaptopM ? 450 : 520}
						/>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
