import { Flex } from "antd";
import { FC } from "react";

import { NOT_RELEASE } from "@/shared/const/release";
import { ComingSoon } from "@/shared/ui";
import { ShotEditLine } from "./ShotEditLine";

import changeObject from "@/assets/shot-selection/change-object.svg";
import changeStyle from "@/assets/shot-selection/change-style.svg";
import removeObject from "@/assets/shot-selection/remove-object.svg";

export const ShotEdit: FC = () => {
	const onClick = () => {

	};

	if (NOT_RELEASE) {
		return (
			<Flex vertical className="gap-xs full-width">
				<ShotEditLine title="Change object" img={changeObject} handleClick={onClick} color="green" />
				<ShotEditLine title="Remove the object" img={removeObject} handleClick={onClick} color="violet" />
				<ShotEditLine title="Change style" img={changeStyle} handleClick={onClick} color="blue" />
			</Flex>
		);
	}

	return <ComingSoon />;
};
