import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";
import { callErrorAction } from "@/entities/notification";
import {
	getInspirationPromptsResource,
	getInspirationResource,
	TMoodboardImage,
	updateInspirationPromptsResource
} from "@/shared/api/moodboard";
import { EMBENDINGS } from "@/shared/const/moodboard";
import { base64toBlob, getAspectRatio, getGcd, getImageFromBlob, getUrlParams } from "@/shared/methods";

export const isDroppableAtom = atom(false, "isDroppable");

export const inspirationBoardListAtom = atom<TMoodboardImage[]>([], "inspirationBoardList");
export const limitAtom = atom(10, "limit");

export const inspirationPromptAtom = atom<string>("", "inspirationPrompt");

inspirationPromptAtom.onChange((ctx, newPrompt) => {
	const brief = getUrlParams(newPrompt, EMBENDINGS);

	if (brief.length) {
		getInspirationAction(ctx, brief, 10);
	}
});

limitAtom.onChange((ctx, newLimit) => {
	const brief = getUrlParams(ctx.get(inspirationPromptAtom), EMBENDINGS);

	if (brief.length) {
		getInspirationAction(ctx, brief, newLimit);
	}
});

export const removeFromInspirationBoardAction = action((ctx, id: string) => {
	inspirationBoardListAtom(ctx, ctx.get(inspirationBoardListAtom).filter((item) => item.id !== id));
});
export const inspirationBoardLoadingAtom = atom<boolean>(false, "inspirationBoardLoadingAtom");
export const getInspirationAction = action(async (ctx, query: string[], limit: number) => {
	try {
		inspirationBoardLoadingAtom(ctx, true);
		const { data } = await getInspirationResource(query, limit);

		const imagesPromises: Promise<TMoodboardImage>[] = data.images.map(async (image) => {
			const blob = base64toBlob(image.data);
			const { width, height } = await getImageFromBlob(blob);
			const aspectRatio = await getAspectRatio(width, height);
			const { numerator, denominator } = getGcd(aspectRatio);

			const updatedImage: TMoodboardImage = {
				...image,
				data: URL.createObjectURL(blob),
				coordinates: { x: 0, y: 0, w: numerator, h: denominator, minW: 2, minH: 2, id: image.id },
				id: image.id,
				isStatic: false
			};

			return updatedImage;
		});

		const images = await Promise.all(imagesPromises);
		inspirationBoardListAtom(ctx, images);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	} finally {
		inspirationBoardLoadingAtom(ctx, false);
	}
}, "getEmbeddingsAction");

export const updateInspirationPromptAction = action(async (ctx, projectId: string, prompt: string) => {
	try {
		await updateInspirationPromptsResource(projectId, prompt);
		inspirationPromptAtom(ctx, prompt);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
}, "updateEmbeddingsAction");

export const getInspirationPromptAction = action(async (ctx, projectId: string) => {
	try {
		inspirationBoardLoadingAtom(ctx, true);
		const res = await getInspirationPromptsResource(projectId);
		inspirationBoardLoadingAtom(ctx, false);
		inspirationPromptAtom(ctx, res.data.prompt);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});
