import { Flex, Modal, Tabs, TabsProps, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { UploadTab } from "@/entities/projects/ui/thumbnail-project-modal/upload-tab";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";
import { SelectTab } from "./select-tab";

interface Props extends IOpenModal {

}

export const ThumbnailProjectModal: FC<Props> = ({ isOpen, toggleModal }) => {
	const images = [{
		img: "https://blooper-dev.ams3.digitaloceanspaces.com/168/4eb26702-7238-47d0-a2a5-7d20a66d67c4",
		idx: 0
	},
	{
		img: "https://blooper-dev.ams3.digitaloceanspaces.com/168/4eb26702-7238-47d0-a2a5-7d20a66d67c4",
		idx: 1
	},
	{
		img: "https://blooper-dev.ams3.digitaloceanspaces.com/168/4eb26702-7238-47d0-a2a5-7d20a66d67c4",
		idx: 2
	},
	{
		img: "https://blooper-dev.ams3.digitaloceanspaces.com/168/4eb26702-7238-47d0-a2a5-7d20a66d67c4",
		idx: 3
	}
	];
	const items: TabsProps["items"] = [
		{
			key: "1",
			label: "Select",
			children: <SelectTab onClose={() => toggleModal(false)} images={images} />
		},
		{
			key: "2",
			label: "Upload",
			children: <UploadTab />
		}
	];

	return (
		<Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
			<Flex vertical>
				<Flex vertical className="gap-s">
					<Flex vertical>
						<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>Set a thumbnail</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
							Select a thumbnail for your project or upload it from your computer
						</Typography.Text>
					</Flex>
					<Tabs defaultActiveKey="1" items={items} />
				</Flex>
			</Flex>
		</Modal>
	);
};
