import { Flex } from "antd";
import { FC, PropsWithChildren } from "react";

import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import bg1024 from "@/assets/viewer/bg-1024.svg";
import bg1280 from "@/assets/viewer/bg-1280.svg";
import bg1728 from "@/assets/viewer/bg-1728.svg";
import bg1920 from "@/assets/viewer/bg-1920.svg";
import bg2560 from "@/assets/viewer/bg-2560.svg";
import bg320 from "@/assets/viewer/bg-320.svg";
import bg414 from "@/assets/viewer/bg-414.svg";
import bg744 from "@/assets/viewer/bg-744.svg";

import "./BackgroundPreLogin.scss";

export const BackgroundPreLogin: FC<PropsWithChildren> = ({ children }) => {
	const { isMobileS, isMobileL, isTablet, isLaptopS, isLaptopM, isLaptopL, isFullHd } = useMediaQueries();

	const getImage = () => {
		if (isMobileS) {
			return bg320;
		}

		if (isMobileL) {
			return bg414;
		}

		if (isTablet) {
			return bg744;
		}

		if (isLaptopS) {
			return bg1024;
		}

		if (isLaptopM) {
			return bg1280;
		}

		if (isLaptopL) {
			return bg1728;
		}

		if (isFullHd) {
			return bg1920;
		}

		return bg2560;
	};
	return (
		<Flex justify="center" align="center" className="background-pre-login">
			<img src={getImage()} alt="background" className="background-pre-login__img"/>
			<Flex justify="center" align="center" className="background-pre-login__content full-width full-height">
				{children}
			</Flex>
		</Flex>
	);
};
