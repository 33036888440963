import { Flex } from "antd";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";

import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";

import { LandingLayoutContext } from "./LandingLayoutContext";

import "./LandingLayout.scss";

export const LandingLayout: FC = () => {
	const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
	const toggleOpen = () => {
		setIsBurgerMenuOpen((prev) => !prev);
	};

	return (
		<LandingLayoutContext.Provider value={{ isOpen: isBurgerMenuOpen, toggleOpen }}>
			<Flex vertical id="layout" style={{
				overflow: isBurgerMenuOpen ? "hidden" : "auto"
			}}>
				<Header />
				<main>
					<Outlet />
				</main>
				<Footer />
			</Flex>
		</LandingLayoutContext.Provider>
	);
};
