import { useAction } from "@reatom/npm-react";
import { useHover } from "ahooks";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { updateStoryboardShotsAction } from "@/features/storyboard-view";
import { updateGenerateImageAction } from "@/entities/storyboard";
import { generateStoryboardShotResource } from "@/shared/api/storyboard";
import { useOpenModal } from "@/shared/hooks";

export const useShotStoryboard = () => {
	const navigate = useNavigate();
	const params = useParams();
	const imageRef = useRef(null);
	const isHoverImage = useHover(imageRef);
	const updateStoryboardShot = useAction(updateStoryboardShotsAction);
	const updateGenerateImage = useAction(updateGenerateImageAction);
	const [isOpenModal, toggleOpenModal] = useOpenModal();
	const onCharacterRedirect = (key: string) => {
		navigate(`/storyboard/${params.id}/character/${key}/character-info`);
	};
	const onRedirect = (shotId: string, sceneId: string) => {
		navigate(`/storyboard/${params.id}/shot-selection/${sceneId}/${shotId}`);
	};

	const onGenerate = async (shotId: string, sceneId: string) => {
		console.log("==========>1", 1);
		updateGenerateImage({ shotId, loading: true, image: null });
		let image = null;

		try {
			if (params.id) {
				toggleOpenModal(false);
				const shotInfo = await generateStoryboardShotResource({ shotId, projectId: params.id });
				console.log("==========>shotInfo.data", shotInfo.data);
				image = shotInfo.data.image_url ?? "";
				updateStoryboardShot({ shotId, sceneId, image });
			}
		} catch (err) {
			updateGenerateImage({ shotId, loading: false, image });
		} finally {
			updateGenerateImage({ shotId, loading: false, image });
		}
	};

	return {
		isOpenModal,
		toggleOpenModal,
		onRedirect,
		onGenerate,
		imageRef,
		isHoverImage,
		onCharacterRedirect
	};
};
