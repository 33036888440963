import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC, MouseEvent, useCallback } from "react";

import { useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { RegenerateImagesModal } from "@/pages/storyboard/ui/RegenerateImagesModal";
import { ExportStoryboardModal } from "@/features/export-storyboard-modal";
import {
	generateStoryboardAction,
	getStoryboardAction,
	StoryboardView,
	storyboardViewAtom, updateStoryboardShotsAction
} from "@/features/storyboard-view";
import { Generate, GenerateOrRegenerate } from "@/features/storyboard-view/utils";
import { updateGenerateImageAction } from "@/entities/storyboard";
import exportIcon from "@/assets/shared/export_black.svg";
import { generateStoryboardShotResource, regenerateImageResource } from "@/shared/api/storyboard";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { ViewTypeTab } from "@/shared/ui";

import "./Storyboard.scss";

export const Storyboard: FC = () => {
	const updateGenerateImage = useAction(updateGenerateImageAction);
	const updateStoryboardShot = useAction(updateStoryboardShotsAction);

	const { id } = useParams();
	const [isOpenExport, toggleOpenExport] = useOpenModal();
	const [isOpenRegenerate, toggleRegenerate] = useOpenModal();
	const [tableView, setTableView] = useAtom(storyboardViewAtom);

	const [storyboardList] = useAtom(getStoryboardAction.dataAtom);
	const [getStatuses] = useAtom(getStoryboardAction.statusesAtom);
	const [generateStatuses] = useAtom(generateStoryboardAction.statusesAtom);

	const { toPDF, targetRef } = usePDF({
		filename: `${id}.pdf`,
		method: "save",
		page: { margin: Margin.NONE }
	});

	const callFunctionOnEachArrayElementInBatches = async (array: Generate[], callback: (projectId: string) => (shot: Generate) => Promise<GenerateOrRegenerate>) => {
		const notGeneratedShots = array.filter((shot) => !shot.isRegenerate);
		notGeneratedShots.forEach((shot) => {
			updateGenerateImage({ shotId: shot.shotId, loading: true, image: null });
		});

		while (notGeneratedShots.length > 0) {
			const chunkSize = 5;
			const batch = notGeneratedShots.splice(0, chunkSize);

			if (id) {
				const shotsInfo = await Promise.allSettled(batch.map(callback(id)));

				shotsInfo.forEach((shotInfo) => {
					if (shotInfo.status === "fulfilled") {
						updateStoryboardShot({
							shotId: shotInfo.value.shotId,
							sceneId: shotInfo.value.sceneId,
							image: shotInfo.value.image ?? ""
						});
					}
				});
			}
		}
	};

	const generateOrRegenerateImage = (projectId: string) => async (shot: Generate): Promise<GenerateOrRegenerate> => {
		let image = null;
		try {
			if (shot.isRegenerate) {
				const shotInfo = await regenerateImageResource({ shotId: shot.shotId, projectId }, new AbortController());
				image = shotInfo.data.image_url ?? "";

				return {
					image,
					shotId: shot.shotId,
					sceneId: shot.sceneId
				};
			} else {
				const shotInfo = await generateStoryboardShotResource({ shotId: shot.shotId, projectId });
				image = shotInfo.data.image_url ?? "";

				return {
					image,
					shotId: shot.shotId,
					sceneId: shot.sceneId
				};
			}
		} catch (e) {
			console.error(e);
			return {
				image: "",
				shotId: shot.shotId,
				sceneId: shot.sceneId
			};
		} finally {
			updateGenerateImage({ shotId: shot.shotId, loading: false, image });
		}
	};

	const onGenerateImages = useCallback((shots: Generate[]) => {
		return async (event: MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			await callFunctionOnEachArrayElementInBatches(shots, generateOrRegenerateImage);
		};
	}, []);

	const onRegenerate = () => {
		toggleRegenerate(true);
	};

	const shots: Generate[] = storyboardList.map((scene) => scene.shots.map((shot) => (
		{ shotId: shot.id, isRegenerate: !!shot.image, sceneId: shot.id })
	)).flat();

	const disabled = generateStatuses.isPending || getStatuses.isPending;
	const isRegenerate = !!storyboardList.map((scene) => scene.shots.filter((shot) => !!shot.image)).flat().length;

	return (
		<Flex vertical className="storyboard full-height">
			<Flex vertical className="gap-4" style={{ paddingBottom: 16 }}>
				<Flex justify="space-between">
					<Flex justify="center" align="end" className="gap-xxs">
						{/* <StoryboardStep fill="#0C0C0D" className="storyboard__img" /> */}
						<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>{id}</Typography.Text>
					</Flex>
					<Flex justify="center" align="center" className="gap-xs">
						<Button disabled={disabled} type="primary" onClick={isRegenerate ? onRegenerate : onGenerateImages(shots)}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								Generate images
							</Typography.Text>
						</Button>
						<ViewTypeTab border disabled={disabled} tabView={tableView} changeView={setTableView} />
						{/* <Button onClick={() => toggleOpenSettings(true)} className="flex-ctr-ctr" icon={<img src={settnigs} alt="Settings" />}> */}
						{/*	<Typography.Text className={TEXT_CLASSNAMES.XsRegular}> */}
						{/*		View Settings */}
						{/*	</Typography.Text> */}
						{/* </Button> */}
						<Button
							className="flex-ctr-ctr"
							icon={<img src={exportIcon} alt="Export" />}
							onClick={() => toggleOpenExport(true)}
							disabled={disabled}
						>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								Export
							</Typography.Text>
						</Button>
						<ExportStoryboardModal
							toggleModal={toggleOpenExport}
							isOpen={isOpenExport}
							isPending={disabled}
							storyboardList={storyboardList}
							toPDF={toPDF}
						/>
					</Flex>
				</Flex>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Storyboard displays a complete overview of your project. <br/>
					Each scene is meticulously detailed, allowing you to visualize and adjust all aspects of your film’s narrative and execution
				</Typography.Text>
			</Flex>
			<Flex className="storyboard__content full-height">
				<StoryboardView targetRef={targetRef} />
			</Flex>
			<RegenerateImagesModal
				shots={shots}
				toggleModal={toggleRegenerate}
				isOpen={isOpenRegenerate}
				onGenerateImages={onGenerateImages}
			/>
		</Flex>
	);
};
