import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { JoinWaitingList, useJoinWaitingList } from "@/features/join-waiting-list";
import { PRODUCT_FEATURE_LIST, ProductDescription, ProductFeature, ProductProcess } from "@/entities/product";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useParallax } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import "./Product.scss";
export const Product: FC = () => {
	const [searchParams] = useSearchParams();
	const join = useJoinWaitingList();
	const { isTablet, isLaptopS, isMobileXL } = useMediaQueries();

	useEffect(() => {
		if (searchParams.get("scroll")) {
			join();
		}
	}, [searchParams]);

	useParallax(1.5, ".product__header");

	return (
		<Flex vertical className="landing-content-center">
			<Flex vertical className="product__header gap-xs">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH3]: isTablet,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileXL
				})}>
					Building the future of video production with Blooper
				</Typography.Text>
				<Typography.Text className={cn(TEXT_CLASSNAMES.LgRegular, {
					[TEXT_CLASSNAMES.MdRegular]: isLaptopS,
					[TEXT_CLASSNAMES.SmRegular]: isTablet,
					[TEXT_CLASSNAMES.XsRegular]: isMobileXL
				})}>
					Making a film is always a lot of work. Give some tasks to Blooper, so you can <br/> focus on the important stuff and save you a lot of time and money.
				</Typography.Text>
			</Flex>
			<div style={{ zIndex: 100 }}>
				<Flex vertical gap={isTablet ? 80 : 160} className="product__content">
					<Flex vertical={isTablet} gap={isMobileXL ? 48 : 24} justify="space-between" className="full-width">
						{PRODUCT_FEATURE_LIST.map((feature, index) => (
							<ProductFeature {...feature} key={index.toString(36)} />
						))}
					</Flex>
					<ProductDescription />
					<ProductProcess />
				</Flex>
				<JoinWaitingList className="product__waiting-list" />
			</div>
		</Flex>
	);
};
