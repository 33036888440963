import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Form, Select, Typography, Radio } from "antd";
import cn from "classnames";
import { FC } from "react";

import { getSettingsPreferencesAction, updateSettingsPreferencesAction } from "@/pages/settings";
import { SettingsWrapper } from "@/entities/settings";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { DisableSubmitButton } from "@/shared/ui";

import darkTheme from "@/assets/settings/view/theme_dark.svg";
import lightTheme from "@/assets/settings/view/theme_light.svg";

import "./GeneralSettings.scss";

type FieldType = {
	theme: string;
	language: string;
}

export const GeneralSettings: FC = () => {
	const [form] = Form.useForm();
	const [preferences] = useAtom(getSettingsPreferencesAction.dataAtom);
	const updatePreferences = useAction(updateSettingsPreferencesAction);
	const theme = Form.useWatch("theme", form);

	const onFinish = (values: FieldType) => {
		updatePreferences(values);
	};

	return (
		<SettingsWrapper
			title="General settings"
			description="Update your personal details here"
			className="full-width gap-s"
		>
			<Form
				form={form}
				initialValues={{ theme: preferences?.data?.theme, language: preferences?.data?.language }}
				onFinish={onFinish}
			>
				<Flex vertical className="gap-s">
					<Flex vertical className="gap-xs">
						<Flex vertical className="gap-4">
							<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>Theme</Typography.Text>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Change the appearance of your app</Typography.Text>
						</Flex>
						<Form.Item<FieldType>
							name="theme"
							required
						>
							<Radio.Group>
								<Flex vertical className="gap-xs">
									<Flex className={cn("gap-s general-settings__theme cubic-animation", { "general-settings__theme--active": theme === "light" })}>
										<Flex vertical justify="space-between">
											<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Light</Typography.Text>
											<Radio value="light" />
										</Flex>
										<img src={lightTheme} alt="light theme" />
									</Flex>
									<Flex className={cn("gap-s general-settings__theme cubic-animation", { "general-settings__theme--active": theme === "dark" })}>
										<Flex vertical justify="space-between">
											<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Dark</Typography.Text>
											<Radio value="dark" />
										</Flex>
										<img src={darkTheme} alt="dark theme" />
									</Flex>
								</Flex>
							</Radio.Group>
						</Form.Item>
					</Flex>
					<Flex justify="space-between">
						<Flex vertical className="gap-4">
							<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>Language</Typography.Text>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Select language of the interface</Typography.Text>
						</Flex>
						<Form.Item<FieldType>
							name="language"
							required
						>
							<Select style={{ height: 44 }} placeholder="Language">
								<Select.Option value="en">English</Select.Option>
								<Select.Option value="ru">Russian</Select.Option>
							</Select>
						</Form.Item>
					</Flex>
					<Flex className="gap-xs">
						<Button className="full-width">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
						</Button>
						<DisableSubmitButton isFullWidth type="primary" form={form}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save Changes</Typography.Text>
						</DisableSubmitButton>
					</Flex>
				</Flex>
			</Form>
		</SettingsWrapper>
	);
};
