import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import success from "@/assets/shared/success.svg";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./TrialVersionPlan.scss";

interface ITrialVersionPlan {
	startUsingAction: () => void;
	upgradePlanAction: () => void
}

export const TrialVersionPlan: FC<ITrialVersionPlan> = ({ startUsingAction, upgradePlanAction }) => (
	<Flex justify="center" align="center" vertical className="trial-version-plan gap-xs">
		<img className="trial-version-plan__img" src={success} alt="success" />
		<Flex justify="center" align="center" vertical className="gap-xxs">
			<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4)}>
				You have started your 14 days free trial version
			</Typography.Text>
			<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
				You have restrictions on the use of functionality. To get access to full <br /> functionality upgrade your plan.
			</Typography.Text>
		</Flex>
		<Flex vertical className="gap-xxs full-width">
			<Button onClick={upgradePlanAction} type="primary">
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Upgrade plan</Typography.Text>
			</Button>
			<Button onClick={startUsingAction}>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Start using</Typography.Text>
			</Button>
		</Flex>
	</Flex>
);
