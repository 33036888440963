export const urlToBlob = async (url: string) => {
	try {
		const res = await fetch(url);
		const blob = await res.blob();

		return blob;
	} catch (e) {
		console.error(e);
	}
};
