import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import "./UseCaseDescription.scss";

interface IUseCaseDescription extends HTMLAttributes<HTMLDivElement> {
	img: string;
	title: string;
	description: string;
}

export const UseCaseDescription: FC<IUseCaseDescription> = ({ img, title, description, ...props }) => {
	const { isMobileL } = useMediaQueries();

	return (
		<Flex {...props} align="start" justify="center" vertical className="use-case-description gap-s">
			<Flex justify="center" align="center" className="use-case-description__img">
				<img src={img} alt={title} />
			</Flex>
			<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH1, {
				[TEXT_CLASSNAMES.HeadlineH2]: isMobileL
			})}>{title}</Typography.Text>
			<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
				[TEXT_CLASSNAMES.SmRegular]: isMobileL
			})}>{description}</Typography.Text>
		</Flex>
	);
};
