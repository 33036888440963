import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, PropsWithChildren } from "react";

import greenBlooper from "@/assets/shared/green_blooper.svg";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { CookieBanner } from "@/shared/ui";

import "./AuthWrapper.scss";

interface IAuthWrapper {
	title: string;
	questionText: string | (string | JSX.Element)[];
	actionText: string;
	action: () => void;
}

export const AuthWrapper: FC<PropsWithChildren<IAuthWrapper>> = ({ title, questionText, actionText, action, children }) => {
	const { isMobileL } = useMediaQueries();

	return (
		<Flex vertical className="auth-wrapper">
			<img className="auth-wrapper__img" src={greenBlooper} alt="green blooper logo"/>
			<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH2, {
				[TEXT_CLASSNAMES.LPHeadlineH3]: isMobileL
			})}>{title}</Typography.Text>
			<Flex align="center">
				<Typography.Text
					className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}
				>
					{questionText}
				</Typography.Text>
				&nbsp;
				{NOT_RELEASE && (
					<Typography.Text
						onClick={action}
						className={cn(TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextSecondary, "pointer auth-wrapper__action-text")}
					>
						{actionText}
					</Typography.Text>
				)}
			</Flex>
			{children}
			<CookieBanner />
		</Flex>
	);
};
