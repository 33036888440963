import cn from "classnames";
import { DragEvent, FC, useState } from "react";

import "./DraggableImage.scss";

interface IDraggableImage {
	id: string;
	img: string;
	draggableImageId: string;
	isFullWidth: boolean;
}

export const DraggableImage: FC<IDraggableImage> = ({ id, img, draggableImageId, isFullWidth }) => {
	const [isImageHover, setIsImageHover] = useState(false);
	const handleDragStart = (event: DragEvent) => {
		event.dataTransfer?.setData(draggableImageId, id.toString());
	};

	const handleMouseOver = () => {
		setIsImageHover(true);
	};
	const handleMouseLeave = () => {
		setIsImageHover(false);
	};

	return (
		<div
			className={cn("draggable-image", {
				"draggable-image__full-width": isFullWidth
			})}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
		>
			<img
				draggable
				onDragStart={handleDragStart}
				className={cn("draggable-image__image", {
					"draggable-image__image__full-width": isFullWidth
				})}
				src={img}
				key={id}
				alt="image"
			/>

			<div className={cn("draggable-image__corner", "draggable-image__corner__left-top", {
				"draggable-image__corner__view": isImageHover
			})} />
			<div className={cn("draggable-image__corner", "draggable-image__corner__right-top", {
				"draggable-image__corner__view": isImageHover
			})} />
			<div className={cn("draggable-image__corner", "draggable-image__corner__left-bottom", {
				"draggable-image__corner__view": isImageHover
			})} />
			<div className={cn("draggable-image__corner", "draggable-image__corner__right-bottom", {
				"draggable-image__corner__view": isImageHover
			})} />
		</div>
	);
};
