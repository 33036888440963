import { reatomAsync, withAbort, withStatusesAtom, withErrorAtom, withDataAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { callErrorAction } from "@/entities/notification";
import { GENERATE_STORYBOARD_STATUS, GenerateImage, GenerateImageParams } from "@/entities/storyboard";
import {
	GetShotParams,
	getStoryboardShotResource, RegenerateImageParams, regenerateImageResource
} from "@/shared/api/storyboard";

export const generateImageAtom = atom<GenerateImage>({}, "generateImageAtom");

export const updateGenerateImageAction = action((ctx, params: GenerateImageParams) => {
	const generateImage = ctx.get(generateImageAtom);

	generateImageAtom(ctx, {
		...generateImage,
		[params.shotId]: {
			pending: params.loading,
			image: params.image === null ? generateImage[params.shotId]?.image : params.image
		}
	});
});

export const getShotAction = reatomAsync(
	(ctx, params: GetShotParams) => getStoryboardShotResource(params, ctx.controller)
).pipe(
	withAbort(),
	withStatusesAtom(),
	withDataAtom(),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

export const regenerateImageAction = reatomAsync(async (ctx, params: RegenerateImageParams) => {
	await regenerateImageResource(params, ctx.controller);

	return params;
}, {
	onFulfill: (ctx, data) => {
		getShotAction(ctx, { shotId: data.shotId, projectId: data.projectId });
	}
}).pipe(
	withAbort(),
	withStatusesAtom(),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

export const isGenerateStoryboardAtom = atom<{ [projectId: string]: boolean }>({}, "isGenerateStoryboard").pipe(withLocalStorage(GENERATE_STORYBOARD_STATUS));
