import { api } from "@/shared/api";
import { InvitationVerifyParams, TAuthCode, TCredentials, TRegisterCredentials, TRegisterKey } from "./types";
import { TResponse } from "@/vite-env";

export const loginResource = async (credentials: TCredentials): TResponse<TRegisterKey> => api.post("/auth/login", {
	...credentials
});

export const createUserResource = (credentials: TRegisterCredentials): TResponse<unknown> => api.post("/auth/add_user", {
	...credentials
});

export const createUserGoogleResource = (credentials: TAuthCode): TResponse<TRegisterKey> => api.post("/auth/add_user/google", {
	...credentials
});

export const deleteUserResource = () => api.delete("/auth/");

export const verifyEmailResource = (key: string): TResponse<TRegisterKey> => api({
	method: "PATCH",
	url: "/auth/verify",
	params: {
		verification_code: key
	}
});

export const verifyInvitationResource = (params: InvitationVerifyParams): TResponse<TRegisterKey> => api.post("/auth/add_user/invitation", params);
