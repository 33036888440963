import { Flex, Form, Input, Modal, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";
import { DisableSubmitButton } from "@/shared/ui";

interface IRenameProjectModal extends IOpenModal {
	onRename: (name: string) => void;
	currentName: string;
}

type TFiled = {
	name: string;
}

export const RenameProjectModal: FC<IRenameProjectModal> = ({ isOpen, toggleModal, onRename, currentName }) => {
	const [form] = Form.useForm();
	const onFinish = (data: TFiled) => {
		onRename(data.name);
	};

	return (
		<Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
			<Form onFinish={onFinish} form={form}>
				<Flex vertical className="gap-s">
					<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>Rename a project</Typography.Text>
					<Form.Item<TFiled> name="name" rules={[{ required: true, message: "Please input your project name" }]}>
						<Input defaultValue={currentName} placeholder="Type here" />
					</Form.Item>
					<DisableSubmitButton
						type="primary"
						form={form}
					>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							Rename
						</Typography.Text>
					</DisableSubmitButton>
				</Flex>
			</Form>
		</Modal>
	);
};
