import { Flex, Modal } from "antd";
import { FC } from "react";
import { SecondStepForm } from "@/features/add-new-project/ui/SecondStepForm";
import { IOpenModal } from "@/shared/hooks";

export const AddScriptModal: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
	const onCloseModal = () => {
		toggleModal(false);
	};

	return (
		<Modal footer={[]} open={isOpen} onCancel={onCloseModal}>
			<Flex vertical className="gap-s">
				<SecondStepForm closeModal={onCloseModal} isBriefOpen={false} />
			</Flex>
		</Modal>
	);
};
