import { Button, Flex, message, Typography, Upload, UploadFile, UploadProps } from "antd";
import cn from "classnames";
import { useState } from "react";
import uploadImage from "@/assets/shared/plus_load.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

export const UploadTab = () => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const onSave = () => {
	};
	const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
		setFileList(newFileList);

	const beforeUpload = (file: UploadFile) => {
		const updatedList = [...fileList, file];
		const filesSize = updatedList.reduce((acc, cur) => acc + (cur?.size ?? 0), 0);
		const isLt16M = filesSize / 1024 / 1024 < import.meta.env.VITE_IMAGE_LOAD_LIMIT_MB;

		if (isLt16M) {
			setFileList([...fileList, file]);
		} else {
			message.error(`All files must be smaller than ${import.meta.env.VITE_IMAGE_LOAD_LIMIT_MB}MB!`);
			return Upload.LIST_IGNORE;
		}

		return false;
	};

	return (
		<Flex vertical className="gap-m">
			<Upload.Dragger
				maxCount={1}
				multiple={false}
				accept=".png,.jpg,.jpeg"
				listType="picture"
				fileList={fileList}
				onChange={handleChange}
				beforeUpload={beforeUpload}
			>
				<p>
					<img src={uploadImage} alt="uplaod image" />
				</p>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					<Typography.Text className={cn(COLOR_CLASSNAMES.Green600, TEXT_CLASSNAMES.XsRegular)}>Click to upload</Typography.Text> or drag and drop
				</Typography.Text>
				<br/>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
					PNG, JPG (max 800x400px)
				</Typography.Text>
			</Upload.Dragger>
			<Button onClick={onSave} disabled={!fileList.length} type="primary">
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save</Typography.Text>
			</Button>
		</Flex>
	);
};
