import { atom } from "@reatom/core";

import { TCurrentPlan } from "@/pages/settings";

export const currentPlanAtom = atom<TCurrentPlan>({
	name: "Studio",
	dueDate: "",
	amountPerUser: 0,
	projectsNumber: 4,
	monthlySeats: 8,
	invoiceTotal: 180
}, "currentPlanAtom");
