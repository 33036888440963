import { reatomAsync, withAbort, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";

import { atom } from "@reatom/core";
import { CharacterTab, TCharacter } from "@/entities/character";
import { callErrorAction } from "@/entities/notification";
import { isGenerateStoryboardAtom } from "@/entities/storyboard";
import {
	CharacterUpdateDTO,
	getCharactersDetectResource,
	getCharactersResource,
	updateCharacterResource
} from "@/shared/api/characters";
import { toCharacterInfo } from "@/shared/methods/toCharacter";

export const getCharactersAction = reatomAsync(async (ctx, projectId: string) => {
	const generateStoryboard = ctx.get(isGenerateStoryboardAtom);

	if (generateStoryboard[projectId]) {
		return await getCharactersResource(projectId, ctx.controller);
	}

	return await getCharactersDetectResource(projectId, ctx.controller);
}).pipe(
	withAbort(),
	withDataAtom([], (_ctx, res) => {
		if (Array.isArray(res.data.characters)) {
			const characters: Record<string, never> = res.data.characters.reduce((acc, character) => ({
				...acc,
				[character.character_key]: character.name
			}), {});

			const result: TCharacter[] = Object.keys(characters).map((id) => ({
				name: characters[id],
				id,
				description: toCharacterInfo(id).description,
				img: toCharacterInfo(id)["1"]
			}));

			return result;
		}

		const detectCharacters = res.data as Record<string, string>;

		const result: TCharacter[] = Object.entries(detectCharacters).map(([name, id]) => ({
			name,
			id,
			description: toCharacterInfo(id).description,
			img: toCharacterInfo(id)["1"]
		}));

		return result;
	}),
	withStatusesAtom(),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

export const updateCharacterAction = reatomAsync((ctx, id: string, params: CharacterUpdateDTO) => updateCharacterResource(id, params, ctx.controller))
	.pipe(
		withAbort(),
		withDataAtom(),
		withStatusesAtom(),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err))
	);

export const characterTabAtom = atom<CharacterTab>("all");
export const activeCharacterAtom = atom<string | null>(null);
