import { Dropdown, Flex, MenuProps } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import blackDots from "@/assets/shared/black_dots.svg";

import "./DropdownDots.scss";

interface IDropdownDots extends HTMLAttributes<HTMLElement>{
	items: MenuProps["items"];
}

export const DropdownDots: FC<IDropdownDots> = ({ items, onClick, ...props }) => {
	return (
		<Dropdown menu={{ items }} placement="bottomRight">
			<Flex
				{...props}
				onClick={(event) => {
					event.stopPropagation();
					if (onClick) {
						onClick(event);
					}
				}}
				className={cn("dropdown-dots cubic-animation", props.className)}
				align="center"
				justify="center"
			>
				<img src={blackDots} alt="white dots" />
			</Flex>
		</Dropdown>
	);
};
