import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";

import { TMoodBoardStatus } from "@/entities/moodboard";
import { callErrorAction } from "@/entities/notification";
import { getEmbeddingsResource, TMoodboardImage } from "@/shared/api/moodboard";
import { base64toBlob, getAspectRatio, getGcd, getImageFromBlob } from "@/shared/methods";

export const searchBoardStatusAtom = atom<TMoodBoardStatus>((ctx) => {
	const list = ctx.spy(searchBoardListAtom);

	return list.length ? "full" : "empty";
}, "searchBoardStatusAtom");

export const searchBoardViewAtom = atom<"image" | "autocomplete">("autocomplete", "searchBoardViewAtom");

export const searchBoardListAtom = atom<TMoodboardImage[]>([], "searchBoardListAtom");
export const searchBoardLoadingAtom = atom<boolean>(false, "searchBoardLoadingAtom");
export const getEmbeddingsAction = action(async (ctx, query: string) => {
	try {
		searchBoardLoadingAtom(ctx, true);
		const { data } = await getEmbeddingsResource(query);

		const imagesPromises: Promise<TMoodboardImage>[] = data.images.map(async (image) => {
			const blob = base64toBlob(image.data);
			const { width, height } = await getImageFromBlob(blob);
			const aspectRatio = await getAspectRatio(width, height);
			const { numerator, denominator } = getGcd(aspectRatio);

			const updatedImage: TMoodboardImage = {
				...image,
				data: URL.createObjectURL(blob),
				coordinates: { x: 0, y: 0, w: numerator, h: denominator, minW: 2, minH: 2, id: image.id },
				id: image.id,
				isStatic: false
			};

			return updatedImage;
		});

		const images = await Promise.all(imagesPromises);
		searchBoardListAtom(ctx, images);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	} finally {
		searchBoardLoadingAtom(ctx, false);
	}
}, "getEmbeddingsAction");

export const removeFromSearchBoardAction = action((ctx, id: string) => {
	searchBoardListAtom(ctx, ctx.get(searchBoardListAtom).filter((item) => item.id !== id));
});
