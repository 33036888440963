import { action, atom } from "@reatom/core";
import { withReset } from "@reatom/lens";
import { TNewProject } from "@/entities/projects";
export const stepAtom = atom<"1" | "2">("1", "stepAtom").pipe(withReset());
export const newProjectAtom = atom<TNewProject>({
	name: null,
	briefing: null,
	script: null
}, "newProjectAtom").pipe(withReset());

export const resetDataAction = action((ctx) => {
	stepAtom.reset(ctx);
	newProjectAtom.reset(ctx);
});
