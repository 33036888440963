import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useContext, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { LandingLayoutContext } from "@/widgets/landing-layout";
import { useJoinWaitingList } from "@/features/join-waiting-list";
import arrow from "@/assets/shared/arrow_right.svg";
import logo from "@/assets/shared/black_blooper.svg";
import minLogo from "@/assets/shared/logo_black.svg";

import { LINKS } from "@/shared/const/links";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useWindowSize } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import { BurgerMenu } from "./burger-menu";
import { HeaderDropdown } from "./header-dropdown";

import "./Header.scss";

export const Header: FC = () => {
	const { isOpen, toggleOpen } = useContext(LandingLayoutContext);
	const [isDropdown, setIsDropdown] = useState(false);
	const { width } = useWindowSize();
	const { isMobileXL } = useMediaQueries();
	const navigate = useNavigate();
	const timeout = useRef<null | ReturnType<typeof setTimeout>>(null);
	const join = useJoinWaitingList();

	const toggleDropdown = (value?: boolean) => {
		setIsDropdown((prevState) => value ?? !prevState);
	};

	const onMouseEnter = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}

		toggleDropdown(true);
	};

	const onMouseLeave = () => {
		timeout.current = setTimeout(() => {
			toggleDropdown(false);
		}, 250);
	};

	const onClick = (path: string) => {
		if (isOpen) {
			toggleOpen();
		}

		if (isDropdown) {
			toggleDropdown();
		}

		navigate(path);
	};

	return (
		<>
			<div className="fake-header" />
			<header className={cn("header", {
				header__mobile: isOpen
			})}>
				<Flex className="header__content" align="center" justify="space-between">
					<div role="button" onClick={() => onClick("/")}>
						<img className="header__content__img" src={isMobileXL ? minLogo : logo} alt="logo" />
					</div>
					<Flex align="center" className="gap-m header__links">
						{LINKS.map((link, index) => Array.isArray(link.url)
							? (
								<Flex
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
									role="button"
									align="center"
									key={index.toString(36)}
									className="gap-xxs"
								>
									<Typography.Text>
										{link.title}
									</Typography.Text>
									<div className={cn("header__content__toggle cubic-animation", {
										"header__content__toggle-active": isDropdown
									})}>
										<img src={arrow} alt="arrow" />
									</div>
									<HeaderDropdown isActive={isDropdown} onClose={toggleDropdown} />
								</Flex>
							)
							: (
								<Flex align="center" key={index.toString(36)} className="gap-xxs">
									<NavLink to={link.url}>
										<Typography.Text>
											{link.title}
										</Typography.Text>
									</NavLink>
								</Flex>
							))}
					</Flex>
					<Flex align="center" className="gap-xs">
						{/* <Button> */}
						{/*	<Typography.Text>Log in</Typography.Text> */}
						{/* </Button> */}
						<Button onClick={join} type="primary">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Join waiting list</Typography.Text>
						</Button>
						{width <= 900 && (
							<BurgerMenu />
						)}
					</Flex>
				</Flex>
			</header>
		</>
	);
};
