import { Button, Flex, Form, FormInstance, Typography } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import cn from "classnames";
import { FC, PropsWithChildren, useEffect, useState } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

interface IDisableSubmitButton extends BaseButtonProps {
	form: FormInstance;
	isFullWidth?: boolean;
	errorMessage?: string | null
	dirty?: boolean;
}

export const DisableSubmitButton: FC<PropsWithChildren<IDisableSubmitButton>> = ({ form, isFullWidth, children, errorMessage, dirty, ...props }) => {
	const [submittable, setSubmittable] = useState(false);

	// Watch all values
	const values = Form.useWatch([], form);

	const isDirty = !form.isFieldsTouched(true) ||
		form.getFieldsError().filter(({ errors }) => errors.length)
			.length > 0;

	const disabled = dirty ? !isDirty : !submittable;

	useEffect(() => {
		form.validateFields({ validateOnly: true }).then(
			() => {
				setSubmittable(true);
			},
			() => {
				setSubmittable(false);
			}
		);
	}, [values]);

	return (
		<Flex vertical className={cn("gap-4", {
			"full-width": isFullWidth
		})}>
			<Button {...props} htmlType="submit" disabled={disabled}>
				{children}
			</Button>
			{errorMessage && <Typography.Text className={cn("heart-red-400", TEXT_CLASSNAMES.XsRegular)}>{errorMessage}</Typography.Text>}
		</Flex>
	);
};
