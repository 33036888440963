import { BillingHistoryMap } from "@/pages/settings";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";

export const BILLING_HISTORY_MAP: BillingHistoryMap = {
	paid: {
		title: "Paid",
		color: COLOR_CLASSNAMES.Green600
	},
	processing: {
		title: "Processing",
		color: COLOR_CLASSNAMES.CrazyOrange600
	},
	auto_scheduled: {
		title: "Auto scheduled",
		color: COLOR_CLASSNAMES.TextSecondary
	}
};
