import { Button } from "antd";
import { FC } from "react";

import arrowLeft from "@/assets/shared/arrow_left_24.svg";

import "./ButtonBack.scss";

interface IButtonBack {
	onAction: () => void;
}

export const ButtonBack: FC<IButtonBack> = ({ onAction }) => (
	<Button onClick={onAction} className="button-back" shape="circle">
		<img src={arrowLeft} alt="arrow left" />
	</Button>
);
