import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

import { useParams } from "react-router-dom";
import { DRAGGABLE_IMAGE_ID } from "@/entities/inspiration-board";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { DraggableBoard, DraggableImage, Spinner } from "@/shared/ui";

import { archiveBoardListAtom, archiveBoardLoadingAtom, archiveBoardStatusAtom, getArchiveBoardAction } from "../model";

import archiveBoardEmptyImage from "@/assets/moodboard/empty-archive-moodboard.svg";

import "./ArchiveBoard.scss";

export const ArchiveBoard: FC = () => {
	const { id } = useParams();
	const [archiveBoardList] = useAtom(archiveBoardListAtom);
	const [archiveBoardStatus] = useAtom(archiveBoardStatusAtom);
	const [isLoading] = useAtom(archiveBoardLoadingAtom);
	const getArchiveBoard = useAction(getArchiveBoardAction);

	useEffect(() => {
		if (id) {
			getArchiveBoard(id);
		}
	}, []);

	if (isLoading) {
		return (
			<Flex vertical align="center" className="full-width gap-xs">
				<Spinner />
			</Flex>
		);
	}

	if (archiveBoardStatus === "empty") {
		return (
			<Flex vertical align="center" className="full-width gap-xs">
				<img className="archive-board__image" src={archiveBoardEmptyImage} alt="archive board empty" />
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, "archive-board__text")}>
					All deleted images will be stored here
				</Typography.Text>
			</Flex>
		);
	}

	return (
		<Flex vertical className="archive-board gap-s">
			<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, "archive-board__text")}>
				All deleted images are stored here <br />
				To put them back to the moodboard click on the return - button
			</Typography.Text>
			<DraggableBoard isLoading={isLoading}>
				{archiveBoardList.map(({ id, data }) => (
					<DraggableImage
						isFullWidth={true}
						key={id}
						id={id}
						img={data}
						draggableImageId={DRAGGABLE_IMAGE_ID}
					/>
				))}
			</DraggableBoard>
		</Flex>
	);
};
