import { Flex, Typography } from "antd";
import { FC, PropsWithChildren } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
// import { CharacterStep } from "@/assets/components";

import "./CharacterHeader.scss";

export const CharacterHeader: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Flex align="center" justify="space-between" className="full-width">
			<Flex vertical className="gap-xxs">
				<Flex className="gap-4" align="center">
					{/* <CharacterStep className="character-img" fill="#0C0C0D" /> */}
					<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>
						Character creation
					</Typography.Text>
				</Flex>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Meet the characters for your storyboard, selected from our diverse collection.<br/>
					Tailor these unique personas to fit the narrative and aesthetic of your film, ensuring each character enhances the story you want to tell.
				</Typography.Text>
			</Flex>
			{children}
		</Flex>
	);
};
