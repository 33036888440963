import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	activeCharacterAtom,
	CharacterActions,
	CharacterHeader,
	CharacterSettings,
	getCharactersAction,
	getNextCharacterId,
	getPrevCharacterId, updateCharacterAction
} from "@/entities/character";

import { Spinner } from "@/shared/ui";
import { CharacterInfoView } from "./CharacterInfoView";

import "./CharacterInfo.scss";

export const CharacterInfo: FC = () => {
	const navigate = useNavigate();
	const { id, characterId } = useParams();
	const [activeCharacter, setActiveCharacter] = useAtom(activeCharacterAtom);
	const initCharacters = useAction(getCharactersAction);
	const [statuses] = useAtom(getCharactersAction.statusesAtom);
	const [characterList] = useAtom(getCharactersAction.dataAtom);
	const updateCharacter = useAction(updateCharacterAction);
	const [updateStatuses] = useAtom(updateCharacterAction.statusesAtom);

	const currentCharacter = characterList.find((character) => character.id === characterId);
	const currentCharacterIndex = characterList.findIndex((character) => character.id === characterId);
	const onSelect = () => {
		if (id && currentCharacter && activeCharacter) {
			updateCharacter(id, { appearance_key: activeCharacter, name: currentCharacter.name, new_name: currentCharacter.name });
			navigate(`/storyboard/${id}/character/${activeCharacter}/character-info`);
		}
	};

	const onNextSelect = () => {
		navigate(`/storyboard/${id}/storyboard`);
	};

	const onNext = () => {
		const nextId = getNextCharacterId(characterList, characterId);

		navigate(`/storyboard/${id}/character/${nextId}/character-info`);
	};

	const onPrev = () => {
		const prevId = getPrevCharacterId(characterList, characterId);

		navigate(`/storyboard/${id}/character/${prevId}/character-info`);
	};

	const type = (!activeCharacter || activeCharacter === characterId) ? "next" : "select";

	useEffect(() => {
		if (id && characterId) {
			setActiveCharacter(characterId);
			initCharacters(id);
		}
	}, []);

	useEffect(() => {
		if (characterId) {
			setActiveCharacter(characterId);
		}
	}, [id, characterId]);

	if (statuses.isPending || updateStatuses.isPending) {
		return (
			<Spinner isOverlay />
		);
	}

	return (
		<Flex className="full-height">
			<Flex vertical justify="space-between" className="full-height full-width character-info">
				<CharacterHeader />
				<CharacterInfoView characterId={activeCharacter ?? characterId ?? ""} />
				<CharacterActions type={type} onNext={onNextSelect} onSelect={onSelect} />
			</Flex>
			<CharacterSettings
				onPrev={onPrev}
				onNext={onNext}
				name={currentCharacter?.name ?? ""}
				description={currentCharacter?.description ?? ""}
				idx={currentCharacterIndex}
			/>
		</Flex>
	);
};
