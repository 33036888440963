import { useAtom } from "@reatom/npm-react";
import { Collapse, Flex } from "antd";
import { FC } from "react";
import { storyboardViewAtom } from "@/features/storyboard-view";
import { StoryboardGrid } from "@/features/storyboard-view/ui/StoryboardGrid";
import { StoryboardList } from "@/features/storyboard-view/ui/StoryboardList";
import { SceneHeader, TStoryboardScene } from "@/entities/storyboard";

type Props = {
	scene: TStoryboardScene;
}

export const CollapsedScene: FC<Props> = ({ scene }) => {
	const [tableView] = useAtom(storyboardViewAtom);

	return (
		<Collapse
			expandIconPosition={"end"}
			items={[
				{
					key: scene.id,
					label: (
						<SceneHeader
							idx={scene.idx}
							title={scene.title}
							shotLength={scene.shots.length}
						/>),
					children: (
						<Flex vertical className="gap-xs">
							{tableView === "grid" && <StoryboardGrid shots={scene.shots} sceneId={scene.id} />}
							{tableView === "list" && <StoryboardList shots={scene.shots} sceneId={scene.id} />}
						</Flex>
					)
				}
			]}
			defaultActiveKey={[scene.id]}
		/>
	);
};
