import { Flex, Tag, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import "./ProductFeature.scss";

interface IProductFeature {
	placeholderImg: string;
	title: string;
	description: string;
	link: string;
	tags: string[];
}

export const ProductFeature: FC<IProductFeature> = ({ tags, description, title, link, placeholderImg }) => {
	const { isLaptopS, isMobileS, isMobileXL } = useMediaQueries();

	return (
		<Flex className="product-feature gap-s">
			<NavLink className="flex-ctr-ctr" to={link}>
				<img src={placeholderImg} alt={title} className="product-feature__img" />
			</NavLink>
			<Flex vertical className={cn("gap-s", {
				"gap-xs": isMobileXL
			})}>
				<Flex vertical className="gap-xxs">
					<Typography.Text className={cn("text-left", TEXT_CLASSNAMES.HeadlineH2, {
						[TEXT_CLASSNAMES.HeadlineH3]: isLaptopS,
						[TEXT_CLASSNAMES.HeadlineH4]: isMobileS
					})}>{title}</Typography.Text>
					<Typography.Text className={cn("text-left", TEXT_CLASSNAMES.MdRegular, {
						[TEXT_CLASSNAMES.SmRegular]: isLaptopS,
						[TEXT_CLASSNAMES.XsRegular]: isMobileS
					})}>{description}</Typography.Text>
				</Flex>
				<Flex className="gap-xxs">
					{tags.map((tag, index) => (
						<Tag key={index.toString(36)}>
							<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, {
								[TEXT_CLASSNAMES.XsRegular]: isLaptopS,
								"product-feature__tag": isMobileS
							})}>
								{tag}
							</Typography.Text>
						</Tag>
					))}
				</Flex>
			</Flex>
		</Flex>
	);
};
