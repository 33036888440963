import { Flex, Skeleton } from "antd";
import { FC } from "react";
import { SettingsWrapper } from "@/entities/settings";

export const GeneralSettingsSkeleton: FC = () => {
	return (
		<SettingsWrapper
			title="General settings"
			description="Update your personal details here"
			className="full-width gap-s"
		>
			<div>
				<Flex vertical gap={18.3}>
					<Flex vertical className="gap-xs">
						<Flex vertical className="gap-xxs">
							<Skeleton title={false} paragraph={{ rows: 1 }} />
							<Skeleton title={false} paragraph={{ rows: 1 }} />
						</Flex>
						<Skeleton active title={false} paragraph={{ rows: 11 }} />
					</Flex>
					<Flex vertical className="gap-s">
						<Flex justify="space-between" align="center">
							<Skeleton title={true} paragraph={{ rows: 1 }} />
							<Skeleton.Button style={{ width: "211px" }} active size="large" />
						</Flex>
						<Flex className="gap-xs">
							<Skeleton.Button style={{ width: "100%" }} className="flex-1" active size="large" />
							<Skeleton.Button style={{ width: "100%" }} className="flex-1" active size="large" />
						</Flex>
					</Flex>
				</Flex>
			</div>
		</SettingsWrapper>
	);
};
