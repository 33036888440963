import { reatomAsync } from "@reatom/async";
import { atom } from "@reatom/core";

import { AxiosError } from "axios";
import { callErrorAction } from "@/entities/notification";
import { getMoodboardTagsResource, updateMoodboardTagsResource } from "@/shared/api/moodboard";
import { TBriefOption } from "../lib";

export const briefAtom = atom<string[]>([], "briefAtom");
export const briefOptionsAtom = atom<TBriefOption[]>([], "briefOptionsAtom");

export const briefProgressAtom = atom((ctx) => Math.floor((ctx.spy(briefAtom).length / 18) * 100));

export const updateTagsAction = reatomAsync((_ctx, projectId: string, tags: string[]) => updateMoodboardTagsResource(projectId, tags), "updateTagsAction");

export const getTagsAction = reatomAsync((_ctx, projectId: string) => getMoodboardTagsResource(projectId), {
	name: "getTagsAction",
	onFulfill: (ctx, response) => {
		briefAtom(ctx, response.data.tags ?? []);
		briefOptionsAtom(ctx, response.data.tags?.map((tag) => ({ label: tag, value: tag })) ?? []);
	},
	onReject: (ctx, err) => {
		callErrorAction(ctx, err as AxiosError);
	}
});
