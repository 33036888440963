import { UserOutlined } from "@ant-design/icons";
import { useAction, useAtom } from "@reatom/npm-react";
import {
	Button,
	Flex,
	Form,
	Input,
	Select,
	Typography,
	Upload,
	UploadFile,
	UploadProps,
	Image,
	message,
	Avatar
} from "antd";
import cn from "classnames";

import { useState } from "react";
import { imageListAvatarAtom, updateProfileSeeingsAction } from "@/pages/settings";
import { getAccountAction } from "@/entities/account";
import { SettingsWrapper } from "@/entities/settings";
import uploadImage from "@/assets/shared/plus_load.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { ROLES } from "@/shared/const/roles";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { getBase64 } from "@/shared/methods";
import { DisableSubmitButton } from "@/shared/ui";

import email from "@/assets/settings/profile/email.svg";
import profile from "@/assets/settings/profile/profile.svg";

import "./SettingsForm.scss";

type FieldType = {
	username: string;
	contactEmail: string;
	role: string;
	file: UploadFile[];
}

export const SettingsForm = () => {
	const [form] = Form.useForm();
	const [settings] = useAtom(getAccountAction.dataAtom);
	const updateSettingsProfile = useAction(updateProfileSeeingsAction);
	const [fileList, setFileList] = useAtom(imageListAvatarAtom);

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");

	const onFinish = (data: FieldType) => {
		const file = fileList[0];
		const fileBase64 = file?.thumbUrl ?? "";

		updateSettingsProfile({
			first_name: "",
			last_name: "",
			avatar: fileBase64,
			username: data.username,
			role: data.role
		});
	};

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
	};

	const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
		setFileList(newFileList);

	const beforeUpload = (file: UploadFile) => {
		const updatedList = [...fileList, file];
		const filesSize = updatedList.reduce((acc, cur) => acc + (cur?.size ?? 0), 0);
		const isLt16M = filesSize / 1024 / 1024 < import.meta.env.VITE_IMAGE_LOAD_LIMIT_MB;

		if (isLt16M) {
			setFileList([...fileList, file]);
		} else {
			message.error(`All files must be smaller than ${import.meta.env.VITE_IMAGE_LOAD_LIMIT_MB}MB!`);
			return Upload.LIST_IGNORE;
		}

		return false;
	};

	const onCancel = () => {
		form.resetFields();
	};

	return (
		<SettingsWrapper
			className="gap-s settings-profile"
			title="Profile Settings"
			description="These are your personal details, visible to your team members"
		>
			{previewImage && (
				<Image
					wrapperStyle={{ display: "none" }}
					preview={{
						visible: previewOpen,
						onVisibleChange: (visible) => setPreviewOpen(visible),
						afterOpenChange: (visible) => !visible && setPreviewImage("")
					}}
					src={previewImage}
				/>
			)}
			<Form
				onFinish={onFinish}
				form={form}
				initialValues={
					{
						username: settings?.data?.username,
						contactEmail: settings?.data?.email,
						role: settings?.data?.role
					}}
			>
				<Flex vertical className="gap-s">
					<Flex className="gap-xs">
						{!fileList.length && (
							<Avatar className="settings-profile__image" shape="square" size={144} icon={<UserOutlined />} />
						)}
						<Form.Item<FieldType>
							name="file"
							className="settings-profile__upload"
						>
							<Upload.Dragger
								maxCount={1}
								multiple={false}
								accept=".png,.jpg,.jpeg"
								listType="picture-card"
								fileList={fileList}
								onPreview={handlePreview}
								onChange={handleChange}
								beforeUpload={beforeUpload}
							>
								<p>
									<img src={uploadImage} alt="uplaod image" className="settings-profile__upload__img" />
								</p>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
									<Typography.Text className={cn(COLOR_CLASSNAMES.Green600, TEXT_CLASSNAMES.XsRegular)}>Click to upload</Typography.Text> or drag and drop
								</Typography.Text>
								<br/>
								<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
									PNG, JPG (max 800x400px)
								</Typography.Text>
							</Upload.Dragger>
						</Form.Item>
					</Flex>
					<Flex vertical className="gap-xs">
						<Flex vertical className="gap-4">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Your name</Typography.Text>
							<Form.Item<FieldType>
								name="username"
								rules={[{ required: true, message: "Please input your username!" }]}
							>
								<Input prefix={<img src={profile} alt="profile" />} />
							</Form.Item>
						</Flex>
						<Flex vertical className="gap-4">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Your email</Typography.Text>
							<Form.Item<FieldType>
								name="contactEmail"
								rules={[
									{
										type: "email",
										message: "The input is not valid E-mail!"
									},
									{ required: true, message: "Please input your contact email!" }
								]}
							>
								<Input disabled prefix={<img src={email} alt="profile" />} />
							</Form.Item>
						</Flex>
						<Flex vertical className="gap-4">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Role</Typography.Text>
							<Form.Item<FieldType>
								name="role"
								rules={[
									{ required: true, message: "Please choose your role!" }
								]}
							>
								<Select
									placeholder="Choose the role"
									style={{ height: 44 }}
									onChange={(value) => form.setFieldValue("role", value)}
									options={ROLES}
								/>
							</Form.Item>
						</Flex>
					</Flex>
					<Flex className="gap-xs">
						<Button htmlType="button" onClick={onCancel} className="full-width">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
						</Button>
						<DisableSubmitButton type="primary" isFullWidth form={form} dirty>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Save Changes</Typography.Text>
						</DisableSubmitButton>
					</Flex>
				</Flex>
			</Form>
		</SettingsWrapper>
	);
};
