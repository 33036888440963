import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface IStoryboardSidebarButton {
	text: string;
	img: string;
	active: boolean;
	url: string;
}

export const StoryboardSidebarButton: FC<IStoryboardSidebarButton> = ({ img, text, active, url }) => {
	const navigate = useNavigate();
	const onRedirect = () => {
		navigate(url);
	};

	return (
		<Flex onClick={onRedirect} vertical className="pointer gap-xxs storyboard-sidebar__button-container" role="button" align="center" justify="center">
			<div className={cn("storyboard-sidebar__button flex-ctr-ctr cubic-animation", {
				"storyboard-sidebar__button--active": active
			})}>
				<img className="storyboard-sidebar__button--img" src={img} alt={text} />
			</div>
			<Typography.Text className={cn("text-center", "storyboard-sidebar__text", {
				"storyboard-sidebar__text--active": active
			})}>
				{text}
			</Typography.Text>
		</Flex>
	);
};
