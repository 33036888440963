import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useParallax } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import "./UseCaseHeader.scss";

interface IUseCaseHeader {
	title: string;
	description: string;
	img: string;
}

export const UseCaseHeader: FC<IUseCaseHeader> = ({ description, title, img }) => {
	const {
		isLaptopS,
		isTablet,
		isMobileL,
		isMobileXL,
		isLaptopM
	} = useMediaQueries();
	const height = isMobileL ? 214 : isMobileXL ? 340 : isTablet ? 401 : isLaptopS ? 537 : isLaptopM ? 692 : 740;
	const padding = isMobileL ? 50 : isMobileXL ? 60 : isTablet ? 70 : isLaptopS ? 80 : isLaptopM ? 90 : 100;

	useParallax(1.5, ".use-case-header");

	return (
		<Flex
			vertical
			align="center"
			className="use-case-header"
		>
			<Flex justify="center" align="center" vertical className="use-case-header__content gap-xs text-center">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH3]: isTablet,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileXL
				})}>{title}</Typography.Text>
				<Typography.Text className={cn(TEXT_CLASSNAMES.LgRegular, {
					[TEXT_CLASSNAMES.MdRegular]: isLaptopS,
					[TEXT_CLASSNAMES.SmRegular]: isTablet,
					[TEXT_CLASSNAMES.XsRegular]: isMobileL
				})}>{description}</Typography.Text>
			</Flex>
			<div style={{ height: `${height - (padding)}px` }} />
			<LazyLoadImage
				wrapperClassName="use-case-header__img use-case-header__wrapper"
				src={img}
				alt={title}
				placeholderSrc={img}
				height={`${height}px`}
			/>
		</Flex>
	);
};
