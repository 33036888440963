import { Flex } from "antd";
import { FC, PropsWithChildren } from "react";

import { Spinner } from "@/shared/ui";

import "./DraggableBoard.scss";

type Props = {
	isLoading: boolean;
}

export const DraggableBoard: FC<PropsWithChildren<Props>> = ({ children, isLoading }) => {
	if (isLoading) {
		return (
			<Flex className="full-width full-height" align="center" justify="center">
				<Spinner />
			</Flex>
		);
	}

	return (
		<Flex wrap="wrap" className="draggable-board gap-xxs">
			{children}
		</Flex>
	);
};
