import { useAction } from "@reatom/npm-react";
import { Flex, MenuProps, Typography } from "antd";
import cn from "classnames";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import {
	deleteProjectByIdAction,
	DeleteProjectModal,
	renameProjectByIdAction,
	RenameProjectModal, ThumbnailProjectModal
} from "@/entities/projects";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { DropdownDots } from "@/shared/ui";
import deleteStory from "@/assets/projects/delete.svg";
import edit from "@/assets/projects/edit.svg";
import emptyStoryboard from "@/assets/projects/empty-storyboard.svg";
import thumbnail from "@/assets/projects/thumbnail.svg";

import "./YourStoryboard.scss";

interface IYourStoryboard {
	img?: string;
	name: string;
	date: string;
}

export const YourStoryboard: FC<IYourStoryboard> = ({ img, name, date }) => {
	const navigate = useNavigate();
	const [editOpen, toggleEditOpen] = useOpenModal();
	const [deleteOpen, toggleDeleteOpen] = useOpenModal();
	const [thumbnailOpen, toggleThumbnail] = useOpenModal();

	const handleRedirect = () => {
		navigate(`/storyboard/${name}/script`);
	};

	const deleteProject = useAction(deleteProjectByIdAction);
	const renameProject = useAction(renameProjectByIdAction);

	const onRename = (newName: string) => {
		renameProject(name, newName);
		toggleEditOpen(false);
	};

	const onDelete = async () => {
		await deleteProject(name);
		toggleEditOpen(false);
	};

	const onLabelClick = (callback: () => void) => (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		callback();
	};

	const items: MenuProps["items"] = [
		{
			key: "1",
			label: (
				<Flex onClick={onLabelClick(() => toggleEditOpen(true))} align="center" className="gap-xxs">
					<img src={edit} alt="rename" />
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Rename</Typography.Text>
				</Flex>
			)
		},
		{
			key: "2",
			label: (
				<Flex onClick={onLabelClick(() => toggleThumbnail(true))} align="center" className="gap-xxs">
					<img src={thumbnail} alt="thumbnail" />
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Set a thumbnail</Typography.Text>
				</Flex>
			)
		},
		{
			key: "4",
			label: (
				<Flex onClick={onLabelClick(() => toggleDeleteOpen(true))} align="center" className="gap-xxs">
					<img src={deleteStory} alt="delete" />
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.CrazyOrange600)}>Delete</Typography.Text>
				</Flex>
			)
		}
	].filter((el) => !(!NOT_RELEASE && el.key === "2"));

	return (
		<>
			<Flex
				vertical
				align="center"
				justify="center"
				className="your-storyboard gap-xs pointer"
				onClick={handleRedirect}
			>
				<Flex justify="center" align="center" className="your-storyboard__background full-width full-height">
					<img className={cn({
						"your-storyboard__img": !!img
					})} src={img || emptyStoryboard} alt="storyboard"/>
				</Flex>
				<Flex align="center" justify="space-between" className="full-width your-storyboard__actions">
					<Flex vertical>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextPrimary)}>{name}</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}>{date}</Typography.Text>
					</Flex>
					<DropdownDots items={items} />
				</Flex>
			</Flex>
			<RenameProjectModal
				onRename={onRename}
				currentName={name}
				isOpen={editOpen}
				toggleModal={toggleEditOpen}
			/>
			<DeleteProjectModal
				onDelete={onDelete}
				isOpen={deleteOpen}
				toggleModal={toggleDeleteOpen}
			/>
			<ThumbnailProjectModal isOpen={thumbnailOpen} toggleModal={toggleThumbnail} />
		</>
	);
};
