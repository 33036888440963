import { Flex, FlexProps } from "antd";
import cn from "classnames";
import { FC, PropsWithChildren } from "react";

import close from "@/assets/shared/close.svg";

import "./CloseWrapper.scss";

interface ICloseWrapper extends FlexProps {
	closeAction: () => void;
}

export const CloseWrapper: FC<PropsWithChildren<ICloseWrapper>> = ({ children, closeAction, className, ...props }) => (
	<Flex {...props} className={cn(className, "close-wrapper")}>
		<img
			onClick={closeAction}
			src={close}
			className="close-wrapper__close pointer"
			alt="close"
		/>
		{children}
	</Flex>
);
