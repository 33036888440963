import { FC, SVGProps } from "react";
import { COLOR_NAMES } from "@/shared/const/color-names";

export const ChaosGrid: FC<SVGProps<unknown>> = ({ fill = COLOR_NAMES.TextSecondary, className }) => {
	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<g clipPath="url(#clip0_498_18765)">
				<path d="M3.33331 4.99967C3.33331 4.55765 3.50891 4.13372 3.82147 3.82116C4.13403 3.5086 4.55795 3.33301 4.99998 3.33301H6.66665C7.10867 3.33301 7.5326 3.5086 7.84516 3.82116C8.15772 4.13372 8.33331 4.55765 8.33331 4.99967V5.83301C8.33331 6.27504 8.15772 6.69896 7.84516 7.01152C7.5326 7.32408 7.10867 7.49967 6.66665 7.49967H4.99998C4.55795 7.49967 4.13403 7.32408 3.82147 7.01152C3.50891 6.69896 3.33331 6.27504 3.33331 5.83301V4.99967Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M3.33331 12.4997C3.33331 12.0576 3.50891 11.6337 3.82147 11.3212C4.13403 11.0086 4.55795 10.833 4.99998 10.833H6.66665C7.10867 10.833 7.5326 11.0086 7.84516 11.3212C8.15772 11.6337 8.33331 12.0576 8.33331 12.4997V14.9997C8.33331 15.4417 8.15772 15.8656 7.84516 16.1782C7.5326 16.4907 7.10867 16.6663 6.66665 16.6663H4.99998C4.55795 16.6663 4.13403 16.4907 3.82147 16.1782C3.50891 15.8656 3.33331 15.4417 3.33331 14.9997V12.4997Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M11.6667 4.99967C11.6667 4.55765 11.8423 4.13372 12.1548 3.82116C12.4674 3.5086 12.8913 3.33301 13.3334 3.33301H15C15.442 3.33301 15.866 3.5086 16.1785 3.82116C16.4911 4.13372 16.6667 4.55765 16.6667 4.99967V14.9997C16.6667 15.4417 16.4911 15.8656 16.1785 16.1782C15.866 16.4907 15.442 16.6663 15 16.6663H13.3334C12.8913 16.6663 12.4674 16.4907 12.1548 16.1782C11.8423 15.8656 11.6667 15.4417 11.6667 14.9997V4.99967Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</g>
			<defs>
				<clipPath id="clip0_498_18765">
					<rect width="20" height="20" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
};
