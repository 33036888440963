import { Flex } from "antd";
import { FC } from "react";

import { TransformWrapper } from "react-zoom-pan-pinch";
import { Actions } from "./actions";
import { Content } from "./Content";
import { Header } from "./header";

import "./ShotSelectionEditor.scss";

export const ShotSelectionEditor: FC = () => {
	return (
		<TransformWrapper disabled>
			<Flex vertical className="shot-selection-editor full-width gap-s">
				<Header />
				<Content />
				<Actions />
			</Flex>
		</TransformWrapper>
	);
};
