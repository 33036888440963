import { Flex, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthWrapper, BackgroundPreLogin, CREDENTIAL__REGISTER_KEY } from "@/entities/viewer";
import { axiosErrorHandler } from "@/shared/api";
import { verifyEmailResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { Spinner } from "@/shared/ui";
import { IMessageError } from "@/vite-env";

export const Verify: FC = () => {
	const navigate = useNavigate();
	const [error, setError] = useState<string | null>(null);
	const { search } = useLocation();

	useEffect(() => {
		const init = async () => {
			try {
				const params = new URLSearchParams(search);
				const verificationCode = params.get("verification_code");

				if (verificationCode) {
					const { data } = await verifyEmailResource(verificationCode);

					localStorage.setItem(CREDENTIAL__REGISTER_KEY, JSON.stringify({ key: data.key }));
					navigate("/plan");
				}
			} catch (e) {
				const { type, error } = axiosErrorHandler<IMessageError<string>>(e as Error);

				if (type === "axios-error") {
					setError("Oops, something went wrong");
				} else {
					setError(error.message);
				}
			}
		};

		init();
	}, [search]);

	return (
		<BackgroundPreLogin>
			<AuthWrapper
				action={() => {}}
				title="Verification"
				questionText=""
				actionText=""
			>
				{error
					? (
						<Typography.Text className={COLOR_CLASSNAMES.HeartRed400}>{error}</Typography.Text>
					)
					: (
						<Flex align="center" justify="center" className="full-width">
							<Spinner />
						</Flex>
					)}
			</AuthWrapper>
		</BackgroundPreLogin>
	);
};
