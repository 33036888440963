import { Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./CharacterCard.scss";
interface ICharacterCard {
	img: string;
	name: string;
	description: string;
	id: string;
}

export const CharacterCard: FC<ICharacterCard> = ({ img, name, description, id }) => {
	const params = useParams();
	const navigate = useNavigate();

	const onRedirect = () => {
		navigate(`/storyboard/${params.id}/character/${id}/character-info`);
	};

	return (
		<Flex onClick={onRedirect} vertical className="character-card pointer gap-xxs">
			<Typography.Paragraph ellipsis={{ rows: 1 }} className={TEXT_CLASSNAMES.HeadlineH3}>"{name}"</Typography.Paragraph>
			<Typography.Paragraph ellipsis={{ rows: 3 }} className={TEXT_CLASSNAMES.XsRegular}>{description}</Typography.Paragraph>
			<div className="character-card__img">
				<img src={img} alt={`character ${name}`} />
			</div>
		</Flex>
	);
};
