import { useAction } from "@reatom/npm-react";
import { Button, Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { updateProfilePasswordAction } from "@/pages/settings";
import { SettingsWrapper } from "@/entities/settings";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { REG_EXP } from "@/shared/const/reg-exp";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { DisableSubmitButton } from "@/shared/ui";
import lock from "@/assets/settings/profile/lock.svg";

type FieldType = {
	currentPassword: string;
	newPassword: string;
	repeatNewPassword: string;
}

export const UpdatePasswordForm: FC = () => {
	const [form] = Form.useForm();
	const changePassword = useAction(updateProfilePasswordAction);

	const onFinish = (data: FieldType) => {
		changePassword({ new_password: data.newPassword, old_password: data.currentPassword });
	};

	const onCancel = () => {
		form.resetFields();
	};

	return (
		<SettingsWrapper
			title="Update password"
			description="Enter your current password to change it"
			className="gap-s full-width"
		>
			<Form form={form} onFinish={onFinish}>
				<Flex vertical className="gap-s">
					<Flex vertical className="gap-xs">
						<Flex vertical className="gap-4 full-width">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Current Password</Typography.Text>
							<Form.Item<FieldType>
								name="currentPassword"
								rules={[
									{ required: true, message: "Please input your current password" },
									{ pattern: REG_EXP.password, message: "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" }
								]}
							>
								<Input
									prefix={<img src={lock} alt="lock" />}
									placeholder="Enter Password"
									type="password"
								/>
							</Form.Item>
						</Flex>
						<Flex vertical className="gap-4 full-width">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>New Password</Typography.Text>
							<Form.Item<FieldType>
								name="newPassword"
								rules={[
									{ required: true, message: "Please input your new password" },
									{ pattern: REG_EXP.password, message: "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" }
								]}
							>
								<Input
									prefix={<img src={lock} alt="lock" />}
									placeholder="Enter New Password"
									type="password"
								/>
							</Form.Item>
						</Flex>
						<Flex vertical className="gap-4 full-width">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Repeat New Password</Typography.Text>
							<Form.Item<FieldType>
								dependencies={["newPassword"]}
								name="repeatNewPassword"
								rules={[
									{ required: true, message: "Please input your new password" },
									{ pattern: REG_EXP.password, message: "Min. eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" },
									({ getFieldValue }) => ({
										validator (_, value) {
											if (!value || getFieldValue("newPassword") === value) {
												return Promise.resolve();
											}
											return Promise.reject(new Error("The new password that you entered do not match"));
										}
									})
								]}
							>
								<Input
									prefix={<img src={lock} alt="lock" />}
									placeholder="Enter New Password"
									type="password"
								/>
							</Form.Item>
						</Flex>
					</Flex>
					<Flex className="gap-xs">
						<Button htmlType="button" onClick={onCancel} className="full-width">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
						</Button>
						<DisableSubmitButton form={form} isFullWidth type="primary">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Update Password</Typography.Text>
						</DisableSubmitButton>
					</Flex>
				</Flex>
			</Form>
		</SettingsWrapper>
	);
};
