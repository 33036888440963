export const COLOR_NAMES = {
	Green100: "#E5FBED",
	Green200: "#CAF7DC",
	Green300: "#95EEB9",
	Green400: "#65E699",
	Green500: "#22D469",
	Green600: "#1DB459",
	Green700: "#178D46",
	Green800: "#0F6130",
	Green900: "#083018",

	FloralPurple100: "#F3EBFF",
	FloralPurple200: "#DAC2FE",
	FloralPurple300: "#BC91FE",
	FloralPurple400: "#9958FD",
	FloralPurple500: "#7821FD",
	FloralPurple600: "#5102CA",
	FloralPurple700: "#4101A2",
	FloralPurple800: "#2C016F",
	FloralPurple900: "#160138",

	MetalGray50: "#F4F5F5",
	MetalGray100: "#EAEAEC",
	MetalGray200: "#E2E3E4",
	MetalGray300: "#D8D9DB",
	MetalGray400: "#BCBEC2",
	MetalGray500: "#A1A4AA",
	MetalGray600: "#797C86",
	MetalGray700: "#5C5F66",
	MetalGray800: "#313235",
	MetalGray900: "#131415",

	TextPrimary: "#0C0C0D",
	TextSecondary: "#616161",
	TextTertiary: "#BFBFBF",
	TextWhitePrimary: "#FFF",
	TextBrand: "#22D469",

	BackgroundWhite: "#FFF",
	BackgroundLightGray: "#EAEAEC",
	BackgroundDarkGray: "#4E5055",
	BackgroundBlack: "#27282B"
};

export const COLOR_CLASSNAMES = {
	Green100: "green-100",
	Green200: "green-200",
	Green300: "green-300",
	Green400: "green-400",
	Green500: "green-500",
	Green600: "green-600",
	Green700: "green-700",
	Green800: "green-800",
	Green900: "green-900",

	FloralPurple100: "floral-purple-100",
	FloralPurple200: "floral-purple-200",
	FloralPurple300: "floral-purple-300",
	FloralPurple400: "floral-purple-400",
	FloralPurple500: "floral-purple-500",
	FloralPurple600: "floral-purple-600",
	FloralPurple700: "floral-purple-700",
	FloralPurple800: "floral-purple-800",
	FloralPurple900: "floral-purple-900",

	MetalGray50: "metal-gray-50",
	MetalGray100: "metal-gray-100",
	MetalGray200: "metal-gray-200",
	MetalGray300: "metal-gray-300",
	MetalGray400: "metal-gray-400",
	MetalGray500: "metal-gray-500",
	MetalGray600: "metal-gray-600",
	MetalGray700: "metal-gray-700",
	MetalGray800: "metal-gray-800",
	MetalGray900: "metal-gray-900",

	CrazyOrange100: "crazy-orange-100",
	CrazyOrange200: "crazy-orange-200",
	CrazyOrange300: "crazy-orange-300",
	CrazyOrange400: "crazy-orange-400",
	CrazyOrange500: "crazy-orange-500",
	CrazyOrange600: "crazy-orange-600",
	CrazyOrange700: "crazy-orange-700",
	CrazyOrange800: "crazy-orange-800",
	CrazyOrange900: "crazy-orange-900",

	HeartRed100: "heart-red-100",
	HeartRed200: "heart-red-200",
	HeartRed300: "heart-red-300",
	HeartRed400: "heart-red-400",
	HeartRed500: "heart-red-500",
	HeartRed600: "heart-red-600",
	HeartRed700: "heart-red-700",
	HeartRed800: "heart-red-800",
	HeartRed900: "heart-red-900",

	TextPrimary: "text-primary",
	TextSecondary: "text-secondary",
	TextTertiary: "text-tertiary",
	TextWhitePrimary: "text-white-primary",
	TextBrand: "text-brand",

	BackgroundWhite: "background-white",
	BackgroundLightGray: "background-light-gray",
	BackgroundDarkGray: "background-dark-gray",
	BackgroundBlack: "background-black"
};
