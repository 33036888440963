import { reatomAsync, withStatusesAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { AxiosError } from "axios";
import { callErrorAction } from "@/entities/notification";
import { CREDENTIAL_KEY } from "@/entities/viewer";
import { deleteUserResource, TRegisterKey } from "@/shared/api/auth";
import { getSettingsProfileResource } from "@/shared/api/settings";
import { getTeamsResource, TeamMemberRole } from "@/shared/api/teams";
import { isObjectEmpty } from "@/shared/methods";

export const viewerAtom = atom<TRegisterKey | null>(null, "viewerAtom").pipe(withLocalStorage(CREDENTIAL_KEY));

export const viewerPermission = atom<TeamMemberRole | null>(null);

export const sessionLoginAction = action((ctx, credential: TRegisterKey) => {
	viewerAtom(ctx, credential);
}, "session login");

export const logoutAction = action((ctx) => {
	viewerAtom(ctx, null);
	localStorage.removeItem(CREDENTIAL_KEY);
	window.location.href = "/login";
});

export const deleteAccountAction = action(async (ctx) => {
	try {
		await deleteUserResource();
		logoutAction(ctx);
	} catch (e) {
		callErrorAction(ctx, e as AxiosError);
	}
});

export const initViewerPermissionAction = reatomAsync(() => getTeamsResource(), {
	name: "initViewerPermissionAction",
	onFulfill: async (ctx, res) => {
		const { data: viewer } = await getSettingsProfileResource(true, new AbortController());

		if (!isObjectEmpty(res.data)) {
			const team = Object.values(res.data)[0];
			const user = team.find((value) => value[viewer.email]);

			if (user?.[viewer.email]) {
				viewerPermission(ctx, user[viewer.email].user_role);
			}
		} else {
			viewerPermission(ctx, "admin");
		}
	},
	onReject: (ctx, err) => callErrorAction(ctx, err as AxiosError)
}).pipe(withStatusesAtom());
