import { Flex } from "antd";
import cn from "classnames";
import { FC } from "react";

import grid from "@/assets/shared/grid.svg";
import list from "@/assets/shared/list.svg";
import { TTabView } from "@/shared/types/common";

import "./ViewTypeTab.scss";

interface IViewTypeTab {
	tabView: TTabView;
	changeView: (value: TTabView) => void;
	disabled: boolean;
	border?: boolean;
}

export const ViewTypeTab: FC<IViewTypeTab> = ({ tabView, changeView, disabled, border }) => {
	return (
		<Flex align="center" className={cn("gap-xxs", {
			"view-type-border": border
		})}>
			<Flex
				onClick={() => !disabled && changeView("grid")}
				role="button"
				align="center"
				justify="center"
				className={cn("view-type-tab", {
					pointer: !disabled,
					"view-type-tab--active": tabView === "grid"
				})}>
				<img src={grid} alt="grid" />
			</Flex>
			<Flex
				onClick={() => !disabled && changeView("list")}
				role="button"
				align="center"
				justify="center"
				className={cn("view-type-tab", {
					pointer: !disabled,
					"view-type-tab--active": tabView === "list"
				})}
			>
				<img src={list} alt="list" />
			</Flex>
		</Flex>
	);
};
