import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

import { JoinWaitingList } from "@/features/join-waiting-list";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { CardTeam } from "./card-team";

import annaPlaceholder from "@/assets/landing/anna.png";
import arseniyPlaceholder from "@/assets/landing/arseniy.png";
import dimaPlaceholder from "@/assets/landing/dima.png";
import ivanPlaceholder from "@/assets/landing/ivan.png";
import michelPlaceholder from "@/assets/landing/michel.png";

import "./LandingTeam.scss";

export const LandingTeam: FC = () => {
	const { isLaptopS, isTablet, isMobileXL } = useMediaQueries();

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex vertical justify="center" className="team-page">
			<Flex vertical className="text-center gap-xs">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH3]: isTablet,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileXL
				})}>Team</Typography.Text>
			</Flex>
			<Flex className="team-page__content" vertical gap="large">
				<Flex gap="large" vertical={isLaptopS}>
					<CardTeam
						fullName="Arseniy Seroka"
						positionName="CHIEF EXECUTIVE OFFICER"
						description="Arseniy is an experienced entrepreneur and learned film director. His love for technology and film is what motivates him to digitalize the creative industry."
						image={arseniyPlaceholder}
						placeholderImage={arseniyPlaceholder}
					/>
					<CardTeam
						fullName="Michel Perez"
						positionName="CHIEF PRODUCT OFFICER"
						description="Michel is an engineer that is obsessed with machine learning and loves building products that bring this powerful technology in the hand of everyday people."
						image={michelPlaceholder}
						placeholderImage={michelPlaceholder}
					/>
				</Flex>
				<Flex gap="large" vertical={isLaptopS}>
					<CardTeam
						fullName="Ivan Markov"
						positionName="CHIEF TECHNOLOGY OFFICER"
						description="As a serial entrepreneur, Ivan is no stranger to building cool things with emerging tech. He cares about making a positive impact by making technology more accessible."
						image={ivanPlaceholder}
						placeholderImage={ivanPlaceholder}
					/>
					<CardTeam
						fullName="Anna Zima"
						positionName="CHIEF PRODUCT DESIGNER"
						description="Anna embodies the epitome of creativity. Reminiscent of a character from the silver screen, she seamlessly transitions between personas, deftly altering the ambiance and aesthetic of design styles at will."
						image={annaPlaceholder}
						placeholderImage={annaPlaceholder}
					/>
				</Flex>
				<Flex gap="large" vertical={isLaptopS}>
					<CardTeam
						fullName="Dmitriy Grosh"
						positionName="FRONTEND ENGINEER"
						description="Professionally makes the world's buttons brighter. Professor of memology. Happy to talk about React, web application performance, digital accessibility, architecture, and more. Regular speaker at developer meetups."
						image={dimaPlaceholder}
						placeholderImage={dimaPlaceholder}
					/>
					{!isLaptopS && (
						<div style={{ padding: "32px" }} className="flex-1" />
					)}
				</Flex>
			</Flex>
			<JoinWaitingList style={{ maxWidth: "1440px" }} />
		</Flex>
	);
};
