import { Flex, Table, TableProps, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { BILLING_HISTORY_MAP, BillingHistoryStatus } from "@/pages/settings";
import { SettingsWrapper } from "@/entities/settings";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./BillingHidtory.scss";

interface DataType {
	key: string;
	date: string;
	amount: number;
	projectsAmount: number;
	seatsAmount: number;
	status: BillingHistoryStatus;
	id: string;
}

export const BillingHistory: FC = () => {
	const columns: TableProps<DataType>["columns"] = [
		{
			title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Date</Typography.Text>,
			dataIndex: "date",
			key: "date",
			render: (date) => <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{date}</Typography.Text>,
			width: 250
		},
		{
			title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Amount</Typography.Text>,
			dataIndex: "amount",
			key: "amount",
			render: (amount) => <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>€{amount}</Typography.Text>,
			width: 220
		},
		{
			title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Projects</Typography.Text>,
			dataIndex: "projectsAmount",
			key: "projectsAmount",
			render: (amount) => <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{amount}</Typography.Text>,
			width: 180
		},
		{
			title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Seats</Typography.Text>,
			dataIndex: "seatsAmount",
			key: "seatsAmount",
			render: (amount) => <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{amount}</Typography.Text>,
			width: 180
		},
		{
			title: () => <Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Status</Typography.Text>,
			key: "status",
			render: (data: DataType) => (
				<Flex vertical className="gap-4">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, BILLING_HISTORY_MAP[data.status].color)}>{BILLING_HISTORY_MAP[data.status].title}</Typography.Text>
				</Flex>
			)
		}
	];

	const data: DataType[] = [
		{
			key: "1",
			amount: 139,
			date: "Apr 20, 2024",
			projectsAmount: 8,
			seatsAmount: 8,
			status: "paid",
			id: "213"
		},
		{
			key: "2",
			projectsAmount: 8,
			seatsAmount: 8,
			amount: 139,
			date: "May 20, 2024",
			status: "processing",
			id: "211231233"
		},
		{
			key: "3",
			projectsAmount: 8,
			seatsAmount: 8,
			amount: 139,
			date: "Jun 20, 2024",
			status: "auto_scheduled",
			id: "2434123213"
		}
	];

	return (
		<SettingsWrapper
			title="Billing History and Payment Schedule"
			description="See information regarding your billing history"
			className="gap-s full-width billing-history"
		>
			<Table className="billing-history__table" columns={columns} dataSource={data} pagination={false} />
		</SettingsWrapper>
	);
};
