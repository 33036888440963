import { useAtom } from "@reatom/npm-react";
import { Avatar, Flex, Space, Table, TableProps, Tag, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import {
	generateImageAtom,
	TStoryboardShot,
	EditButton, useShotStoryboard
} from "@/entities/storyboard";
import { COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { toCharacter } from "@/shared/methods/toCharacter";
import { Spinner } from "@/shared/ui";
import emptyShot from "@/assets/storyboard/empty-list-shot.svg";

import "./StoryboardList.scss";
interface IStoryboardList {
	shots: TStoryboardShot[];
	sceneId: string;
}

type DataType = Pick<TStoryboardShot, "image" | "time" | "cameraMovement" | "location" | "description" | "props" | "shotSettings" | "characters">

export const StoryboardList: FC<IStoryboardList> = ({ shots, sceneId }) => {
	const { onRedirect, onCharacterRedirect } = useShotStoryboard();
	const [generateImage] = useAtom(generateImageAtom);

	const columns: TableProps<DataType>["columns"] = [
		{
			title: "Shot",
			dataIndex: "idx",
			key: "idx",
			render: (data: TStoryboardShot["idx"]) => <Typography.Text>{data + 1}</Typography.Text>,
			width: 60
		},
		{
			title: () => "Image",
			width: 100,
			className: "storyboard-list__image",
			render: (data: TStoryboardShot) => {
				const pending = generateImage[data.id]?.pending;
				const imageGenerated = generateImage[data.id]?.image;
				const currentImage = (imageGenerated ?? data.image);

				return (
					<Flex align="center" justify="center" className="full-height full-width">
						{pending && <Flex align="center" justify="center" className="scene-shot-grid__overlay"><Spinner /></Flex>}
						{currentImage
							? (
								<img
									className="image-contain"
									src={currentImage}
									alt="shot"
								/>
							)
							: (
								<img
									className="image-contain"
									src={emptyShot}
									alt="shot"
								/>
							)}
					</Flex>
				);
			}
		},
		{
			title: "Time",
			dataIndex: "time",
			key: "time",
			width: 80,
			render: (data: TStoryboardShot["time"]) => <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data} sec</Typography.Text>
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			width: 165,
			render: (data: TStoryboardShot["location"]) => <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data}</Typography.Text>
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			width: 340,
			className: "storyboard-list__description",
			render: (data: TStoryboardShot["description"]) => (
				<Flex className="storyboard-list__cell">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data}</Typography.Text>
				</Flex>
			)
		},
		{
			title: "Dialogue",
			dataIndex: "dialogue",
			key: "dialogue",
			width: 300,
			className: "storyboard-list__dialogue",
			render: (data: TStoryboardShot["dialogue"]) => (
				<>
					{!data.length && <Typography.Text>-</Typography.Text>}
					{!!data.length && (
						<Flex className="storyboard-list__cell">
							<Typography.Text className={cn("storyboard-list__cell--dialogue", TEXT_CLASSNAMES.XsRegular)}>{data}</Typography.Text>
						</Flex>
					)}
				</>
			)
		},
		{
			title: "Props",
			dataIndex: "props",
			key: "props",
			width: 180,
			render: (data: TStoryboardShot["props"]) => (
				<Space direction="vertical" align="start">
					{data.map((tag, index) => (
						<Tag key={index.toString(36)}>{tag}</Tag>
					))}
				</Space>
			)
		},
		{
			title: "Shot Settings",
			dataIndex: "shotSettings",
			key: "shotSettings",
			width: 140,
			render: (data: TStoryboardShot["shotSettings"]) => (
				<Space direction="vertical" align="start">
					{data.map((tag, index) => (
						<Tag key={index.toString(36)}>{tag}</Tag>
					))}
				</Space>
			)
		},
		{
			title: "Camera movement",
			dataIndex: "cameraMovement",
			key: "cameraMovement",
			width: 230,
			render: (data: TStoryboardShot["cameraMovement"]) => <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{data}</Typography.Text>
		},
		{
			title: "Characters",
			dataIndex: "characters",
			key: "characters",
			width: 100,
			className: "storyboard-list__dialogue",
			render: (data: TStoryboardShot["characters"]) => (
				<Flex vertical className="gap-xxs storyboard-list__cell">
					{[...new Map(data.map(item => [item.key, item])).values()].map((character, index) => (
						<Avatar
							style={{ borderColor: COLOR_NAMES.MetalGray300, backgroundColor: COLOR_NAMES.MetalGray50, minHeight: 44 }}
							key={index}
							size={46}
							icon={<img src={toCharacter(character.key, "4")} alt="character" />}
							onClick={() => onCharacterRedirect(character.key)}
						/>
					))}
				</Flex>
			)
		},
		{
			title: "Actions",
			width: 70,
			render: (data: TStoryboardShot) => (
				<Flex align="center" justify="center">
					<EditButton isActive={!!data.image} onClick={() => onRedirect(data.id, sceneId)} />
				</Flex>
			)
		}
	];

	return (
		<Flex vertical className="gap-xs">
			<Table
				rowClassName="storyboard-list"
				columns={columns}
				scroll={{ x: "max-content" }}
				dataSource={shots}
				bordered
				pagination={false}
			/>
		</Flex>
	);
};
