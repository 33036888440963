import { Flex, Typography } from "antd";
import { FC } from "react";
import { InviteNewMember } from "@/features/team-settings";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import addMember from "@/assets/team/add-member.svg";

export const EmptyTeamManage: FC = () => (
	<Flex align="center" justify="center" className="full-width full-height">
		<Flex vertical align="center" className="gap-s">
			<img src={addMember} className="team-manage__image" alt="add member" />
			<Flex vertical className="text-center">
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>Your Team is empty</Typography.Text>
				<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Start inviting participants and create your team</Typography.Text>
			</Flex>
			<InviteNewMember />
		</Flex>
	</Flex>
);
