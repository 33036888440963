import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useParams } from "react-router-dom";

import { deleteSceneAction } from "@/entities/script";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useOpenModal } from "@/shared/hooks";
import info from "@/assets/description/info.svg";

interface IDeleteSceneModal {
	sceneId: string;
}

export const DeleteSceneModal: FC<IDeleteSceneModal> = ({ sceneId }) => {
	const [open, toggleModal] = useOpenModal();
	const { id } = useParams();
	const deleteScene = useAction(deleteSceneAction);
	const handleDeleteScene = () => {
		if (id) {
			deleteScene(id, sceneId);
		}
	};

	return (
		<>
			<Typography.Text
				className={cn(TEXT_CLASSNAMES.XsRegular, "pointer")}
				role="button"
				onClick={() => toggleModal(true)}
				style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
			>
				Delete scene
			</Typography.Text>
			<Modal
				open={open}
				width={590}
				onCancel={() => toggleModal(false)}
				footer={
					<Flex justify="flex-end">
						<Button onClick={() => toggleModal(false)}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
						</Button>
						<Button type="primary" onClick={handleDeleteScene}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Yes, delete it</Typography.Text>
						</Button>
					</Flex>
				}
			>
				<Flex vertical className="gap-xxs">
					<Flex style={{ background: "transparent" }} align="center" className="gap-xxs">
						<img src={info} alt="info" />
						<Typography.Text>Are you sure you want to delete the entire scene?</Typography.Text>
					</Flex>
					<Typography.Text style={{ paddingLeft: 40 }} className={TEXT_CLASSNAMES.XsRegular}>
						All shots from this scene will also be deleted and cannot be returned
					</Typography.Text>
				</Flex>
			</Modal>
		</>
	);
};
