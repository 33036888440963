import { TMoodboardImage } from "@/shared/api/moodboard";
import bg1 from "@/assets/inspiration-board/bg_1.png";
import bg2 from "@/assets/inspiration-board/bg_2.png";
import bg3 from "@/assets/inspiration-board/bg_3.png";
import bg4 from "@/assets/inspiration-board/bg_4.png";

export const DRAGGABLE_IMAGE_ID = "DRAGGABLE_IMAGE_ID";

export const INSPIRATION_BOARD_IMAGES: TMoodboardImage[] = [
	{
		id: "1",
		data: bg1,
		coordinates: {
			id: "1",
			w: 2,
			h: 2,
			x: 0,
			y: 0,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: true
	},
	{
		id: "2",
		data: bg2,
		coordinates: {
			id: "2",
			w: 2,
			h: 2,
			x: 2,
			y: 0,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: false
	},
	{
		id: "3",
		data: bg3,
		coordinates: {
			id: "3",
			w: 2,
			h: 2,
			x: 4,
			y: 2,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: false
	},
	{
		id: "4",
		data: bg4,
		coordinates: {
			id: "4",
			w: 2,
			h: 2,
			x: 1,
			y: 1,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: false
	},
	{
		id: "5",
		data: bg1,
		coordinates: {
			id: "5",
			w: 2,
			h: 2,
			x: 1,
			y: 1,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: false
	},
	{
		id: "6",
		data: bg2,
		coordinates: {
			id: "6",
			w: 2,
			h: 2,
			x: 1,
			y: 1,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: false
	},
	{
		id: "7",
		data: bg3,
		coordinates: {
			id: "7",
			w: 2,
			h: 2,
			x: 1,
			y: 1,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: false
	},
	{
		id: "8",
		data: bg4,
		coordinates: {
			id: "8",
			w: 2,
			h: 2,
			x: 1,
			y: 1,
			minH: 2,
			minW: 2
		},
		// status: "inspiration",
		isStatic: false
	}
];
