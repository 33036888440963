import { useAction } from "@reatom/npm-react";
import { useHover } from "ahooks";
import { Flex } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";
import { useParams } from "react-router-dom";
import { addShotToSceneAction } from "@/entities/script";

type Props = {
	sceneId: string;
	shotId: string;
}

export const LastShot: FC<Props> = ({ shotId, sceneId }) => {
	const { id } = useParams();
	const ref = useRef(null);
	const isHover = useHover(ref);
	const addShot = useAction(addShotToSceneAction);

	const handleAddShot = () => {
		if (id) {
			addShot(id, { sceneId, shotId });
		}
	};

	return (
		<Flex ref={ref} style={{ paddingTop: 32 }} className="full-width">
			<div onClick={handleAddShot} className={cn("shot__last-shot full-width pointer", {
				"shot__last-shot--hover": isHover
			})} />
		</Flex>
	);
};
