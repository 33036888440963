import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { AddNewProject } from "@/features/add-new-project";
import plus from "@/assets/shared/plus_square.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import emptyProject from "@/assets/projects/empty-projects.svg";

import "./EmptyProject.scss";

export const EmptyProject: FC = () => {
	const [isOpen, toggleModal] = useOpenModal();

	return (
		<Flex className="empty-project gap-s full-height" vertical align="center" justify="center">
			<img className="empty-project__img" src={emptyProject} alt="empty project" />
			<Flex vertical className="gap-xxs">
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>No projects here yet</Typography.Text>
				{/* <Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Start creating projects and storyboards</Typography.Text> */}
			</Flex>
			<Button
				onClick={() => toggleModal(true)}
				type="primary"
				className="flex"
				icon={<img src={plus} alt="plus" />}
			>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, "flex-ctr-ctr")}>
					Create your project
				</Typography.Text>
			</Button>
			<AddNewProject isOpen={isOpen} toggleModal={toggleModal} />
		</Flex>
	);
};
