import { useAction } from "@reatom/npm-react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ChooseYourPlan, TrialVersionPlan } from "@/entities/plan";
import { BackgroundPreLogin, CREDENTIAL__REGISTER_KEY, sessionLoginAction } from "@/entities/viewer";
import { TRegisterKey } from "@/shared/api/auth";
import { useLocalStorage } from "@/shared/hooks";

import "./Plan.scss";
export const Plan: FC = () => {
	const navigate = useNavigate();
	const [isUpgradePlan, setIsUpgradePlan] = useState(false);
	const sessionLogin = useAction(sessionLoginAction);
	const [viewer] = useLocalStorage<TRegisterKey | null>(CREDENTIAL__REGISTER_KEY, null);

	const toggleUpgradePlan = () => {
		setIsUpgradePlan((prevState) => !prevState);
	};

	const startUsing = () => {
		if (viewer) {
			sessionLogin({ key: viewer.key });
			navigate("/main/projects");
		}
	};

	return (
		<BackgroundPreLogin>
			{!isUpgradePlan && <TrialVersionPlan startUsingAction={startUsing} upgradePlanAction={toggleUpgradePlan} />}
			{isUpgradePlan && <ChooseYourPlan closeAction={toggleUpgradePlan} />}
		</BackgroundPreLogin>
	);
};
