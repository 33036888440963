import { Flex } from "antd";
import { FC, useEffect } from "react";
import { JoinWaitingList } from "@/features/join-waiting-list";
import { UseCaseContent, UseCaseDescription, UseCaseHeader } from "@/entities/use-cases";

import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import rollsIcon from "@/assets/use-cases/video/rolls-icon.svg";
import rollsPlaceholder from "@/assets/use-cases/video/rolls-placeholder.png";
import supportIcon from "@/assets/use-cases/video/support-icon.svg";
import supportPlaceholder from "@/assets/use-cases/video/support-placeholder.png";
import synergyIcon from "@/assets/use-cases/video/synergy-icon.svg";
import synergyPlaceholder from "@/assets/use-cases/video/synergy-placeholder.png";
import videoPlaceholder from "@/assets/use-cases/video-placeholder.png";

export const UseCaseVideo: FC = () => {
	const { isTablet, isLaptopS, isLaptopM, isMobileS, isMobileL } = useMediaQueries();

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex className="landing-content-center" vertical align="center" justify="center">
			<UseCaseHeader
				title="Post-production AI assistant"
				description="Craft rough cut and compelling visuals effortlessly with our the help of our video assistant."
				img={videoPlaceholder}
				// placeholder={videoPlaceholder}
			/>
			<UseCaseContent>
				<Flex vertical={isTablet} className="gap-l">
					<UseCaseDescription
						img={supportIcon}
						title="Post production support"
						description="Blooper connects raw footage with the corresponding shots in your storyboard, helping you to get your film material in order quickly."
					/>
					<img
						style={{
							maxWidth: isLaptopS ? "460px" : isLaptopM ? "580px" : "640px",
							maxHeight: isMobileS ? "175px" : isMobileL ? "246px" : isLaptopS ? "314px" : isLaptopM ? "395px" : "465px"
						}}
						className="landing-content-center__img"
						src={supportPlaceholder}
						alt="Post production support"
					/>
				</Flex>
				<Flex vertical={isTablet} className="gap-l">
					{!isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "415px" : isLaptopM ? "540px" : "640px",
								maxHeight: isMobileS ? "201px" : isMobileL ? "284px" : isLaptopS ? "326px" : isLaptopM ? "414px" : "430px"
							}}
							className="landing-content-center__img"
							src={synergyPlaceholder}
							alt="Synergy between products"
						/>
					)}
					<UseCaseDescription
						img={synergyIcon}
						title="B-roll suggestion"
						description="Need a B-roll between two shots? Blooper helps you to find a fitting video."
					/>
					{isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "415px" : isLaptopM ? "540px" : "640px",
								maxHeight: isMobileS ? "201px" : isMobileL ? "284px" : isLaptopS ? "326px" : isLaptopM ? "414px" : "430px"
							}}
							className="landing-content-center__img"
							src={synergyPlaceholder}
							alt="Synergy between products"
						/>
					)}
				</Flex>
				<Flex vertical={isTablet} className="gap-l">
					<UseCaseDescription
						img={rollsIcon}
						title="Synergy between products"
						description="Blooper connects raw footage with the corresponding shots in your storyboard, helping you to get your film material in order quickly and creates a rough cut instantly."
					/>
					<img
						style={{
							maxWidth: isLaptopS ? "415px" : isLaptopM ? "530px" : "580px",
							maxHeight: isMobileS ? "182px" : isMobileL ? "210px" : isLaptopS ? "268px" : isLaptopM ? "318px" : "454px"
						}}
						className="landing-content-center__img"
						src={rollsPlaceholder} alt="B-roll suggestion"
					/>
				</Flex>
			</UseCaseContent>
			<JoinWaitingList style={{ maxWidth: "1440px" }} />
		</Flex>
	);
};
