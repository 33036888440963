import { useAtom } from "@reatom/npm-react";
import { Flex, Segmented, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import {
	activeCharacterAtom,
	CHARACTERS_TITLE,
	CharacterTab,
	characterTabAtom,
	toCharacterList
} from "@/entities/character";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { RightSidebar, SidebarNavigation } from "@/shared/ui";

import "./CharacterSettings.scss";

type Props = {
	onNext: () => void;
	onPrev: () => void;
	idx: number;
	description: string;
	name: string;
}

export const CharacterSettings: FC<Props> = ({ onNext, onPrev, idx, description, name }) => {
	const [tab, setTab] = useAtom(characterTabAtom);
	const [activeCharacter, setActiveCharacter] = useAtom(activeCharacterAtom);

	const sidebarViewList = [
		{
			title: "Character settings",
			value: "settings"
		}
	];

	const options = Object.entries(CHARACTERS_TITLE).map(([value, label]) => ({
		label,
		value
	}));
	const characters = toCharacterList(tab);

	return (
		<RightSidebar
			changeView={() => {}}
			sidebarViewList={sidebarViewList}
			activeView="settings"
		>
			<Flex vertical className="gap-s">
				<Flex vertical className="gap-xs">
					<SidebarNavigation
						title={`${idx + 1} character - ${name}`}
						onNext={onNext}
						onPrev={onPrev}
					/>
					<Flex vertical>
						<Flex className="character-settings__row">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{description}</Typography.Text>
						</Flex>
					</Flex>
				</Flex>
				<Segmented
					className="character-settings__tabs"
					size="large"
					options={options}
					onChange={(value) => setTab(value as CharacterTab)}
				/>
				<Flex justify="center" wrap="wrap" className="gap-xs">
					{characters.map((character) => (
						<div
							key={character.id}
							onClick={() => setActiveCharacter(character.id)}
							role="button"
							className={cn("character-settings__character pointer cubic-animation", {
								"character-settings__character--active": character.id === activeCharacter
							})}>
							<img className="character-settings__character--img" src={character.img} alt={character.id} />
						</div>
					))}
				</Flex>
			</Flex>
		</RightSidebar>
	);
};
