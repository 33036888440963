// import address from "@/assets/shared/address.svg";
import facebook from "@/assets/shared/facebook.svg";
import instagram from "@/assets/shared/instagram.svg";
import linkedin from "@/assets/shared/linkedin.svg";
// import mail from "@/assets/shared/mail.svg";
import twitter from "@/assets/shared/twitter.svg";

export const SOCIAL_MEDIA_LIST = [
	{
		link: "",
		icon: instagram,
		alt: "instagram"
	},
	{
		link: "",
		icon: linkedin,
		alt: "linkedin"
	},
	{
		link: "",
		icon: facebook,
		alt: "facebook"
	},
	{
		link: "",
		icon: twitter,
		alt: "twitter"
	}
];

export const INFO_LIST = [
	{
		title: "Company",
		links: [
			{
				text: "Team",
				link: "/team",
				icon: null
			},
			{
				text: "Contact",
				link: "/contacts",
				icon: null
			}
		]
	},
	{
		title: "Links",
		links: [
			{
				text: "For Writers",
				link: "/use-case-script",
				icon: null
			},
			{
				text: "For Producers",
				link: "/use-case-video",
				icon: null
			},
			{
				text: "For Directors",
				link: "/use-case-storyboard",
				icon: null
			}
		]
	},
	{
		title: "Contact Us",
		links: [
			{
				text: "Blooper AG Zugerstrasse 32 6340 Baar Switzerland",
				link: "/contacts",
				icon: null
			},
			{
				text: "info@blooper.ai",
				link: "/contacts",
				icon: null
			}
		]
	}
];
