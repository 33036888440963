import { Flex } from "antd";
import { FC, useEffect } from "react";
import { JoinWaitingList } from "@/features/join-waiting-list";
import { ExamplePdf, UseCaseContent, UseCaseDescription, UseCaseHeader } from "@/entities/use-cases";

import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import compositionIcon from "@/assets/use-cases/storyboard/composition-icon.svg";
import compositionPlaceholder from "@/assets/use-cases/storyboard/composition-placeholder.png";
import geneartionIcon from "@/assets/use-cases/storyboard/generation-icon.svg";
import generationPlaceholder from "@/assets/use-cases/storyboard/generation-placeholder.png";
import moodboardIcon from "@/assets/use-cases/storyboard/moodboard-icon.svg";
import moodboardPlaceholder from "@/assets/use-cases/storyboard/moodboard-placeholder.png";
import storyboardPlaceholder from "@/assets/use-cases/storyboard-placeholder.png";

export const UseCaseStoryboard: FC = () => {
	const { isTablet, isLaptopS, isLaptopM, isMobileL, isMobileS } = useMediaQueries();

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex className="landing-content-center" vertical align="center" justify="center">
			<UseCaseHeader
				title="Harness AI to instantly convert scripts into storyboards"
				description="Visualizing a film's scenes and sequences is essential for effective storytelling and planning. Blooper helps directors and producers to make their artistic vision come to life."
				img={storyboardPlaceholder}
				// placeholder={storyboardPlaceholder}
			/>
			<UseCaseContent>
				<Flex vertical={isTablet} className="gap-l">
					<UseCaseDescription
						img={moodboardIcon}
						title="Moodboard creation"
						description="Streamline the process of compiling and organizing visual inspiration. Our tool helps you to create a moodboard that fits your script while taking your style preferences into account."
					/>
					<img
						style={{
							maxWidth: isLaptopS ? "354px" : isLaptopM ? "500px" : "530px",
							maxHeight: isMobileS ? "245px" : isMobileL ? "300px" : isLaptopS ? "339px" : isLaptopM ? "479px" : "508px"
						}}
						className="landing-content-center__img"
						src={moodboardPlaceholder}
						alt="Moodboard creation"
					/>
				</Flex>
				<Flex vertical={isTablet} className="gap-l">
					{!isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "415px" : isLaptopM ? "540px" : "640px",
								maxHeight: isMobileS ? "172px" : isMobileL ? "243px" : isLaptopS ? "280px" : isLaptopM ? "364px" : "408px"
							}}
							className="landing-content-center__img"
							src={generationPlaceholder}
							alt="Storyboard generation"
						/>
					)}
					<UseCaseDescription
						img={geneartionIcon}
						title="Storyboard generation"
						description="Get from shot outline to storyboard in seconds. Speed up your preproduction by letting our AI assistant make a first draft and go from there. Blooper also helps to preserve transitions rules between the shots."
					/>
					{isTablet && (
						<img
							style={{
								maxWidth: isLaptopS ? "415px" : isLaptopM ? "540px" : "640px",
								maxHeight: isMobileS ? "172px" : isMobileL ? "243px" : isLaptopS ? "280px" : isLaptopM ? "364px" : "408px"
							}}
							className="landing-content-center__img"
							src={generationPlaceholder}
							alt="Storyboard generation"
						/>
					)}
				</Flex>
				<Flex vertical={isTablet} className="gap-l">
					<UseCaseDescription
						img={compositionIcon}
						title="Composition settings"
						description="Our image generation tool is tailored to the needs of the movie industry. Generate storyboard panels based on textual descriptions, shot compositions and camera angles. With constant possibilities for manual editing at any step of generation."
					/>
					<img
						style={{
							maxWidth: isLaptopS ? "404px" : isLaptopM ? "540px" : "640px",
							maxHeight: isMobileS ? "162px" : isMobileL ? "228px" : isLaptopS ? "260px" : isLaptopM ? "341px" : "405px"
						}}
						className="landing-content-center__img"
						src={compositionPlaceholder}
						alt="Composition settings"
					/>
				</Flex>
				<ExamplePdf
					actionName="download pdf from use case storyboard"
					title="Export to PDF and other file types"
					description="Simply integrate with your existing workflow by exporting to various file formats, including PDFs. With Blooper's versatile export capabilities, effortlessly transition between platforms and collaborate with ease."
					buttonText="View example PDF"
				/>
			</UseCaseContent>
			<JoinWaitingList style={{ maxWidth: "1440px" }} />
		</Flex>
	);
};
