import { Flex, Layout } from "antd";
import { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { FeedbackModal } from "@/widgets/feedback-modal";
import { TStoryboardRoutes } from "@/entities/storyboard";
import feedback from "@/assets/shared/feedback.svg";
import logo from "@/assets/shared/logo.svg";
import settings from "@/assets/shared/settings.svg";
import { COLOR_NAMES } from "@/shared/const/color-names";
import { useOpenModal } from "@/shared/hooks";
import { StoryboardSidebarButton } from "./StoryboardSidebarButton";
import characterDesign from "@/assets/storyboard/character-design.svg";
import home from "@/assets/storyboard/home.svg";
import moodBoard from "@/assets/storyboard/mood-board.svg";
import storyBoard from "@/assets/storyboard/story-board.svg";
import textUpload from "@/assets/storyboard/text-upload.svg";

import "./StoryboardSidebar.scss";

export const StoryboardSidebar: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [activeStep, setActiveStep] = useState<TStoryboardRoutes>("script");
	const { id } = useParams();
	const [feedbackModal, toggleFeedbackModal] = useOpenModal();
	const goToMain = () => {
		navigate("/main/projects");
	};

	useEffect(() => {
		const pathnameArray = location.pathname.split("/");
		const routeName = pathnameArray[pathnameArray.length - 1] as TStoryboardRoutes;

		setActiveStep(routeName);
	}, [location.pathname]);

	return (
		<Layout hasSider className="storyboard-sidebar">
			<Layout.Sider
				style={{
					overflow: "auto",
					height: "100vh",
					position: "fixed",
					left: 0,
					top: 0,
					bottom: 0,
					background: COLOR_NAMES.MetalGray50
				}}
				width={99}
			>
				<Flex align="center" vertical className="full-width full-height storyboard-sidebar__content">
					<Flex vertical align="center" className="gap-xs">
						<div className="pointer" onClick={goToMain}>
							<img className="storyboard-sidebar__logo" src={logo} alt="blooper" />
						</div>
						<StoryboardSidebarButton text="" img={home} active={false} url="/main/projects" />
					</Flex>
					<Flex vertical align="center" justify="center" className="full-width full-height gap-m">
						<StoryboardSidebarButton
							text="Script"
							img={textUpload}
							active={activeStep === "script"}
							url={`/storyboard/${id}/script`}
						/>
						<StoryboardSidebarButton
							text="Moodboard"
							img={moodBoard}
							active={activeStep === "moodboard"}
							url={`/storyboard/${id}/moodboard`}
						/>
						<StoryboardSidebarButton
							text="Character Design"
							img={characterDesign}
							active={activeStep === "character"}
							url={`/storyboard/${id}/character`}
						/>
						<StoryboardSidebarButton
							text="Storyboard"
							img={storyBoard}
							active={activeStep === "storyboard"}
							url={`/storyboard/${id}/storyboard`}
						/>
					</Flex>
					<Flex vertical className="gap-xs">
						<Flex
							onClick={() => navigate("/main/settings/profile")}
							vertical
							className="pointer gap-xxs storyboard-sidebar__button-container"
							role="button"
							align="center"
							justify="center"
						>
							<div className={"storyboard-sidebar__button flex-ctr-ctr cubic-animation"}>
								<img className="storyboard-sidebar__button--img" src={settings} alt="settings" />
							</div>
						</Flex>
						<Flex
							onClick={() => toggleFeedbackModal(true)}
							vertical
							className="pointer gap-xxs storyboard-sidebar__button-container"
							role="button"
							align="center"
							justify="center"
						>
							<div className={"storyboard-sidebar__button flex-ctr-ctr cubic-animation"}>
								<img className="storyboard-sidebar__button--img" src={feedback} alt="feedback" />
							</div>
						</Flex>
					</Flex>
					<FeedbackModal toggleModal={toggleFeedbackModal} isOpen={feedbackModal} />
				</Flex>
			</Layout.Sider>
			<Layout style={{ marginLeft: 99 }}>
				<Layout.Content style={{ padding: 0, overflow: "initial" }}>
					<Outlet />
				</Layout.Content>
			</Layout>
		</Layout>
	);
};
